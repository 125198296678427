import React from 'react';
import Header from 'components/commercetools-ui/aafes/header';
import { useCart } from 'frontastic/provider';

const HeaderTastic = ({ data }) => {
  const { data: cartList } = useCart();
  const leftColumn = [
    {
      header: data.headerCol1,
      links: data.linksCol1,
      icon: data.iconCol1,
      mIcon: data.iconColM1,
      loginRequired: data?.loginRequired1,
    },
    {
      header: data.headerCol2,
      links: data.linksCol2,
      icon: data.iconCol2,
      mIcon: data.iconColM2,
      loginRequired: data?.loginRequired2,
    },
  ];
  const weeklyAd = {
    header: data.headerCol3,
    links: data.linksCol3,
    icon: data.iconCol3,
    mIcon: data.iconColM3,
    loginRequired: data?.loginRequired3,
  };

  const rightColumn = [
    {
      header: data.headerCol4,
      links: data.linksCol4,
      icon: data.iconCol4,
      mIcon: data.iconColM4,
      loginRequired: data?.loginRequired4,
    },
    {
      header: data.headerCol5,
      links: data.linksCol5,
      icon: data.iconCol5,
      mIcon: data.iconColM5,
      loginRequired: data?.loginRequired5,
    },
    {
      header: data.headerCol6,
      links: data.linksCol6,
      icon: data.iconCol6,
      mIcon: data.iconColM6,
      loginRequired: data?.loginRequired6,
    },
  ];

  const findStore = {
    header: data.headerCol7,
    links: data.linksCol7,
    icon: data.iconCol7,
    mIcon: data.iconColM7,
  };

  const exchangeLogo = {
    header: data.headerCol8,
    links: data.linksCol8,
    icon: data.iconCol8,
    mIcon: data.iconColM8,
  };

  const militaryStarParentURL = {
    header: data.starHead1,
    links: data.starLink1,
  };

  const militaryStar = [
    {
      header: data.starHead1,
      links: data.starLink1,
    },
    {
      header: data.starHead2,
      links: data.starLink2,
    },
    {
      header: data.starHead3,
      links: data.starLink3,
    },
  ];
  const militaryStarApply = {
    header: data.starHead4,
    links: data.starLink4,
  };

  return (
    <Header
      data={data}
      leftColumn={leftColumn}
      rightColumn={rightColumn}
      findStore={findStore}
      exchangeLogo={exchangeLogo}
      militaryStar={militaryStar}
      militaryStarApply={militaryStarApply}
      cartList={cartList}
      militaryStarParentURL={militaryStarParentURL}
    />
  );
};

export default HeaderTastic;
