import { useMemo } from 'react';
import Link from 'next/link';
import InfoIcon from 'components/icons/infoErrorIcon';
import {
  COMING_SOON,
  GIFT_CARDS,
  PDP_PERSONALIZATION_LINES_MAX_CHARS,
  PRODUCT_TYPES_WITH_NO_SHIPPING_OPTIONS,
} from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';
import ApplicablePromotions from './applicablePromotions';
import BuildUniformSet from './buildUniformSet';
import GiftCardInfo from './GiftCardInfo';
import ListFeatureAndBenefits from './listFeatureAndBenefits';
import PersonalizeLine from './personalizeLine';
import PriceDetails from './priceDetails';
import VariantPicker from './variantPicker';
const ProductInfo = ({
  product,
  variant,
  personalizationLinesData,
  onChangeVariantIdx,
  showLogin,
  errorMessage = null,
  isPickUp,
  setIsPersonalization,
  isUseFacilityBasedPrice,
  studioConfig,
  selectedPicker,
  setSelectedPicker,
  pdpDroplets,
  showProductFrequency,
  isAddToCartDisabledForPUIS,
}) => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const isGiftCards = PRODUCT_TYPES_WITH_NO_SHIPPING_OPTIONS?.includes(variant?.attributes?.customProductType);
  const isGiftCardTypes = GIFT_CARDS.includes(variant?.attributes?.customProductType);

  const isHidePrice = useMemo(() => {
    return variant?.attributes?.customProductType === COMING_SOON || isGiftCardTypes;
  }, [variant?.attributes?.customProductType, isGiftCardTypes]);
  const availableShippingMethods = {
    isPickupInStore: showProductFrequency ? false : variant?.isPickupInStore,
    isShipToAddress: variant?.isShipToAddress,
  };
  const isPUISShippingMethodAvailabe = availableShippingMethods?.isPickupInStore && !isAddToCartDisabledForPUIS;

  return (
    <section>
      {errorMessage && (
        <div className="font-lato mb-5 flex gap-3 rounded-[4px] border border-[#dd001c] p-4 text-sm font-normal leading-5 text-[#dd001c]">
          <InfoIcon className="mt-1 shrink-0 rotate-180 fill-[#dd001c]" />
          <p>{errorMessage}</p>
        </div>
      )}
      <div className="mt-1 sm:mt-3 sm:px-0 lg:mt-0">
        <div>
          <h2 className="sr-only">
            {formatProductMessage({ id: 'product?.info', defaultMessage: 'Product information' })}
          </h2>
          {!isHidePrice && (
            <PriceDetails
              product={product}
              isMSRPRestricted={variant?.attributes?.isMSRPRestricted}
              showLogin={showLogin}
              isPickUp={isPickUp}
              variantStoreIsOnStock={variant?.isOnStockPickupInStore}
              isUseFacilityBasedPrice={isUseFacilityBasedPrice}
              variantIsPickupInStore={variant?.attributes?.isPickupInStore}
              isPUISShippingMethodAvailabe={isPUISShippingMethodAvailabe}
            />
          )}
          {isGiftCardTypes && (
            <div className="flex-wrap pb-6">
              <GiftCardInfo product={product} giftCardPrice={variant?.price} onChangeVariantIdx={onChangeVariantIdx} />
            </div>
          )}
        </div>
        {pdpDroplets?.[0]?.promotions && (
          <ApplicablePromotions
            variantPromotion={pdpDroplets[0]?.promotions}
            studioConfig={studioConfig}
            isMarketplaceProduct={product?.isMarketplaceProduct}
          />
        )}
        {variant?.attributes?.isPersonalizable && (
          <PersonalizeLine
            isPersonalizationRequired={variant?.attributes?.isPersonalizationRequired}
            personalizationLinesCount={variant?.attributes?.personalizationLines}
            personalizationLinesMaxChars={
              variant?.attributes?.personalizationLinesMaxChars || PDP_PERSONALIZATION_LINES_MAX_CHARS
            }
            personalizationLinesData={personalizationLinesData}
            setIsPersonalization={setIsPersonalization}
            isMilitaryClothing={variant?.attributes?.isMilitaryClothing}
            isPersonalizable={variant?.attributes?.isPersonalizable}
          />
        )}
        {!isGiftCards && product?.pickers?.length > 0 && (
          <VariantPicker
            availablePickers={product?.pickers}
            availableSwatch={product?.swatches}
            variant={variant}
            variants={product?.variants}
            onChangeVariantIdx={onChangeVariantIdx}
            selectedPicker={selectedPicker}
            setSelectedPicker={setSelectedPicker}
          />
        )}
        {variant?.attributes?.listFeatureAndBenefits && (
          <ListFeatureAndBenefits
            listFeatureAndBenefits={variant?.attributes?.listFeatureAndBenefits}
            isProductOverview={false}
          />
        )}
        {variant?.attributes?.isMilitaryClothing && <BuildUniformSet />}
      </div>
    </section>
  );
};
export default ProductInfo;
