import * as Yup from 'yup';

export const validateEmail = (email: string) => {
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  return emailRegex.test(email);
};

export const splitFullName = (fullName) => {
  const splitedName = fullName?.trim()?.split(/\s+/);
  const firstName = splitedName[0];
  const lastName = splitedName?.slice(1)?.join('');
  return { firstName, lastName };
};
