import React from 'react';
import { SHARETHISURL, GTM_PRODUCT, NETWORKS, GTM_SOCIAL_NETWORK, GTM_MARKET_PLACE } from 'helpers/constants/aafes';
const appendScript = (config) => {
  const script = document.createElement('script');
  script.setAttribute('id', 'sharethis-js');
  const params = {
    property: config.property || '',
    product: config.product,
    source: 'reactjs',
  };
  const query = Object.keys(params)
    ?.map((key) => key + '=' + params[key])
    ?.join('&');
  script.src = SHARETHISURL + query;
  script.async = true;
  document.body.appendChild(script);
};

const configureOnLoad = (component, config) => {
  const _onShareThisLoaded = window.onShareThisLoaded;
  let onload = () => {
    if (!onload.complete) {
      if (!config.id) {
        config.id = 'sharethis-' + Date?.now();
      }
      if (component.buttons.current) {
        component.buttons.current.id = config.id;
        window.__sharethis__.load(config.product, config);
      }
      if (_onShareThisLoaded) {
        _onShareThisLoaded();
      }
      onload.complete = true;
    }
  };
  window.onShareThisLoaded = onload;
};

const load = function (component, product) {
  let config = component.props.config || { enabled: true };
  config = JSON.parse(JSON.stringify(config));
  config.product = product;

  configureOnLoad(component, config);

  if (!document.getElementById('sharethis-js')) {
    appendScript(config);
  } else if (window.__sharethis__) {
    window.onShareThisLoaded();
  }
};

class InlineFollowButtons extends React.Component {
  constructor(props) {
    super(props);
    this.buttons = React.createRef();
  }

  componentDidMount() {
    load(this, 'inline-follow-buttons');
  }

  render() {
    return (
      <div
        ref={this.buttons}
        className="sharethis-inline-follow-buttons-aafes"
        data-gtm-exit
        data-gtm-id={GTM_SOCIAL_NETWORK}
      />
    );
  }
}

class InlineShareButtons extends React.Component {
  constructor(props) {
    super(props);
    this.buttons = React.createRef();
  }
  setShareButtonAttributes() {
    const buttonElements = this.buttons.current.querySelectorAll('.st-btn');
    buttonElements.forEach((button, index) => {
      button.setAttribute('data-gtm-share', true);
      button.setAttribute('data-gtm-method', NETWORKS[index]);
      button.setAttribute('data-gtm-type', GTM_PRODUCT);
      button.setAttribute('data-gtm-id', this.props.config?.productId);
    });
  }

  componentDidMount() {
    load(this, 'inline-share-buttons');
    this.setShareButtonAttributes();
  }

  render() {
    return <div ref={this.buttons} className="sharethis-inline-share-buttons-aafes" />;
  }
}

function formatMarketPlaceId(value) {
  if (typeof value !== 'string') return '';
  if (value.includes(' ')) {
    return value.replace(/\s+/g, '-').toLowerCase();
  }
  return value;
}

class InlineShareButtonsMP extends React.Component {
  constructor(props) {
    super(props);
    this.buttons = React.createRef();
  }
  setShareButtonAttributes() {
    const buttonElements = this.buttons.current.querySelectorAll('.st-btn');
    const marketPlaceId = this.props.config?.marketplaceId;
    const formattedMarketPlaceId = formatMarketPlaceId(marketPlaceId);
    buttonElements.forEach((button, index) => {
      button.setAttribute('data-gtm-share', true);
      button.setAttribute('data-gtm-method', NETWORKS[index]);
      button.setAttribute('data-gtm-type', GTM_MARKET_PLACE);
      button.setAttribute('data-gtm-id', formattedMarketPlaceId);
    });
  }
  componentDidMount() {
    load(this, 'inline-share-buttons');
    this.setShareButtonAttributes();
  }

  render() {
    return <div ref={this.buttons} className="sharethis-inline-share-buttons-aafes-mp" />;
  }
}

class InlineReactionButtons extends React.Component {
  constructor(props) {
    super(props);
    this.buttons = React.createRef();
  }

  componentDidMount() {
    load(this, 'inline-reaction-buttons');
  }

  render() {
    return <div ref={this.buttons} className="sharethis-inline-reaction-buttons-aafes" />;
  }
}

class StickyShareButtons extends React.Component {
  constructor(props) {
    super(props);
    this.buttons = React.createRef();
  }

  componentDidMount() {
    load(this, 'sticky-share-buttons');
  }

  render() {
    return <div ref={this.buttons} className="sharethis-sticky-share-buttons-aafes" />;
  }
}

export { InlineFollowButtons, InlineReactionButtons, InlineShareButtons, StickyShareButtons, InlineShareButtonsMP };
