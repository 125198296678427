import React, { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import { ProjectConfiguration } from '@Types/ProjectConfiguration';
import { MARKETING_BUG_NAMES } from 'helpers/constants/aafes';
import RatingStars from './RatingStars';

type ProductHeaderProps = {
  name: string;
  sku: string;
  productId: string;
  badge?: string;
  averageRating?: number;
  totalReviews?: number;
  isGuestPdp?: any;
  variant?: any;
  studioConfig?: ProjectConfiguration;
};

const ProductHeader = ({ name, productId, sku, badge, isGuestPdp, variant }: ProductHeaderProps) => {
  const brandRef = useRef<HTMLDivElement>(null);
  const [isWrapped, setIsWrapped] = useState(false);

  useEffect(() => {
    const checkWrapping = () => {
      if (brandRef?.current) {
        const lineHeight = parseFloat(window.getComputedStyle(brandRef?.current)?.lineHeight);
        const isTextWrapped = brandRef?.current?.offsetHeight > lineHeight;
        setIsWrapped(isTextWrapped);
      }
    };
    checkWrapping();
    window.addEventListener('resize', checkWrapping);
    return () => window.removeEventListener('resize', checkWrapping);
  }, []);

  return (
    <div className="my-5 flex flex-col items-start gap-3 md:flex-row lg:max-w-[78%]">
      {!isGuestPdp && badge && (
        <div className={`item-tag ${badge} rounded-[3px] px-2 text-xs font-bold leading-5 text-white md:mt-1`}>
          {MARKETING_BUG_NAMES?.[badge]}
        </div>
      )}
      <div className="flex flex-col gap-[10px] md:gap-[2px]">
        <div className="text-[21px] font-normal leading-[30px] text-gray-900 md:text-[24px]">{name}</div>
        <div className="flex-wrap items-center gap-[10px] md:flex">
          <div className="brand-container leading-[30px]" ref={brandRef}>
            <div className="flex flex-wrap items-center gap-x-[10px]">
              {/* Conditionally render SKU and rating only if not a guest */}
              {!isGuestPdp && (
                <>
                  <div className="text-sm leading-[30px] text-gray-800">Item: #{sku}</div>
                  <div className="h-[18px] border-l-[2px] border-[#00000020]" />
                  <div className="mt-1">
                    <RatingStars productId={productId} />
                  </div>
                  {!isWrapped && variant?.attributes?.brand !== 'Other Brands' && (
                    <div className="h-[18px] border-l-[2px] border-[#00000020] sm:block"></div>
                  )}
                </>
              )}
              <div>
                <p className="font-lato text-sm text-[#043C63]">
                  {variant?.attributes?.brand && variant?.attributes?.brand !== 'Other Brands' && (
                    <Link
                      href={`/browse?query=&facets=${encodeURIComponent(
                        encodeURIComponent(JSON.stringify({ brands: [variant?.attributes?.brand] })),
                      )}`}
                    >
                      {variant?.attributes?.brand}
                    </Link>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductHeader;
