import React, { Fragment, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Dialog, Transition } from '@headlessui/react';
import Loader from 'components/commercetools-ui/loader/Loader';
import MiniLoader from 'components/commercetools-ui/loader/miniLoader';
import {
  FRONT_SMALL,
  GA_CONTINUE_CHECKOUT,
  GA_TYPE_REGULAR_CHECKOUT,
  GA_TYPE_GUEST_CHECKOUT,
} from 'helpers/constants/aafes';
import useGATrackAddActions from 'helpers/hooks/gaEvents/useGATrackAddActions';
import useGATrackRemoveAction from 'helpers/hooks/gaEvents/useGATrackRemoveAction';
import useGATrackUserAction from 'helpers/hooks/gaEvents/useGATrackUserAction';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart, useAccount } from 'frontastic';
import Price from '../price';

function DonationModal({ donationModal, closeModal, itemQty, handleCheckout }) {
  const { loggedIn } = useAccount();
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const { data: cart, updateItem, addItem } = useCart();
  const [errorMessage, setErrorMessage] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [showMiniLoader, setShowMiniLoader] = useState(false);
  const router = useRouter();
  const { trackUserAction } = useGATrackUserAction();
  const { account } = useAccount();
  const { trackAddToCart } = useGATrackAddActions();
  const { trackGARemoveFromCart } = useGATrackRemoveAction();
  const { getDonationProducts } = useCart();
  const [donationProducts, setDonationProducts] = useState(null);

  const addItemToCart = async (product, variant, value) => {
    setShowLoader(true);
    const result = addItem(variant, value, undefined, undefined, undefined, undefined, !loggedIn);
    result
      .then((data: any) => {
        setErrorMessage(() => {
          return {
            ...data,
          };
        });
        if (data) {
          const trackAddtoCart = {
            product: product,
            type: loggedIn ? GA_TYPE_REGULAR_CHECKOUT : GA_TYPE_GUEST_CHECKOUT,
            variant: variant,
            quantity: value,
          };
          trackAddToCart(trackAddtoCart);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setShowLoader(false));
  };
  const editItemQuantity = async (lineItemId: string, newQuantity: number) => {
    try {
      setShowLoader(true);
      const response = await updateItem(lineItemId, newQuantity, !loggedIn);
      setShowLoader(false);

      if (response !== undefined) {
        const item = cart?.lineItems.find((item) => item.lineItemId === lineItemId);
        const prevQuantity = item?.count;
        if (newQuantity > prevQuantity) {
          const trackEditQuantityData = {
            product: item,
            type: loggedIn ? GA_TYPE_REGULAR_CHECKOUT : GA_TYPE_GUEST_CHECKOUT,
            //variant: response,
            quantity: newQuantity,
          };

          trackAddToCart(trackEditQuantityData);
        } else {
          const trackEditRemoveQuantityData = {
            product: item,
            type: loggedIn ? GA_TYPE_REGULAR_CHECKOUT : GA_TYPE_GUEST_CHECKOUT,
            quantity: newQuantity,
            cart: response,
          };
          trackGARemoveFromCart(trackEditRemoveQuantityData);
        }
      }
    } catch (error) {
      console.error('Error updating item quantity:', error);
      setShowLoader(false);
    }
  };

  const cartItem = cart.lineItems;
  const maxCount = itemQty;

  const [selectedValue, setSelectedValue] = useState({});
  const [updatedDonationItem, setUpdatedDonationItem] = useState({});
  const maxCountList = () => {
    const content = [];
    for (let i = 1; i <= maxCount; i++) {
      content.push(<option value={i}> {i}</option>);
    }
    return content;
  };

  const isAddedDonationItem = (itemdId: any) => {
    const hasDonationProduct = cartItem
      ?.flat()
      ?.map((item) => item?.variant?.sku)
      ?.includes(itemdId);
    return hasDonationProduct;
  };
  function handleDropDown(index, value) {
    setSelectedValue((prevState) => ({
      ...prevState,
      [index]: value,
    }));
  }

  const handleUpdateDropdown = (index, qty) => {
    setUpdatedDonationItem((prev) => ({
      ...prev,
      [index]: [qty, false],
    }));
  };
  const getSelectedItem = (product) => {
    const selectedItem = cartItem?.filter((item) => item?.variant?.sku == product.variants?.[0]?.sku);
    return selectedItem;
  };

  useEffect(() => {
    const fetchData = async () => {
      setShowMiniLoader(true);
      try {
        const data = await getDonationProducts();
        setDonationProducts(data);
      } catch (error) {
        console.error('Error fetching donation products:', error);
      }
      setShowMiniLoader(false);
    };

    fetchData();
  }, []);

  return (
    <>
      <Transition appear show={donationModal} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={closeModal}>
          <Transition.Child
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <section className="absolute inset-0" onClick={closeModal}>
                  <section
                    className="absolute top-1/2 left-1/2 h-[512px] max-h-[512px] w-[90%] -translate-x-1/2 -translate-y-1/2 overflow-y-auto rounded bg-white px-[30px] pt-8 pb-4 dark:bg-primary-200 md:mt-9 md:w-[600px] lg:mt-3"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <section className="absolute top-3 right-3"></section>
                    {showMiniLoader ? (
                      <div className="flex h-full w-full items-center justify-center">
                        <MiniLoader />
                      </div>
                    ) : (
                      <section className="text-left">
                        <section className="modalHeader relative mb-3">
                          <h3 className="w-80 text-lg font-bold leading-[22px] text-[#333333]">
                            {formatCheckoutMessage({
                              id: 'thankyouGreeting',
                              defaultMessage: 'Thank you for giving to those who serve',
                            })}
                          </h3>
                          {errorMessage && <p className="text-[#cd0808]">{errorMessage?.body}</p>}
                        </section>
                        {donationProducts?.map((product, id) => {
                          return (
                            <section key={id}>
                              {product?.variants[0]?.isOnStock && (
                                <>
                                  <section className="my-5 flex justify-between">
                                    <section>
                                      <section className="flex">
                                        <section className="mr-[9px]">
                                          <img
                                            src={
                                              product?.variants[0]?.imageSet?.[FRONT_SMALL]?.src?.[0]?.url ||
                                              product?.variants[0]?.images[0]
                                            }
                                            alt="Donation Product Image"
                                            className="w-[100px]"
                                          />
                                        </section>

                                        <section>
                                          <p className="font-semibold leading-5 text-[#043C63]">{product?.name}</p>
                                          <p className="mb-3 text-sm text-[#666666]">
                                            {formatCommonMessage({
                                              id: 'item',
                                              defaultMessage: 'item',
                                            })}
                                            {product?.productId}
                                          </p>

                                          <Price
                                            price={product?.variants[0]?.price}
                                            className={`mb-4 text-sm text-[#333333]`}
                                          />
                                          <section className="hidden md:block">
                                            <section className="flex items-center  md:flex">
                                              <p className="mb-2 mr-3 text-sm text-[#666666]">
                                                {formatCommonMessage({
                                                  id: 'qty',
                                                  defaultMessage: 'Qty',
                                                })}
                                                :
                                              </p>
                                              {isAddedDonationItem(product?.variants[0]?.sku) ? (
                                                <select
                                                  className="select h-[36px] w-[70px] cursor-pointer rounded border border-gray-600 text-affes-sm text-gray-900 "
                                                  value={
                                                    !updatedDonationItem[id]
                                                      ? getSelectedItem(product)[0]?.count
                                                      : parseInt(updatedDonationItem[id][0])
                                                  }
                                                  onChange={(e) => handleUpdateDropdown(id, e.target.value)}
                                                >
                                                  {maxCountList()}
                                                </select>
                                              ) : (
                                                <select
                                                  className="select h-[36px] w-[70px] cursor-pointer rounded border border-gray-600 text-affes-sm text-gray-900 "
                                                  value={selectedValue[id]}
                                                  onChange={(e) => handleDropDown(id, e.target.value)}
                                                >
                                                  {maxCountList()}
                                                </select>
                                              )}
                                            </section>
                                          </section>
                                        </section>
                                      </section>
                                    </section>
                                    <section className="hidden md:block">
                                      {!isAddedDonationItem(product?.variants?.[0]?.sku) ? (
                                        <button
                                          className="h-[44px] w-[97px] rounded-sm border border-blue-900 py-1.5 px-8 text-sm font-semibold text-blue-900"
                                          onClick={() =>
                                            addItemToCart(product, product?.variants[0], parseInt(selectedValue[id]))
                                          }
                                        >
                                          <span className="">
                                            {formatCommonMessage({ id: 'add', defaultMessage: 'Add' })}
                                          </span>
                                        </button>
                                      ) : (
                                        <button
                                          className={`h-[44px] w-[97px] rounded-sm border   text-sm font-semibold  ${
                                            updatedDonationItem[id]
                                              ? !updatedDonationItem[id][1]
                                                ? 'border-blue-900 text-blue-900'
                                                : 'border-gray-400 text-gray-400'
                                              : 'border-gray-400 text-gray-400'
                                          }`}
                                          onClick={() => {
                                            editItemQuantity(
                                              getSelectedItem(product)[0]?.lineItemId,
                                              parseInt(updatedDonationItem[id][0]),
                                            );
                                            setUpdatedDonationItem((prev) => ({
                                              ...prev,
                                              [id]: [updatedDonationItem[id][0], true],
                                            }));
                                          }}
                                          disabled={!updatedDonationItem[id] ? true : updatedDonationItem[id][1]}
                                        >
                                          <span className="">
                                            {formatCommonMessage({ id: 'update', defaultMessage: 'Update' })}
                                          </span>
                                        </button>
                                      )}
                                    </section>
                                  </section>
                                  <section className="flex justify-between md:hidden">
                                    <section className="flex items-center ">
                                      <p className="mb-2 mr-3 text-sm text-gray-600">
                                        {formatCommonMessage({ id: 'qty', defaultMessage: 'Qty' })}:{' '}
                                      </p>
                                      {isAddedDonationItem(product?.variants[0]?.sku) ? (
                                        <select
                                          className="select h-[36px] w-[70px] cursor-pointer rounded border border-gray-600 text-affes-sm text-gray-900 "
                                          value={
                                            !updatedDonationItem[id]
                                              ? getSelectedItem(product)[0]?.count
                                              : parseInt(updatedDonationItem[id][0])
                                          }
                                          onChange={(e) => handleUpdateDropdown(id, e.target.value)}
                                        >
                                          {maxCountList()}
                                        </select>
                                      ) : (
                                        <select
                                          className="select h-[36px] w-[70px] cursor-pointer rounded border border-gray-600 text-affes-sm text-gray-900 "
                                          tabIndex={-1}
                                          value={selectedValue[id]}
                                          onChange={(e) => handleDropDown(id, e.target.value)}
                                        >
                                          {maxCountList()}
                                        </select>
                                      )}
                                    </section>
                                    <section>
                                      {!isAddedDonationItem(product?.variants?.[0]?.sku) ? (
                                        <button
                                          className="h-[44px] w-[97px] rounded-sm border border-blue-900 py-1.5 px-8 text-sm font-semibold text-blue-900"
                                          onClick={() =>
                                            addItemToCart(product, product?.variants[0], parseInt(selectedValue[id]))
                                          }
                                        >
                                          <span className="">
                                            {formatCommonMessage({ id: 'add', defaultMessage: 'Add' })}
                                          </span>
                                        </button>
                                      ) : (
                                        <button
                                          className={`h-[44px] w-[97px] rounded-sm border   text-sm font-semibold  ${
                                            updatedDonationItem[id]
                                              ? !updatedDonationItem[id][1]
                                                ? 'border-blue-900 text-blue-900'
                                                : 'border-gray-400 text-gray-400'
                                              : 'border-gray-400 text-gray-400'
                                          }`}
                                          onClick={() => {
                                            editItemQuantity(
                                              getSelectedItem(product)[0]?.lineItemId,
                                              parseInt(updatedDonationItem[id][0]),
                                            );
                                            setUpdatedDonationItem((prev) => ({
                                              ...prev,
                                              [id]: [updatedDonationItem[id][0], true],
                                            }));
                                          }}
                                          disabled={!updatedDonationItem[id] ? true : updatedDonationItem[id][1]}
                                        >
                                          <span className="">
                                            {formatCommonMessage({ id: 'update', defaultMessage: 'Update' })}
                                          </span>
                                        </button>
                                      )}
                                    </section>
                                  </section>
                                </>
                              )}
                            </section>
                          );
                        })}
                        <hr className="my-4 bg-gray-500" />
                        <section className="text-right">
                          <button
                            className="h-[44px] w-full rounded bg-blue-900 py-3 text-base text-white md:w-[196px]"
                            onClick={() => {
                              handleCheckout();
                              if (loggedIn) {
                                trackUserAction(GA_CONTINUE_CHECKOUT, account);
                              }
                            }}
                          >
                            {formatCheckoutMessage({ id: 'proceedCheckout', defaultMessage: 'Proceed To Checkout' })}
                          </button>
                        </section>
                      </section>
                    )}
                  </section>
                </section>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {showLoader && <Loader />}
    </>
  );
}

export default DonationModal;
