import React, { useEffect, useState } from 'react';
import { GoPencil } from 'react-icons/go';
import { EXTPRICE_BRONZE } from 'helpers/constants/aafes';
import { useCart } from 'frontastic';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import AdditionalServicesModal from '../Modals/AdditionalServicesModal';
import Price from '../price';
interface additionalServices {
  setAddonItemId?: any;
  lineItem: any;
  cartListItems?: any;
  checkoutPage?: boolean;
  isOrderConfirmed?: boolean;
  isFromSFLtoWishlist?: boolean;
  wishListData?: any;
  isSFL?: boolean;
  isWishlist?: boolean;
  lineItemsStock?: any;
  saveForLaterPrices?: any;
}

const AdditionalServices = ({
  lineItem,
  cartListItems,
  setAddonItemId,
  checkoutPage,
  isOrderConfirmed,
  isFromSFLtoWishlist = false,
  wishListData,
  isSFL,
  isWishlist,
  lineItemsStock,
  saveForLaterPrices,
}: additionalServices) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const [isCheckout, setIsCheckout] = useState(false);
  const [showServicesModal, setServicesModal] = useState(false);
  const refProducts = lineItem?.variant?.attributes?.referencedProducts_Cart;
  const cartAddOn = lineItem?.attachments;
  const [addonItem, setAddonItem] = useState();
  const [addOnItemQty, setAddOnItemQty] = useState<number>();
  const [isNoDeliveryOption, setIsNoDeliveryOption] = useState(false);
  const [lineItemList, setLineItemList] = useState([]);
  const { studioConfig } = useCart();
  const deliveryServiceProductSKU = studioConfig?.deliveryServiceProductSKU;

  function handleShipClick() {
    setServicesModal(true);
  }
  useEffect(() => {
    const location = window ? window.location.pathname : '';
    if (location.indexOf('checkout') > -1) {
      setIsCheckout(true);
    }
  });

  useEffect(() => {
    setAddOnItemQty(lineItem?.count);
    if (lineItem?.attachments?.length) {
      const itemSKU = lineItem?.attachments?.[0]?.obj?.masterData?.current?.masterVariant?.sku;
      cartListItems?.lineItems?.map((cartItem, index) => {
        if (cartItem?.lineItemId === lineItem?.additionalLineItemId) {
          setAddonItem(cartItem);
          setAddonItemId(cartItem?.lineItemId);
        }
      });
    }
    if (lineItem?.isPickUp && refProducts?.length == 1 && refProducts?.[0].name == 'DELIVERY_SERVICE') {
      setIsNoDeliveryOption(true);
    }
    if (isSFL) {
      setLineItemList(cartListItems);
    } else {
      setLineItemList(isWishlist ? cartListItems?.lineItems : cartListItems?.displayLineItems?.[3]?.others);
    }
  }, [cartListItems, lineItem]);

  const addOnPrice = (item) => {
    return {
      centAmount: item?.totalPrice?.centAmount,
    };
  };
  const returnAdditionalPrice = (itemId, priceArray, itemSku) => {
    let price;
    if (itemSku === deliveryServiceProductSKU) {
      price = EXTPRICE_BRONZE;
      return price;
    }

    if (isWishlist || isSFL) {
      price = priceArray?.hasOwnProperty(itemId) && priceArray?.[itemId];
      if (price) {
        price = price?.extPrice;
      }
    } else {
      price = priceArray?.filter((item) => item?.lineItemId === itemId);
      if (price) {
        price = price?.[0]?.totalPrice;
      }
    }
    return price;
  };

  const isOutOfStock = (item) => {
    const availability = lineItemsStock?.[item?.lineItemId]?.availability;
    if (availability) {
      return !availability.isOnStock;
    }
    return lineItemsStock?.[item?.lineItemId]?.isOnStock === false;
  };

  return (
    ((checkoutPage || isOrderConfirmed) === undefined || (cartAddOn !== undefined && cartAddOn?.length > 0)) && (
      <section
        className={`${isCheckout && !setAddonItemId && 'xl:w-[657px]'} ${
          isSFL ? '' : 'block  rounded border border-[rgba(153,153,153,0.3)] px-[15px] py-[10px]'
        } ${isWishlist || isSFL ? 'mt-0' : 'mt-3'}
        `}
      >
        <section className={`${isWishlist ? 'grid gap-2' : ''}`}>
          <section className="flex pb-1 text-sm">
            <p className="font-bold text-gray-500">
              {formatCartMessage({ id: 'additionalServices', defaultMessage: 'Additional Services' })}
            </p>
            {isCheckout || isOrderConfirmed ? (
              ''
            ) : (
              <span>
                {!isFromSFLtoWishlist && !isSFL && (
                  <button disabled={isNoDeliveryOption}>
                    <GoPencil
                      className={(isNoDeliveryOption ? 'text-slate-400 ' : ' text-blue-900 ') + 'my-auto ml-2 text-lg'}
                      onClick={handleShipClick}
                    />
                  </button>
                )}
              </span>
            )}
          </section>
          <div>
            {(lineItem?.additionalLineItemIds?.length < 1 || !lineItem?.additionalLineItemIds) && (
              <span className="text-sm text-gray-500">
                {formatCartMessage({ id: 'noneSelected', defaultMessage: 'None Selected' })}
              </span>
            )}
          </div>
          {lineItem?.additionalLineItemIds?.map((addonItemId) =>
            lineItemList
              ?.filter((item) => item?.lineItemId === addonItemId)
              .map((lineItem, index) => {
                const outOfStock = isOutOfStock(lineItem);
                const additionalProductType = lineItem?.variant?.attributes?.customProductType;
                const imageUrl = JSON.parse(studioConfig?.vasConfig)?.[additionalProductType]?.imageUrl;
                return (
                  <section key={index} className="mb-2 flex gap-2 text-sm">
                    <div className="h-9 w-9 shrink-0">
                      <img src={imageUrl} alt="Additional Service Icon" />
                    </div>
                    <div className="grid w-full grid-cols-5 gap-[32px]">
                      <div className="col-span-3">
                        <p className="text-affes-base text-blue-900">{lineItem?.name}</p>
                        {outOfStock && (
                          <p className="text-affes-base text-red-900">
                            {formatCartMessage({ id: 'outOfStock', defaultMessage: 'Out of Stock' })}
                          </p>
                        )}
                      </div>
                      {setAddonItemId && !isOrderConfirmed && (
                        <div className="col-span-2 text-right text-affes-base text-blue-900">
                          <Price
                            className="inline"
                            price={returnAdditionalPrice(
                              addonItemId,
                              isFromSFLtoWishlist
                                ? wishListData
                                : isSFL
                                ? saveForLaterPrices
                                : cartListItems?.displayLineItems?.[3]?.others,
                              lineItem?.variant?.sku,
                            )}
                          />
                        </div>
                      )}
                    </div>
                  </section>
                );
              }),
          )}
        </section>
        {showServicesModal && (
          <AdditionalServicesModal
            servicesModal={showServicesModal}
            setServicesModal={setServicesModal}
            additionalServices={refProducts}
            lineItem={lineItem}
            addOnItemQty={addOnItemQty}
            parentSku={lineItem.variant}
            channel={lineItem?.channel?.key}
            personalizationTextLines={[]}
            subscriptionsFrequency={undefined}
            hideDeliveryOptions={lineItem?.isPickUp}
            isCart={true}
          />
        )}
      </section>
    )
  );
};

export default AdditionalServices;
