import React, { useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import ShippingModal from 'components/commercetools-ui/aafes/Modals/shippingModal';
import Loader from 'components/commercetools-ui/loader/Loader';
import { GA_PROFILE, GA_UPDATE_COM_PREF } from 'helpers/constants/aafes';
import useGATrackUserAction from 'helpers/hooks/gaEvents/useGATrackUserAction';
import { useFormat } from 'helpers/hooks/useFormat';
import { validateEmail } from 'helpers/utils/fieldValidators';
import { sendToMonetate } from 'helpers/utils/monetateHelper';
import SmoothScroller from 'helpers/utils/SmoothScroller';
import { fetchApiHub, revalidateOptions, useAccount, useCart } from 'frontastic';
declare global {
  interface Window {
    monetateQ: any;
  }
}

function CommunicationPreference() {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const { account, updateCustomerPreferences, update, isEmailExist, addresses: subscribedAddress = [] } = useAccount();
  const [seasonalMailTypeFlag, setSeasonalMailTypeFlag] = useState(
    account?.seasonalAdByMailSubscriptionType || 'unsubscribed',
  );
  const [seasonalMailChannelVal, setSeasonalMailChannelVal] = useState(
    account?.addressForSeasonalAd || account?.addresses?.[0]?.addressId,
  );
  const [successfulySaved, setSuccessfullySaved] = useState(false);
  const { trackFormComplete } = useGATrackUserAction();
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [getCustomerPreferenceBrazeData, setGetCustomerPreferenceBrazeData] = useState(null);

  const { getCustomerPreferenceBraze } = useAccount();

  const seasonalAdByMailSubscriptionType = () => {
    setSeasonalMailTypeFlag('subscribed');
    updateCustomerPreferences(
      'seasonalAdByMailSubscriptionType',
      'subscribed',
      'addressForSeasonalAd',
      seasonalMailChannelVal,
    );
  };

  const [promoEmailTypeFlag, setPromoEmailTypeFlag] = useState(
    getCustomerPreferenceBrazeData?.users?.[0]?.email_subscribe || account?.promoByEmailSubscription,
  );
  const [promoEmailChannelVal, setPromoEmailChannelVal] = useState(
    getCustomerPreferenceBrazeData?.users?.[0]?.email || account?.promoSubscriptionEmail,
  );
  const [promoSMSTypeFlag, setPromoSMSTypeFlag] = useState(
    getCustomerPreferenceBrazeData?.users?.[0]?.push_subscribe || account?.promoBySMSSubscription,
  );

  const [orderStatusEmailTypeFlag, setOrderStatusEmailTypeFlag] = useState(
    account?.orderByEmailSubscriptionType || 'unsubscribed',
  );
  const [orderStatusEmailChannelVal, setOrderStatusEmailChannelVal] = useState(
    account?.orderSubscriptionEmail || account?.email,
  );
  const [orderStatusSMSTypeFlag, setOrderStatusSMSTypeFlag] = useState(
    account?.orderBySMSSubscriptionType || 'unsubscribed',
  );
  const [orderStatusSMSChannelVal, setOrderStatusSMSChannelVal] = useState(
    account?.orderSubscriptionPhone || account?.addresses[0]?.phone,
  );
  const phoneNumberValidation = new RegExp(/^\+?\d{10,15}$/);
  const [promoEmailValid, setPromoEmailValid] = useState(true);
  const [orderStatusEmailValid, setOrderStatusValid] = useState(true);
  const [orderStatusSMSValid, setOrderStatusSMSValid] = useState(true);
  const [isShipOpen, setIsShipOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [saveButtonloader, setSaveButtonloader] = useState<any>({});
  const [isloading, setIsLoading] = useState<boolean>(false);
  const showShippingModal = () => {
    setIsShipOpen(true);
  };
  const closeShippingModal = () => {
    setIsShipOpen(false);
  };
  const closeSuccessfullySaved = () => {
    setTimeout(() => {
      setSuccessfullySaved(false);
    }, 2000);
  };
  const { setCartProductDetailEventSent, studioConfig, data } = useCart();
  const [monetateConfig, setMonetateConfig] = useState(null);
  const [monetateSent, setMonetateSent] = useState(false);
  useEffect(() => {
    if (studioConfig?.monetateConstants) {
      try {
        const monetateConfig = JSON?.parse(studioConfig?.monetateConstants?.replace(/'/g, '"'));
        setMonetateConfig(monetateConfig);
      } catch (error) {
        console.error('Errorparsing projectConfig:', error);
      }
    }
  }, [studioConfig]);

  useEffect(() => {
    const processMonetate = async () => {
      const callMonetate = async () => {
        setMonetateSent(true);
        await sendToMonetate(
          {
            page: 'communication_preferences',
            cart: {
              ...data,
            },
          },
          monetateConfig,
        );
      };
      if (account && monetateConfig && !monetateSent) {
        await callMonetate();
        setCartProductDetailEventSent(true);
      }
    };
    const fetchData = async () => {
      await processMonetate();
    };
    fetchData();
  }, [account, monetateConfig]);

  const APILoader = () => {
    return (
      <>
        <svg className="mx-auto h-6 w-6 animate-spin fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
          <path d="M8,8.5A3.5,3.5,0,1,1,4.5,5,3.5,3.5,0,0,1,8,8.5ZM4.5,14A3.5,3.5,0,1,0,8,17.5,3.5,3.5,0,0,0,4.5,14Zm16-2A3.5,3.5,0,1,0,17,8.5,3.5,3.5,0,0,0,20.5,12Zm0,2A3.5,3.5,0,1,0,24,17.5,3.5,3.5,0,0,0,20.5,14Zm-8,4A3.5,3.5,0,1,0,16,21.5,3.5,3.5,0,0,0,12.5,18Zm0-18A3.5,3.5,0,1,0,16,3.5,3.5,3.5,0,0,0,12.5,0Z" />
        </svg>
      </>
    );
  };
  const handleSuccessfullySaved = (rest) => {
    trackFormComplete({
      contentId: GA_PROFILE,
      formName: GA_UPDATE_COM_PREF,
    });
    setSaveButtonloader(rest);
    setSuccessfullySaved(true);
    closeSuccessfullySaved();
    SmoothScroller.scrollToTop();
  };

  const updatePreferencesAndAccount = async (props) => {
    const {
      flagTitle = undefined,
      flagValue = undefined,
      variable = undefined,
      variableValue = undefined,
      rest,
      profileAttribute = undefined,
      isUpdateProfile = false,
      errorAttribute = undefined,
    } = props;
    const response = await updateCustomerPreferences(flagTitle, flagValue, variable, variableValue);
    if (response?.errorCode === 500) {
      setSaveButtonloader(rest);
      setErrorMessage((prevState) => ({
        ...prevState,
        [flagTitle ?? errorAttribute]: response?.body,
      }));
      return { isfailedToUpdate: true };
    }
    if (isUpdateProfile) {
      const updateProfile = await update({
        [profileAttribute]: variableValue,
      });

      if (updateProfile?.errorCode === 500) {
        setSaveButtonloader(rest);
        setErrorMessage((prevState) => ({
          ...prevState,
          [flagTitle ?? errorAttribute]: updateProfile?.body,
        }));
        return { isfailedToUpdate: true };
      }
    }
    return { isfailedToUpdate: false };
  };

  const updateOrderSubscriptionPhone = async () => {
    if (phoneNumberValidation.test(orderStatusSMSChannelVal)) {
      const { orderBySMSSubscriptionType, ...rest } = saveButtonloader;
      if (
        account?.profilePhone !== orderStatusSMSChannelVal ||
        account?.orderSubscriptionPhone !== orderStatusSMSChannelVal ||
        account?.orderBySMSSubscriptionType !== orderStatusSMSTypeFlag
      ) {
        setSaveButtonloader((prevState) => ({
          ...prevState,
          orderBySMSSubscriptionType: true,
        }));
        try {
          const response = await updatePreferencesAndAccount({
            flagTitle: 'orderBySMSSubscriptionType',
            flagValue: orderStatusSMSTypeFlag,
            variable: 'orderSubscriptionPhone',
            variableValue: orderStatusSMSChannelVal,
            rest: rest,
            profileAttribute: 'profilePhone',
            isUpdateProfile: true,
          });
          if (response?.isfailedToUpdate) return null;
        } catch (error) {
          setSaveButtonloader(rest);
          return setErrorMessage((prevState) => ({
            ...prevState,
            orderBySMSSubscriptionType: error?.body,
          }));
        }
      }
      setOrderStatusSMSValid(true);
      handleSuccessfullySaved(rest);
    } else {
      setOrderStatusSMSValid(false);
    }
  };

  const updateOrderSubscriptionEmail = async () => {
    if (validateEmail(orderStatusEmailChannelVal)) {
      setSaveButtonloader((prevState) => ({
        ...prevState,
        orderByEmailSubscriptionType: true,
      }));
      const { orderByEmailSubscriptionType, ...rest } = saveButtonloader;
      const emailExist = await isEmailExist(orderStatusEmailChannelVal);
      if (emailExist?.statusCode === 200) {
        try {
          const response = await updatePreferencesAndAccount({
            flagTitle: 'orderByEmailSubscriptionType',
            flagValue: orderStatusEmailTypeFlag,
            variable: 'orderSubscriptionEmail',
            variableValue: orderStatusEmailChannelVal,
            rest: rest,
            profileAttribute: 'email',
            isUpdateProfile: true,
          });
          if (response?.isfailedToUpdate) return null;
          setOrderStatusValid(true);
          handleSuccessfullySaved(rest);
        } catch (error) {
          setSaveButtonloader(rest);
          setErrorMessage((prevState) => ({
            ...prevState,
            orderByEmailSubscriptionType: error?.body,
          }));
        }
      } else if (orderStatusEmailChannelVal === account?.email) {
        const response = await updatePreferencesAndAccount({
          variable: 'orderSubscriptionEmail',
          variableValue: orderStatusEmailChannelVal,
          flagTitle: 'orderByEmailSubscriptionType',
          flagValue: orderStatusEmailTypeFlag,
          rest: rest,
        });
        if (response?.isfailedToUpdate) return null;
        setPromoEmailValid(true);
        handleSuccessfullySaved(rest);
      } else if (
        account?.orderByEmailSubscriptionType === 'unsubscribed' &&
        account?.orderSubscriptionEmail === orderStatusEmailChannelVal
      ) {
        const response = await updatePreferencesAndAccount({
          variable: 'orderSubscriptionEmail',
          variableValue: orderStatusEmailChannelVal,
          flagTitle: 'orderByEmailSubscriptionType',
          flagValue: orderStatusEmailTypeFlag,
          rest: rest,
        });
        if (response?.isfailedToUpdate) return null;
        setOrderStatusValid(true);
        handleSuccessfullySaved(rest);
      } else {
        setSaveButtonloader(rest);
        setErrorMessage((prevState) => ({
          ...prevState,
          orderByEmailSubscriptionType: emailExist?.body,
        }));
      }
    } else {
      setOrderStatusValid(false);
    }
  };
  const updatePromoSubscriptionEmail = async () => {
    if (validateEmail(promoEmailChannelVal)) {
      setSaveButtonloader((prevState) => ({
        ...prevState,
        promoByEmailSubscriptionType: true,
      }));
      const { promoByEmailSubscriptionType, ...rest } = saveButtonloader;
      const emailExist = await isEmailExist(promoEmailChannelVal);
      if (emailExist?.statusCode === 200) {
        try {
          const response = await updatePreferencesAndAccount({
            flagTitle: 'promoByEmailSubscriptionType',
            flagValue: promoEmailTypeFlag,
            variable: 'promoSubscriptionEmail',
            variableValue: promoEmailChannelVal,
            rest: rest,
            profileAttribute: 'email',
            isUpdateProfile: true,
          });
          if (response?.isfailedToUpdate) return null;
          const orderSubscriptionEmail = await updatePreferencesAndAccount({
            variable: 'orderSubscriptionEmail',
            variableValue: promoEmailChannelVal,
            rest: rest,
            errorAttribute: 'promoByEmailSubscriptionType',
          });
          if (orderSubscriptionEmail?.isfailedToUpdate) return null;
          setPromoEmailValid(true);
          handleSuccessfullySaved(rest);
          if (errorMessage && errorMessage?.promoByEmailSubscriptionType) {
            const { promoByEmailSubscriptionType, ...rest } = errorMessage;
            setErrorMessage(rest);
          }
        } catch (error) {
          setSaveButtonloader(rest);
          setErrorMessage((prevState) => ({
            ...prevState,
            promoByEmailSubscriptionType: error?.body,
          }));
        }
      } else if (promoEmailChannelVal === account?.email) {
        const response = await updatePreferencesAndAccount({
          variable: 'promoSubscriptionEmail',
          variableValue: promoEmailChannelVal,
          flagTitle: 'promoByEmailSubscriptionType',
          flagValue: promoEmailTypeFlag,
          rest: rest,
        });
        if (response?.isfailedToUpdate) return null;
        setPromoEmailValid(true);
        handleSuccessfullySaved(rest);
      } else if (
        getCustomerPreferenceBrazeData?.users?.[0]?.email_subscribe === 'unsubscribed' ||
        (getCustomerPreferenceBrazeData?.users?.[0]?.email_subscribe === 'subscribed' &&
          (getCustomerPreferenceBrazeData?.users?.[0]?.email ?? account?.promoSubscriptionEmail) ===
            promoEmailChannelVal)
      ) {
        const response = await updatePreferencesAndAccount({
          flagTitle: 'promoByEmailSubscriptionType',
          flagValue: promoEmailTypeFlag,
          variable: 'promoSubscriptionEmail',
          variableValue: promoEmailChannelVal,
          rest: rest,
        });
        if (response?.isfailedToUpdate) return null;
        setPromoEmailValid(true);
        handleSuccessfullySaved(rest);
      } else {
        setSaveButtonloader(rest);
        setErrorMessage((prevState) => ({
          ...prevState,
          promoByEmailSubscriptionType: emailExist?.body,
        }));
      }
    } else {
      setPromoEmailValid(false);
    }
  };
  useEffect(() => {
    if (account?.orderSubscriptionEmail !== orderStatusEmailChannelVal)
      setOrderStatusEmailChannelVal(account?.orderSubscriptionEmail ?? account?.email);
    if (account?.orderSubscriptionPhone !== orderStatusSMSChannelVal)
      setOrderStatusSMSChannelVal(account?.orderSubscriptionPhone);
  }, [account]);

  useEffect(() => {
    if (account && getCustomerPreferenceBrazeData)
      if (
        (account?.promoSubscriptionEmail !== getCustomerPreferenceBrazeData?.users?.[0]?.email ||
          getCustomerPreferenceBrazeData?.users?.[0]?.push_subscribe !== account?.promoBySMSSubscription ||
          getCustomerPreferenceBrazeData?.users?.[0]?.email_subscribe !== account?.promoByEmailSubscription) &&
        !isLoad
      ) {
        setIsLoad(true);
        mutate(['/action/braze/getCustomerPreferenceBraze', account?.accountId]).then(() => {
          setIsLoad(false);
        });
      }
  }, [getCustomerPreferenceBrazeData, account]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const data = await getCustomerPreferenceBraze();
        setGetCustomerPreferenceBrazeData(data);
        if (data?.users?.[0]?.email_subscribe) setPromoEmailTypeFlag(data?.users?.[0]?.email_subscribe);
        if (data?.users?.[0]?.email) setPromoEmailChannelVal(data?.users?.[0]?.email);
        if (data?.users?.[0]?.push_subscribe) setPromoSMSTypeFlag(data?.users?.[0]?.push_subscribe);
      } catch (error) {
        console.error('Error fetching customer preference:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <section aria-labelledby="communication-preference-section">
      <h4 id="communication-preference-section" className="text-[21px] font-bold leading-6 text-gray-900">
        {formatAccountMessage({ id: 'communicationPreferences', defaultMessage: 'Communication Preferences' })}
      </h4>
      {successfulySaved && (
        <p
          className="my-4 rounded bg-sky-100 text-center text-lg leading-8 text-sky-800"
          role="status"
          aria-live="polite"
        >
          {formatAccountMessage({ id: 'savedMsg', defaultMessage: 'Your preferences were successfully saved.' })}
        </p>
      )}
      <section
        className="mb-3 mt-[13px] rounded border p-[15px] text-sm text-gray-900 md:mt-[10px]"
        aria-labelledby="communication-preferences-heading"
      >
        <section>
          <p className="mb-3 text-base font-bold">
            {formatAccountMessage({ id: 'promotionalMessage', defaultMessage: 'Promotional Messaging' })}
          </p>
          <p>
            {formatAccountMessage({
              id: 'communicationDesc',
              defaultMessage:
                'Send me updates from the Exchange with exclusive sales, promotions, coupons, and specials',
            })}
          </p>
          <section className="mt-[21px] block">
            <p className="my-2 text-base font-bold">{formatAccountMessage({ id: 'email', defaultMessage: 'Email' })}</p>

            <section className="mt-[14px] mb-[22px]">
              <input
                className="mr-1 h-[18px] w-[18px] p-1 accent-[#043C63] outline-[#666666] focus:ring-0 dark:focus:ring-blue-600"
                type="radio"
                name="Promotional Messaging"
                id="promotionalEmail_yes"
                onChange={() => setPromoEmailTypeFlag('opted_in')}
                checked={promoEmailTypeFlag === 'opted_in'}
                aria-labelledby="promotionalEmailLabel"
              />
              <label className="ml-1" htmlFor="promotionalEmail_yes" id="promotionalEmailLabel">
                {formatAccountMessage({
                  id: 'promotionalConfirmation',
                  defaultMessage: 'Yes, send me promotional emails',
                })}
              </label>
            </section>
            <section>
              <input
                id="promotionalEmail_no"
                onChange={() => setPromoEmailTypeFlag('unsubscribed')}
                className="mr-1 h-[18px] w-[18px] p-1"
                checked={promoEmailTypeFlag === 'unsubscribed' || promoEmailTypeFlag === 'subscribed'}
                type="radio"
                name="Promotional Messaging"
                aria-labelledby="promotionalEmailNoLabel"
              />
              <label htmlFor="promotionalEmail_no" className="ml-1" id="promotionalEmailNoLabel">
                {formatAccountMessage({ id: 'denied', defaultMessage: 'Not right now' })}
              </label>
            </section>
          </section>
          {promoEmailTypeFlag === 'opted_in' ? (
            <section>
              <section className="mt-8  w-full md:w-1/2">
                <label htmlFor="promoEmailInput" className="mb-[10px] block text-xs text-[rgba(102,102,102,1)]">
                  {formatAccountMessage({ id: 'email', defaultMessage: 'Email' })}{' '}
                  <sup className="h-[14px] w-[6px] text-[rgba(218,15,15,1)]">*</sup>
                </label>
                <section className="items-top mb-[10px] flex gap-x-[18px]">
                  <section>
                    <input
                      type="email"
                      id="promoEmailInput"
                      className={` h-9 w-[255px] rounded  text-xs text-gray-900  ${
                        !promoEmailValid ? 'border-red-500' : ''
                      }`}
                      maxLength={40}
                      value={promoEmailChannelVal}
                      onChange={(event) => {
                        setPromoEmailChannelVal(event.target.value);
                        if (!promoEmailValid) setPromoEmailValid(true);
                        if (errorMessage && errorMessage?.promoByEmailSubscriptionType) {
                          const { promoByEmailSubscriptionType, ...rest } = errorMessage;
                          setErrorMessage(rest);
                        }
                      }}
                      required
                      aria-invalid={!promoEmailValid ? 'true' : 'false'}
                      aria-describedby="promoEmailError promoEmailInstructions"
                    />
                    <p className="font-lato mt-[10px] text-xs font-normal leading-none text-[#333]">
                      {formatAccountMessage({
                        id: 'defaultEmailMessage',
                        defaultMessage: 'This email will be saved as your account default',
                      })}
                    </p>
                    {!promoEmailValid && (
                      <p id="promoEmailError" className="mt-1 text-red-900" role="alert">
                        Please provide a valid email address.
                      </p>
                    )}
                  </section>
                  <button
                    type="button"
                    className=" h-9 w-[81px] rounded bg-[rgba(4,60,99,1)] px-6  text-white"
                    onClick={() => {
                      updatePromoSubscriptionEmail();
                    }}
                    disabled={saveButtonloader?.promoByEmailSubscriptionType}
                  >
                    {saveButtonloader?.promoByEmailSubscriptionType
                      ? APILoader()
                      : formatCommonMessage({ id: 'save', defaultMessage: 'Save' })}
                  </button>
                </section>
              </section>
              {errorMessage?.promoByEmailSubscriptionType && (
                <p className="font-lato mt-[10px] text-sm font-normal leading-none text-red-900">
                  {errorMessage?.promoByEmailSubscriptionType}
                </p>
              )}
            </section>
          ) : (
            <section className="mt-8 w-full md:w-1/2">
              <button
                type="button"
                className=" h-9 w-[81px] rounded bg-[rgba(4,60,99,1)] px-6  text-white"
                onClick={() => {
                  updateCustomerPreferences('promoByEmailSubscriptionType', promoEmailTypeFlag, undefined, undefined);
                  if (errorMessage && errorMessage?.promoByEmailSubscriptionType) {
                    const { promoByEmailSubscriptionType, ...rest } = errorMessage;
                    setErrorMessage(rest);
                  }
                  setPromoEmailValid(true);
                  setSuccessfullySaved(true);
                  closeSuccessfullySaved();
                  SmoothScroller.scrollToTop();
                }}
              >
                {formatCommonMessage({ id: 'save', defaultMessage: 'Save' })}
              </button>
            </section>
          )}
        </section>
        <hr className="my-[25px]" />

        <section>
          <p className="mb-[14px] text-base font-bold">
            {formatAccountMessage({ id: 'seasonAdMails', defaultMessage: 'Seasonal Ad by Mail' })}
          </p>
          <section className="mt-5 block  ">
            <section className="mb-[22px]">
              <input
                className="mr-1 h-[18px] w-[18px] p-1 font-bold"
                type="radio"
                name="Seasonal Ad by Mail"
                onChange={() => {
                  seasonalAdByMailSubscriptionType();
                }}
                id="seasonalMailConfirmation_yes"
                defaultChecked={seasonalMailTypeFlag === 'subscribed'}
              />
              <label className="ml-1" htmlFor="seasonalMailConfirmation_yes">
                {formatAccountMessage({
                  id: 'seasonAdMailConfirmation',
                  defaultMessage: 'Yes, send me seasonal ads by mail',
                })}
              </label>
            </section>
            <section>
              <input
                className="mr-1 h-[18px] w-[18px] p-1"
                onChange={() => setSeasonalMailTypeFlag('unsubscribed')}
                id="seasonalMailConfirmation_no"
                defaultChecked={seasonalMailTypeFlag === 'unsubscribed'}
                type="radio"
                name="Seasonal Ad by Mail"
              />
              <label htmlFor="seasonalMailConfirmation_no" className="ml-1">
                {formatAccountMessage({ id: 'denied', defaultMessage: 'Not right now' })}
              </label>
            </section>
          </section>
          {seasonalMailTypeFlag === 'subscribed' ? (
            // TODO account?.addressForSeasonalAd is the addressId, we need to parse this addressId data instead of showing only the first one
            // TODO we need to set the addressId once the customer selects a different one from the overlay
            <section className="my-3">
              {subscribedAddress?.map(
                (address, index) =>
                  address?.addressId === account?.addressForSeasonalAd && (
                    <section>
                      <p className="mb-[5px] capitalize">
                        {address.firstName} {address?.lastName}
                      </p>
                      <p>
                        {address?.streetNumber} {address?.streetName}
                      </p>
                      <p>
                        {address?.city} {address?.country} {address?.postalCode}
                      </p>
                    </section>
                  ),
              )}
              <button type="button" onClick={showShippingModal} className="mt-[14px] text-[rgba(4,60,99,1)]">
                {formatCommonMessage({ id: 'selectDiffAddress', defaultMessage: 'Select a Different Address' })}{' '}
              </button>
              {isShipOpen && (
                <ShippingModal
                  closeSippingModal={closeShippingModal}
                  seasonalMailTypeFlag={seasonalMailTypeFlag}
                  communicationPref={true}
                />
              )}
            </section>
          ) : (
            <section className="mt-8 w-full md:w-1/2">
              <button
                type="button"
                className=" h-9 w-[81px] rounded bg-[rgba(4,60,99,1)] px-6  text-white"
                onClick={() => {
                  updateCustomerPreferences(
                    'seasonalAdByMailSubscriptionType',
                    seasonalMailTypeFlag,
                    undefined,
                    undefined,
                  );
                  setSuccessfullySaved(true);
                  closeSuccessfullySaved();
                  SmoothScroller.scrollToTop();
                }}
              >
                {formatCommonMessage({ id: 'save', defaultMessage: 'Save' })}
              </button>
            </section>
          )}
        </section>
        <hr className="my-[25px]" />

        <section>
          <p className="mb-3 text-base font-bold">
            {formatAccountMessage({ id: 'orderStatusMsg', defaultMessage: 'Order Status Messaging' })}
          </p>

          <section className="mt-5 block  ">
            <p className="mb-[14px] text-base font-bold">
              {formatAccountMessage({ id: 'email', defaultMessage: 'Email' })}
            </p>
            <section className="mb-[31px]">
              <section className="mb-[23px]">
                <input
                  className="mr-1 h-[18px] w-[18px] p-1"
                  type="radio"
                  name="Order Status Messaging"
                  onChange={() => setOrderStatusEmailTypeFlag('subscribed')}
                  id="orderStatusEmail_yes"
                  defaultChecked={orderStatusEmailTypeFlag === 'subscribed'}
                />
                <label className="ml-1" htmlFor="orderStatusEmail_yes">
                  {formatAccountMessage({ id: 'updateByEmail', defaultMessage: 'Yes, send me order updates by email' })}
                </label>
              </section>
              <section>
                <input
                  className="mr-1 h-[18px] w-[18px] p-1"
                  onChange={() => setOrderStatusEmailTypeFlag('unsubscribed')}
                  defaultChecked={orderStatusEmailTypeFlag === 'unsubscribed'}
                  type="radio"
                  name="Order Status Messaging"
                  id="orderStatusEmail_no"
                />
                <label htmlFor="orderStatusEmail_no" className="ml-1">
                  {formatAccountMessage({ id: 'denied', defaultMessage: 'Not right now' })}
                </label>
              </section>
            </section>

            {orderStatusEmailTypeFlag === 'subscribed' ? (
              <section>
                <section className=" mb-[10px] w-full md:w-1/2">
                  <label htmlFor="orderStatusEmail" className="mb-[10px] block text-xs text-[rgba(102,102,102,1)]">
                    {formatAccountMessage({ id: 'email', defaultMessage: 'Email' })}{' '}
                    <sup className="h-[14px] w-[6px] text-[rgba(218,15,15,1)]">*</sup>
                  </label>
                  <section className="items-top mb-[10px] flex gap-x-[18px]">
                    <section>
                      <input
                        type="text"
                        id="orderStatusEmail"
                        className={`h-9 w-[255px] rounded  text-xs text-gray-900  ${
                          !orderStatusEmailValid ? 'border-red-500' : ''
                        }`}
                        value={orderStatusEmailChannelVal}
                        onChange={(event) => {
                          setOrderStatusEmailChannelVal(event.target.value);
                          if (!orderStatusEmailValid) setOrderStatusValid(true);
                          if (errorMessage && errorMessage?.orderByEmailSubscriptionType) {
                            const { orderByEmailSubscriptionType, ...rest } = errorMessage;
                            setErrorMessage(rest);
                          }
                        }}
                        required
                        maxLength={40}
                        aria-invalid={!orderStatusEmailValid ? 'true' : 'false'}
                        aria-describedby={!orderStatusEmailValid ? 'email-error' : undefined}
                      />
                      <p className="font-lato mt-[10px] text-xs font-normal leading-none text-[#333]">
                        {formatAccountMessage({
                          id: 'defaultEmailMessage',
                          defaultMessage: 'This email will be saved as your account default',
                        })}
                      </p>
                      {!orderStatusEmailValid && (
                        <p id="email-error" role="alert" className="mt-1 text-red-900">
                          Please provide a valid email address.
                        </p>
                      )}
                    </section>
                    <button
                      type="button"
                      className="h-9 w-[81px] rounded  bg-[rgba(4,60,99,1)] px-6  text-white"
                      onClick={() => {
                        updateOrderSubscriptionEmail();
                      }}
                      disabled={saveButtonloader?.orderByEmailSubscriptionType}
                    >
                      {saveButtonloader?.orderByEmailSubscriptionType
                        ? APILoader()
                        : formatCommonMessage({ id: 'save', defaultMessage: 'Save' })}
                    </button>
                  </section>
                </section>
                {errorMessage?.orderByEmailSubscriptionType && (
                  <p className="font-lato mt-[10px] text-sm font-normal leading-none text-red-900">
                    {errorMessage?.orderByEmailSubscriptionType}
                  </p>
                )}
              </section>
            ) : (
              <section className="mt-8 w-full md:w-1/2">
                <button
                  type="button"
                  className=" h-9 w-[81px] rounded bg-[rgba(4,60,99,1)] px-6  text-white"
                  onClick={() => {
                    updateCustomerPreferences(
                      'orderByEmailSubscriptionType',
                      orderStatusEmailTypeFlag,
                      undefined,
                      undefined,
                    );
                    if (errorMessage && errorMessage?.orderByEmailSubscriptionType) {
                      const { orderByEmailSubscriptionType, ...rest } = errorMessage;
                      setErrorMessage(rest);
                    }
                    setSuccessfullySaved(true);
                    closeSuccessfullySaved();
                    setOrderStatusValid(true);
                    SmoothScroller.scrollToTop();
                  }}
                >
                  {formatCommonMessage({ id: 'save', defaultMessage: 'Save' })}
                </button>
              </section>
            )}
          </section>
        </section>
        <hr className="my-[25px]" />

        <section>
          <p className="mb-[14px] text-base font-bold">
            {' '}
            {formatCommonMessage({ id: 'textMessage1', defaultMessage: 'Text Messages (SMS)' })}
          </p>
          <section className="mt-3 mb-[17px] block  ">
            <section className="mb-[22px]">
              <input
                className="mr-1 h-[18px] w-[18px] p-1 font-bold"
                type="radio"
                name="Text Messages1"
                onChange={() => setOrderStatusSMSTypeFlag('subscribed')}
                id="orderStatusSMS_yes"
                defaultChecked={orderStatusSMSTypeFlag === 'subscribed'}
              />
              <label className="ml-1" htmlFor="orderStatusSMS_yes">
                {formatAccountMessage({
                  id: 'textMsgSMS',
                  defaultMessage: 'Yes, send me order updates via text messages (SMS)',
                })}
              </label>
            </section>
            <section>
              <input
                className="mr-1 h-[18px] w-[18px] p-1"
                onChange={() => setOrderStatusSMSTypeFlag('unsubscribed')}
                defaultChecked={orderStatusSMSTypeFlag === 'unsubscribed'}
                type="radio"
                name="Text Messages1"
                id="orderStatusSMS_no"
              />
              <label htmlFor="orderStatusSMS_no" className="ml-1">
                {formatAccountMessage({ id: 'denied', defaultMessage: 'Not right now' })}
              </label>
            </section>
          </section>
          {orderStatusSMSTypeFlag === 'subscribed' ? (
            <section>
              <section className=" mt-8 mb-3 w-full md:w-1/2">
                <label htmlFor="orderStatusPhoneNumber" className="my-[10px] block text-xs text-[rgba(102,102,102,1)]">
                  {formatCommonMessage({ id: 'mobilePhoneNo', defaultMessage: 'Mobile Phone Number' })}{' '}
                  <sup className="h-[14px] w-[6px] text-[rgba(218,15,15,1)]">*</sup>
                </label>
                <section className="  items-top mb-[10px] flex gap-x-[18px]">
                  <section>
                    <input
                      type="tel"
                      maxLength={15}
                      className={`h-9 w-[255px] rounded text-xs text-[#333]   placeholder-gray-500 placeholder:italic ${
                        !orderStatusSMSValid ? 'border-red-500' : ''
                      }`}
                      placeholder="5555555555"
                      value={orderStatusSMSChannelVal}
                      onChange={(event) => {
                        setOrderStatusSMSChannelVal(event.target.value);
                        if (!orderStatusSMSValid) setOrderStatusSMSValid(true);
                        if (errorMessage && errorMessage?.orderBySMSSubscriptionType) {
                          const { orderBySMSSubscriptionType, ...rest } = errorMessage;
                          setErrorMessage(rest);
                        }
                      }}
                      required
                      id="orderStatusPhoneNumber"
                      aria-invalid={!orderStatusSMSValid ? 'true' : 'false'}
                      aria-describedby={!orderStatusSMSValid ? 'phoneNumber-error' : undefined}
                    />
                    <p className="font-lato mt-[10px] text-xs font-normal leading-none text-[#333]">
                      {formatAccountMessage({
                        id: 'defaultNumberMessage',
                        defaultMessage: 'This number will be saved as your account default.',
                      })}
                    </p>
                    {!orderStatusSMSValid && (
                      <p id="phoneNumber-error" className="mt-1 text-red-900">
                        Please provide a valid mobile number.
                      </p>
                    )}
                  </section>
                  <button
                    className="h-9 w-[81px] rounded  bg-[rgba(4,60,99,1)] px-6  text-white"
                    onClick={() => {
                      updateOrderSubscriptionPhone();
                    }}
                    disabled={saveButtonloader?.orderBySMSSubscriptionType}
                  >
                    {saveButtonloader?.orderBySMSSubscriptionType
                      ? APILoader()
                      : formatCommonMessage({ id: 'save', defaultMessage: 'Save' })}
                  </button>
                </section>
              </section>
              {errorMessage?.orderBySMSSubscriptionType && (
                <p className="font-lato mt-[10px] text-sm font-normal leading-none text-red-900">
                  {errorMessage?.orderBySMSSubscriptionType}
                </p>
              )}
            </section>
          ) : (
            <section className="mt-8 w-full md:w-1/2">
              <button
                type="button"
                className=" h-9 w-[81px] rounded bg-[rgba(4,60,99,1)] px-6  text-white"
                onClick={() => {
                  updateCustomerPreferences('orderBySMSSubscriptionType', orderStatusSMSTypeFlag, undefined, undefined);
                  setSuccessfullySaved(true);
                  closeSuccessfullySaved();
                  setOrderStatusSMSValid(true);
                  SmoothScroller.scrollToTop();
                }}
              >
                {formatCommonMessage({ id: 'save', defaultMessage: 'Save' })}
              </button>
            </section>
          )}
        </section>
      </section>
      {isloading && <Loader />}
    </section>
  );
}

export default CommunicationPreference;
