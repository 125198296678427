import React, { useState } from 'react';
import { useRouter } from 'next/router';
import FavoriteIcon from 'components/icons/favoriteIcon';
import LinkIcon from 'components/icons/link';
import { NETWORKS, GTM_PRODUCT, GTM_WISHLIST, GTM_SHARE } from 'helpers/constants/aafes';
import { useAccount } from 'frontastic';
import { InlineShareButtons } from '../../sharethis';

function Iconography({ productId, onAddToWishlist, showLogin, isOnWishlist }) {
  const [loading, setLoading] = useState<boolean>(false);
  const addWishList = async () => {
    if (loggedIn) {
      setLoading(true);
      try {
        await onAddToWishlist();
      } catch (error) {}
      setLoading(false);
    } else {
      showLogin();
    }
  };

  const { loggedIn } = useAccount();
  const router = useRouter();
  const currentUrl = router?.asPath;

  const copyUrlToClipboard = () => {
    const url = window?.location?.href;
    navigator?.clipboard?.writeText(url);
  };

  return (
    <section className="mt-3 flex px-3">
      <div className="icon-container relative mt-3">
        {!loading ? (
          <button
            className="icon ease cursor-pointer transition-transform duration-200 hover:translate-y-[-5px] hover:opacity-80"
            onClick={addWishList}
            data-gtm-share
            data-gtm-method={GTM_WISHLIST}
            data-gtm-type={GTM_PRODUCT}
            data-gtm-id={productId}
          >
            <FavoriteIcon fill={`${isOnWishlist ? 'primaryBtn' : '#ffff'}`} />
          </button>
        ) : (
          <FavoriteIcon fill={`${isOnWishlist ? 'primaryBtn' : '#ffff'}`} isLoading />
        )}
      </div>
      <InlineShareButtons
        config={{
          alignment: 'center', // alignment of buttons (left, center, right)
          color: 'grey', // set the color of buttons (social, white)
          enabled: true, // show/hide buttons (true, false)
          labels: null, // button labels (cta, counts, null)
          language: 'en', // which language to use (see LANGUAGES)
          networks: NETWORKS,
          padding: 0,
          productId: productId,
        }}
      />
      <div className="icon-container relative mt-3">
        <button
          className="icon ease cursor-pointer transition-transform duration-200 hover:translate-y-[-5px] hover:opacity-80"
          onClick={copyUrlToClipboard}
          data-gtm-share
          data-gtm-method={GTM_SHARE}
          data-gtm-type={GTM_PRODUCT}
          data-gtm-id={productId}
        >
          <LinkIcon />
        </button>
      </div>
    </section>
  );
}

export default Iconography;
