import { useCallback } from 'react';
import {
  EXTPRICE_BRONZE,
  GA_ADD_PAYMENT_INFO,
  GA_ADD_SHIPPING_INFO,
  GA_ADD_TO_CART,
  GA_ADD_TO_WISHLIST,
  GA_CHECKOUT_TYPE_GUEST,
} from 'helpers/constants/aafes';
import { logEventToDataLayer } from 'helpers/utils/analytics';
import { transformItemData, calculateDiscount } from 'helpers/utils/commonGAUtils';

const useGATrackAddActions = () => {
  const trackAddToCart = useCallback((trackAddToCartObj: any) => {
    const { product, type, variant, quantity, isCart } = trackAddToCartObj;
    try {
      const items = transformItemData(product, undefined, undefined, quantity);
      const addToCart = {
        ...(isCart ? { content_id: type } : {}),
        ecommerce: {
          currency:
            variant?.price?.currencyCode || product?.price?.currencyCode || product?.variants?.[0]?.price?.currencyCode,
          value:
            // variant?.subTotal?.centAmount / 10 ** variant?.subTotal?.fractionDigits ||
            parseFloat(
              (
                (variant?.price?.centAmount / 10 ** variant?.price?.fractionDigits) * quantity ||
                (product?.price?.centAmount / 10 ** product?.price?.fractionDigits) * quantity
              )?.toFixed(variant?.price?.fractionDigits || product?.price?.fractionDigits),
            ),
          items: items,
        },
      };
      logEventToDataLayer(GA_ADD_TO_CART, addToCart);
    } catch (error) {
      console.error('ga4 add to cart event fail', error);
    }
  }, []);

  const trackAddPaymentInfo = useCallback((trackAddPaymentInfoObj: any) => {
    const { cartList, type } = trackAddPaymentInfoObj;
    const { discount } = calculateDiscount(cartList);
    const item = transformItemData(cartList?.lineItems);
    try {
      const addPaymentInfo = {
        content_id: type,
        ecommerce: {
          currency: cartList?.total?.currencyCode,
          value: cartList?.subTotal?.centAmount / 10 ** cartList?.subTotal?.fractionDigits,
          coupon: cartList?.discountCodes?.[0]?.code || undefined,
          payment_type:
            (type === GA_CHECKOUT_TYPE_GUEST ? 'Credit Card' : cartList?.payments?.[0]?.paymentMethod) || undefined,
          discount: discount,
          items: item,
        },
      };
      logEventToDataLayer(GA_ADD_PAYMENT_INFO, addPaymentInfo);
    } catch (error) {
      console.error('ga4 add payment info event fail', error);
    }
  }, []);

  const trackAddShippingInfo = useCallback((trackAddShippingInfoObj: any) => {
    const { cartData, contentId, shippingTier } = trackAddShippingInfoObj;
    const { discount } = calculateDiscount(cartData);
    try {
      const transformedItem = transformItemData(cartData?.lineItems);
      const shippingItem = {
        content_id: contentId,
        ecommerce: {
          currency: cartData?.subTotal?.currencyCode || EXTPRICE_BRONZE.currencyCode,
          value: cartData?.subTotal?.centAmount / 10 ** cartData?.subTotal?.fractionDigits,
          coupon: cartData?.discountCodes?.[0]?.code || undefined,
          discount: discount,
          shipping_tier: cartData?.shipping?.[0]?.shippingInfo?.name || shippingTier,
          items: transformedItem,
        },
      };
      logEventToDataLayer(GA_ADD_SHIPPING_INFO, shippingItem);
    } catch (error) {
      console.error('ga4 add shipping info event fail', error);
    }
  }, []);

  const trackGAAddToWishlist = useCallback((trackWishlistObj: any) => {
    const { product, type, isPDP = false } = trackWishlistObj;
    try {
      const transformedItem = transformItemData(product);
      const wishlistItem = {
        ecommerce: {
          currency: EXTPRICE_BRONZE.currencyCode,
          ...(isPDP ? {} : { item_list_name: type }),
          value:
            product?.variants?.[0]?.activePrice ||
            product?.variants?.[0]?.price?.centAmount / 10 ** product?.variants?.[0]?.price?.fractionDigits,
          items: transformedItem,
        },
      };
      logEventToDataLayer(GA_ADD_TO_WISHLIST, wishlistItem);
    } catch (error) {
      console.error('ga4 add to wishlist event fail', error);
    }
  }, []);

  const trackGAAddToCart = useCallback((trackAddToCartObj: any) => {
    const { product, type, price } = trackAddToCartObj;
    try {
      const transformedItem = transformItemData(product, undefined, price);
      const cartItem = {
        ecommerce: {
          currency: EXTPRICE_BRONZE.currencyCode,
          item_list_name: type,
          value: price,
          items: transformedItem,
        },
      };
      logEventToDataLayer(GA_ADD_TO_CART, cartItem);
    } catch (error) {
      console.error('ga4 add to cart event fail', error);
    }
  }, []);

  return {
    trackAddToCart,
    trackAddPaymentInfo,
    trackAddShippingInfo,
    trackGAAddToWishlist,
    trackGAAddToCart,
  };
};

export default useGATrackAddActions;
