import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { AutoSuggest, Suggestion } from '@Types/groupby/Autosuggest';
import { GA_EXCHANGE_CONTEXTUAL_MENU } from 'helpers/constants/aafes';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import useGATrackUserAction from 'helpers/hooks/gaEvents/useGATrackUserAction';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
import { useAccount, useProduct } from 'frontastic';
import Image from 'frontastic/lib/image';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import { getProductPricesWithFacilityPrice } from '../../../../helpers/utils/productDetailsFBP';
import SearchButton from '../../../icons/search';
const LoginModalContent = dynamic(() => import('../Modals/Login/login'));

function SearchForm({ setIsSearchActive, isMobile }) {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'common' });
  const { autoSuggestSearch } = useProduct();
  const { addSearchTerm, getSearchTerms, clearSearchTerms, loggedIn } = useAccount();
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [recentSearchTerms, setRecentSearchTerms] = useState<string[]>([]);
  const [isRecentSearchTermsFetched, setIsRecentSearchTermsFetched] = useState<boolean>(false);
  const [isSuggestionLoading, setIsSuggestionLoading] = useState<boolean>(false);
  const [suggestionConatiner, setSuggestionConatiner] = useState<boolean>(false);
  const [isShowContainer, setIsShowContainer] = useState<boolean>(false);
  const [isDesktopSize] = useMediaQuery(desktop);
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestionResponse, setSuggestionResponse] = useState<null | AutoSuggest>(null);
  const searchSectionRef = useRef<HTMLDivElement>(null);
  const { trackSearchResult } = useGATrackUserAction();
  const { account } = useAccount();
  const debounceTimerRef = useRef(null);
  const inputRef = useRef(null);
  const logTimerRef = useRef(null);
  const searchAndSuggestionRef = useRef(null);

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [loginModal, setLoginModal] = useState(false);
  const [fbpPrices, setFbpPrices] = useState(null);
  const trimmedSearchQuery = useMemo(() => {
    return searchQuery ? searchQuery?.trim() : null;
  }, [searchQuery]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  useEffect(() => {
    const { query } = router.query;
    if (!query) {
      setSearchQuery('');
    }
    setSuggestionResponse(null);
  }, [router.query]);

  const activateSearch = () => {
    setIsShowContainer(true);
    setIsSearchActive(true);
    setShowCancelButton(isMobile);
    if (isMobile) searchSectionRef.current.style.width = '100%';
  };

  const deactivateSearch = () => {
    setIsSearchActive(false);
    setShowCancelButton(false);
    if (isMobile) searchSectionRef.current.style.width = '50%';
  };

  const handleClickOutside = (e) => {
    if (!(searchSectionRef.current && searchSectionRef.current.contains(e.target))) {
      setIsShowContainer(false);
    }
  };
  const handleNavClick = () => {
    sessionStorage?.setItem('dym', 'true');
    sessionStorage?.setItem('sayt', 'false');
    setIsSearchActive(false);
    setShowCancelButton(false);
    if (isMobile) {
      searchSectionRef.current.style.width = '50%';
    }
    setIsShowContainer(false);
    setSuggestionResponse(null);
    inputRef.current && inputRef.current.blur();
    setSearchQuery('');
    setSelectedIndex(-1);
  };
  const handleSearch = async (e) => {
    sessionStorage?.setItem('sayt', 'true');
    sessionStorage?.setItem('dym', 'false');
    e.preventDefault();
    try {
      if (trimmedSearchQuery?.length > 1) {
        await addSearchTerm(searchQuery);
        setIsRecentSearchTermsFetched(false);
      }
    } catch (error) {
      console.log('Add Search Terms Failed:', error);
    }
    setIsSearchActive(false);
    setShowCancelButton(false);
    if (isMobile) {
      searchSectionRef.current.style.width = '50%';
    }
    setIsShowContainer(false);
    setSuggestionResponse(null);

    router.push(`/browse?query=${encodeURIComponent(searchQuery)}`);
    inputRef.current && inputRef.current.blur();
    setSearchQuery('');
    setSelectedIndex(-1);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!isShowContainer || !suggestionResponse) return;

    const textSuggestionsLength = suggestionResponse.suggestions?.length || 0;
    const productSuggestionsLength = suggestionResponse.records?.length || 0;
    const totalLength = textSuggestionsLength + productSuggestionsLength + 1; // +1 for View All Results

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex((prev) => {
          const next = prev + 1;
          return next >= totalLength ? 0 : next;
        });
        break;

      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex((prev) => {
          const next = prev - 1;
          return next < 0 ? totalLength - 1 : next;
        });
        break;

      case 'Enter':
        e.preventDefault();
        if (selectedIndex >= 0) {
          const isViewAllResults = selectedIndex === totalLength - 1;
          const isProductSuggestion = selectedIndex >= textSuggestionsLength && !isViewAllResults;

          if (isViewAllResults) {
            router.push(`/browse?query=${encodeURIComponent(searchQuery)}`);
            handleNavClick();
          } else if (isProductSuggestion && productSuggestionsLength > 0) {
            const productIndex = selectedIndex - textSuggestionsLength;
            if (productIndex < productSuggestionsLength) {
              const selectedProduct = suggestionResponse.records[productIndex];
              if (selectedProduct?.relativeUrl) {
                router.push(selectedProduct.relativeUrl);
                handleNavClick();
              }
            }
          } else if (selectedIndex < textSuggestionsLength) {
            const selectedSuggestion = suggestionResponse.suggestions[selectedIndex];
            if (selectedSuggestion) {
              router.push(selectedSuggestion.relativeUrl);
              handleNavClick();
            }
          }
        } else {
          handleSearch(e);
        }
        break;
    }
  };
  const isProductSelected = (productIndex: number): boolean => {
    const textSuggestionsLength = suggestionResponse?.suggestions?.length || 0;
    return selectedIndex === textSuggestionsLength + productIndex;
  };
  const getHighlightedText = (suggestion: Suggestion) => {
    // Split text on highlight term, include term itself into parts, ignore case
    if (!suggestion?.isCategory) {
      const text = suggestion?.name;
      const parts = text?.split(new RegExp(`(${searchQuery})`, 'gi'));
      return (
        <span>
          {parts?.map((part, index) =>
            part?.toLowerCase() === searchQuery?.toLowerCase() ? <b key={index}>{part}</b> : part,
          )}
        </span>
      );
    } else {
      return (
        <span>
          {suggestion?.displaySearchTerm} in <b>{suggestion?.name}</b>
        </span>
      );
    }
  };
  const handleSettingSearchQuery = (e) => {
    const currentValueLength = searchQuery?.length;
    const eventValue = e?.target?.value;
    const eventValueLength = eventValue?.length;
    eventValueLength === 3 && currentValueLength < eventValueLength
      ? setSuggestionConatiner(false)
      : setSuggestionConatiner(true);
    setSearchQuery(eventValue);
  };
  const handleSearchTermsFetch = async () => {
    setIsSuggestionLoading(true);
    if (!isRecentSearchTermsFetched) {
      try {
        const res = await getSearchTerms();
        setIsRecentSearchTermsFetched(true);
        const parsedRes = typeof res === 'string' ? JSON.parse(res) : [];
        setRecentSearchTerms(parsedRes);
      } catch (error) {
        console.log('Error while fetching/parsing recent search :', error);
        setIsRecentSearchTermsFetched(false);
      }
    }
    setIsSuggestionLoading(false);
  };

  const handleSetSearchQueryFromSuggestion = (suggestion) => {
    sessionStorage?.setItem('dym', 'true');
    sessionStorage?.setItem('sayt', 'false');
    inputRef?.current.focus();
    setSearchQuery(suggestion?.name);
    setSelectedIndex(-1);
  };

  const handleClearHistroy = () => {
    setRecentSearchTerms([]);
    clearSearchTerms();
    setIsShowContainer(false);
    setSelectedIndex(-1);
  };

  const closeSearchSeaction = () => {
    if (isMobile) {
      setSearchQuery('');
      deactivateSearch();
      setIsShowContainer(false);
      setSelectedIndex(-1);
    }
  };

  const logSearchEvent = useCallback((query, resultsCount) => {
    const suggestionsWrapper = document.getElementById('suggestions-wrapper');
    if (suggestionsWrapper && suggestionsWrapper.offsetParent !== null) {
      trackSearchResult({ contentId: GA_EXCHANGE_CONTEXTUAL_MENU, searchTerm: query, searchResults: resultsCount });
    }
  }, []);

  const debouncedLogSearchEvent = useCallback(
    (query, resultsCount) => {
      clearTimeout(logTimerRef.current);
      logTimerRef.current = setTimeout(() => logSearchEvent(query, resultsCount), 1000);
    },
    [logSearchEvent],
  );

  useEffect(() => {
    function handleClickOutside(event) {
      if (!searchAndSuggestionRef?.current?.contains(event?.target)) {
        closeSearchSeaction();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  useEffect(() => {
    const getSuggestions = () => {
      if (searchQuery?.length >= 3) {
        setIsSuggestionLoading(true);
        autoSuggestSearch(searchQuery)
          .then((res) => {
            setSuggestionResponse(res?.suggestions?.length > 0 ? res : null);
            debouncedLogSearchEvent(searchQuery, res?.suggestions?.length || 0);
          })
          .catch((err) => console.log(err))
          .finally(() => {
            setIsSuggestionLoading(false);
          });
      } else {
        setSuggestionResponse(null);
        setIsSuggestionLoading(false);
      }
    };

    const debounceGetSuggestions = () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }

      debounceTimerRef.current = setTimeout(getSuggestions, 300);
    };

    debounceGetSuggestions();

    return () => {
      clearTimeout(debounceTimerRef.current);
      clearTimeout(logTimerRef.current);
    };
  }, [searchQuery, debouncedLogSearchEvent]);

  useEffect(() => {
    setSelectedIndex(-1);
  }, [searchQuery]);
  useEffect(() => {
    setSuggestionConatiner(true);
  }, [suggestionResponse]);

  useEffect(() => {
    setIsShowContainer(false);
    setIsRecentSearchTermsFetched(false);
    setRecentSearchTerms([]);
  }, [loggedIn]);
  function showLogin() {
    setLoginModal(true);
    setIsShowContainer(false);
  }
  function closeLoginModal() {
    setLoginModal(false);
  }
  useEffect(() => {
    const abortController = new AbortController();
    const fetchPrices = async () => {
      try {
        const fbpItems = suggestionResponse.records.filter((record) => record.useFacilityBasedPrice);
        if (fbpItems && fbpItems.length > 0) {
          const fbpPrices = await getProductPricesWithFacilityPrice(fbpItems, account?.defaultStore?.key);
          setFbpPrices(fbpPrices);
          return;
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Price fetch error:', error);
          setFbpPrices({});
        }
      }
    };
    if (loggedIn) {
      fetchPrices();
    }
    return () => {
      abortController.abort();
      console.log('Aborting previous price fetch');
    };
  }, [suggestionResponse, loggedIn, account?.defaultStore?.key]);

  return (
    <>
      <section className="order-3 mx-2 w-1/2 md:mx-3 lg:w-[680px]" ref={searchSectionRef}>
        <section ref={searchAndSuggestionRef} className="search-wrapper relative">
          <form className="flex">
            <section className="flex h-[44px] grow items-center rounded-[43px] border border-gray-600 md:rounded-md">
              <section className="relative w-full items-center">
                <span id="search-assistive-text" className="sr-only"></span>
                <input
                  ref={inputRef}
                  type="text"
                  name={formatMessage({ id: 'search', defaultMessage: 'Search the Exchange' })}
                  placeholder={
                    isDesktopSize ? formatMessage({ id: 'search', defaultMessage: 'Search the Exchange' }) : 'Search'
                  }
                  aria-label={formatMessage({ id: 'search', defaultMessage: 'Search the Exchange' })}
                  aria-labelledby="search-assistive-text"
                  aria-haspopup="listbox"
                  aria-owns="suggestions-wrapper"
                  aria-expanded={isShowContainer}
                  className="w-full border-0 border-transparent bg-transparent text-sm leading-7 text-[#666666] focus:border-transparent focus:ring-0"
                  onFocus={(e) => {
                    activateSearch();
                    handleSearchTermsFetch();
                  }}
                  value={searchQuery}
                  onChange={handleSettingSearchQuery}
                  onKeyDown={handleKeyDown}
                />
                <button className="absolute right-4 top-2" aria-label="Search Button" onClick={(e) => handleSearch(e)}>
                  <SearchButton />
                </button>
              </section>
            </section>
            {showCancelButton && (
              <button
                onClick={() => {
                  deactivateSearch();
                  setSearchQuery('');
                  setIsShowContainer(false);
                  setSelectedIndex(-1);
                }}
                className="ml-2 text-[#043C63]"
              >
                Cancel
              </button>
            )}
          </form>

          {isShowContainer && searchQuery?.length >= 3 && suggestionConatiner ? (
            <section className="search-suggestions" id="suggestions-wrapper" role="listbox">
              <div
                className={`relative z-[9999] flex flex-wrap px-[14px] pt-[14px] ${
                  isSuggestionLoading ? 'aafes-loading' : ''
                }`}
              >
                {suggestionResponse?.suggestions && (
                  <ul className={`w-full ${suggestionResponse?.suggestions ? 'lg:w-1/2' : 'w-full'}`}>
                    {suggestionResponse?.suggestions.map((suggestion, index) => (
                      <li
                        className={`flex flex-row-reverse items-center justify-between gap-2 border-b border-gray-200 py-[6px] pl-[1px] pr-[15px] text-[14px] lg:flex-row lg:justify-start lg:border-0 lg:px-5 lg:py-[9px] ${
                          selectedIndex === index ? 'bg-gray-100' : ''
                        }`}
                        key={suggestion?.name}
                        role="option"
                        aria-selected={selectedIndex === index}
                      >
                        <button
                          className="suggestion_option_icon scale-[0.8]"
                          onClick={() => handleSetSearchQueryFromSuggestion(suggestion)}
                        >
                          <SearchButton />
                        </button>
                        <Link href={suggestion?.relativeUrl}>
                          <a onClick={handleNavClick}>
                            <span className="text-gray-900 hover:text-blue-900 hover:underline">
                              {getHighlightedText(suggestion)}
                            </span>
                          </a>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
                {suggestionResponse?.records?.length > 0 && (
                  <ul className="hidden w-1/2 lg:block">
                    {suggestionResponse?.records?.map((rec: any, index) => {
                      const record = rec?.product || rec;
                      return (
                        record?.relativeUrl && (
                          <li
                            className={`search_prod_display ${isProductSelected(index) ? 'bg-gray-100' : ''}`}
                            key={record?.displayName}
                            role="option"
                            aria-selected={isProductSelected(index)}
                          >
                            <Link href={record?.relativeUrl}>
                              <a onClick={handleNavClick}>
                                <Image
                                  id={'img' + record?.recordId}
                                  aria-labelledby="productName"
                                  src={record?.mediumImage}
                                  alt={record?.mediumImage}
                                  className="h-[70px] w-[70px] pl-1"
                                />
                              </a>
                            </Link>
                            <div className="text-[16px]">
                              <Link href={record?.relativeUrl}>
                                <a
                                  className={`hover:underline ${
                                    isProductSelected(index) ? 'text-blue-900' : 'text-gray-900'
                                  }`}
                                  onClick={handleNavClick}
                                >
                                  <div className="display_name_over_flow hover:text-blue-900 hover:underline">
                                    {record?.displayName}
                                  </div>
                                </a>
                              </Link>
                              {(record?.msrpRestriction === true || record?.useFacilityBasedPrice) && !loggedIn ? (
                                <button className="cursor-pointer" onClick={() => showLogin()}>
                                  <p className="py-2 text-base font-semibold leading-[24px] text-blue-900 hover:underline">
                                    {formatMessage({
                                      id: 'logForPricing',
                                      defaultMessage: 'Log in for Exchange pricing',
                                    })}
                                  </p>
                                </button>
                              ) : (
                                <SuggestionPrice product={record} loggedIn={loggedIn} fbpPrices={fbpPrices} />
                              )}
                            </div>
                          </li>
                        )
                      );
                    })}
                  </ul>
                )}
                <p
                  className={`border-black w-full border-t py-[9px] px-1 pt-2 text-[14px] lg:px-5 ${
                    selectedIndex ===
                    (suggestionResponse?.suggestions?.length || 0) + (suggestionResponse?.records?.length || 0)
                      ? 'bg-gray-100'
                      : ''
                  }`}
                >
                  <Link href={`/browse?query=${encodeURIComponent(searchQuery)}`}>
                    <a className="text-[#043C63]" onClick={handleNavClick}>
                      {getHighlightedText({ name: 'View All Results', isCategory: false })}
                    </a>
                  </Link>
                </p>
              </div>
            </section>
          ) : (
            isShowContainer &&
            recentSearchTerms?.length > 0 && (
              <section className="search-suggestions">
                <div
                  className={`relative z-[9999] pl-[14px] pr-[7px] pt-[14px] text-[13px] ${
                    isSuggestionLoading ? 'aafes-loading' : ''
                  }`}
                >
                  {recentSearchTerms?.map((term, i) => (
                    <div key={term} className="grid grid-cols-[1fr_auto] border-b border-gray-200">
                      <div className="self-center text-[#212529]">
                        <Link href={`/browse?query=${encodeURIComponent(term)}`}>
                          <a className="hover:underline" onClick={handleNavClick}>
                            {term}
                          </a>
                        </Link>
                      </div>
                      <button
                        className="arrow_btn_st self-center"
                        onClick={() => {
                          setSearchQuery(term);
                          inputRef?.current.focus();
                        }}
                      />
                    </div>
                  ))}
                  <div className="my-3">
                    {/* <Link href="#"> */}
                    <button
                      className="h-[22px] text-sm font-semibold italic text-[#043C63] hover:underline"
                      onClick={handleClearHistroy}
                    >
                      Clear history
                    </button>
                    {/* </Link> */}
                  </div>
                </div>
              </section>
            )
          )}
        </section>
      </section>
      <LoginModalContent loginModal={loginModal} closeModal={closeLoginModal} loginCallBack={activateSearch} />
    </>
  );
}

export default SearchForm;

const SuggestionPrice = ({ product, loggedIn, fbpPrices }) => {
  const variants = product?.variants;
  if (product.useFacilityBasedPrice) {
    const price = fbpPrices ? fbpPrices[product.repositoryId] : undefined;
    return price && price > 0 ? (
      <span className="block">{CurrencyHelpers.formatForCurrency(price * 100)}</span>
    ) : (
      <span className="block"></span>
    );
  } else {
    if (variants?.length < 1) {
      return <span className="block">{CurrencyHelpers.formatForCurrency(product?.activePrice * 100)}</span>;
    }
    const isProductOnSale = variants?.some((variant) => variant?.listPrice > variant?.salePrice);
    let priceValues;
    let finalPrice;
    if (isProductOnSale) {
      priceValues = variants?.map((variant) => variant?.salePrice || null);
    } else {
      priceValues = variants?.map((variant) => variant?.listPrice || null);
    }
    const filteredPriceValues = priceValues?.filter((value) => typeof value === 'number');
    const uniquePriceValues = Array.from(new Set(filteredPriceValues)) as number[];
    if (uniquePriceValues?.length > 1) {
      const minMsrp = Math.min(...uniquePriceValues) as number;
      const maxMsrp = Math.max(...uniquePriceValues) as number;
      finalPrice = `${CurrencyHelpers.formatForCurrency(minMsrp * 100)} - ${CurrencyHelpers.formatForCurrency(
        maxMsrp * 100,
      )}`;
    } else if (uniquePriceValues?.length === 1) {
      finalPrice = CurrencyHelpers.formatForCurrency(uniquePriceValues?.[0] * 100);
    } else {
      finalPrice = CurrencyHelpers.formatForCurrency(product?.activePrice * 100);
    }
    return (
      <span className={`block ${isProductOnSale ? 'text-[#DA0F0F]' : ''}`}>
        {isProductOnSale ? finalPrice + ' Sale' : finalPrice}
      </span>
    );
  }
};
