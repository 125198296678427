import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { AAFES_OWNED_SITE, GTM_FOOTER, GTM_MAIN_MENU, NON_AAFES_OWNED_SITE } from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';
import { isAAFESownedSite } from 'helpers/utils/commonGAUtils';
import { useAccount } from 'frontastic';
import ArrowForward from '../../../icons/arrowForward';
const LoginModal = dynamic(() => import('../Modals/Login/login'));

const ColumnLinks = ({ item, loggedIn, showLogin, setRedirectUrl }) => {
  return (
    <ul>
      {item.links.map((subItem, index) => {
        const navigationPath = `${item?.header} > ${subItem?.name}`;
        const isAAFESOwned = isAAFESownedSite(subItem?.link);
        const isgtmAffesOwned = isAAFESOwned ? AAFES_OWNED_SITE : NON_AAFES_OWNED_SITE;
        return (
          <li key={index} className="text-affes-sm leading-6 text-gray-900">
            {subItem?.loginRequired ? (
              loggedIn ? (
                subItem?.linkType === 'link' ? (
                  subItem?.openNewTab ? (
                    <a
                      href={subItem?.link}
                      target="_blank"
                      rel="noreferrer"
                      className="hover:underline"
                      data-gtm-path={navigationPath}
                      data-gtm-exit
                      data-gtm-id={isgtmAffesOwned}
                    >
                      {subItem.name}
                    </a>
                  ) : (
                    <Link href={subItem?.link}>
                      <a
                        className="hover:underline"
                        data-gtm-path={navigationPath}
                        data-gtm-exit
                        data-gtm-id={isgtmAffesOwned}
                      >
                        {subItem.name}
                      </a>
                    </Link>
                  )
                ) : (
                  <a
                    href={subItem?.link}
                    target={subItem?.openNewTab ? '_blank' : '_self'}
                    rel="noreferrer"
                    className="hover:underline"
                    data-gtm-path={navigationPath}
                    data-gtm-exit
                    data-gtm-id={isgtmAffesOwned}
                  >
                    {subItem.name}
                  </a>
                )
              ) : (
                <section
                  onClick={() => {
                    showLogin();
                    setRedirectUrl(subItem?.link);
                  }}
                >
                  <p
                    className={`${subItem?.linkType === 'link' ? 'cursor-pointer' : ''} hover:underline`}
                    data-gtm-path={navigationPath}
                  >
                    {subItem.name}
                  </p>
                </section>
              )
            ) : subItem?.linkType === 'link' ? (
              subItem?.openNewTab ? (
                <a
                  href={subItem?.link}
                  target="_blank"
                  rel="noreferrer"
                  className="hover:underline"
                  data-gtm-path={navigationPath}
                  data-gtm-exit
                  data-gtm-id={isgtmAffesOwned}
                >
                  {subItem.name}
                </a>
              ) : (
                <Link href={subItem?.link}>
                  <a
                    className="hover:underline"
                    data-gtm-path={navigationPath}
                    data-gtm-exit
                    data-gtm-id={isgtmAffesOwned}
                  >
                    {subItem.name}
                  </a>
                </Link>
              )
            ) : (
              <a
                href={subItem?.link}
                target={subItem?.openNewTab ? '_blank' : '_self'}
                rel="noreferrer"
                className="hover:underline"
                data-gtm-path={navigationPath}
                data-gtm-exit
                data-gtm-id={isgtmAffesOwned}
              >
                {subItem.name}
              </a>
            )}
          </li>
        );
      })}
    </ul>
  );
};

const ColumnSection = ({ item, activeIndex, handleAccordionClick, loggedIn, showLogin, setRedirectUrl }) => {
  return (
    <section key={'ColumnSection' + item?.id}>
      <section className="mb-6 flex w-full justify-between" onClick={() => handleAccordionClick(item.id)}>
        <button className="text-left text-affes-sm font-bold leading-[16.8px] text-[#333333]">{item.header}</button>
        <ArrowForward className="my-auto md:hidden " />
      </section>

      <section className="mb-3 block md:hidden">
        {activeIndex === item.id && (
          <ul className="mb-4">
            {item.links.map((subItem, index) => {
              const navigationPath = `${item?.header} > ${subItem?.name}`;
              const isAAFESOwned = isAAFESownedSite(subItem?.link);
              const isgtmAffesOwned = isAAFESOwned ? AAFES_OWNED_SITE : NON_AAFES_OWNED_SITE;
              return (
                <li className="text-affes-sm leading-6 text-gray-900" key={index}>
                  {subItem?.loginRequired ? (
                    loggedIn ? (
                      subItem?.linkType === 'link' ? (
                        subItem?.openNewTab ? (
                          <a
                            href={subItem?.link}
                            target="_blank"
                            rel="noreferrer"
                            data-gtm-path={navigationPath}
                            data-gtm-exit
                            data-gtm-id={isgtmAffesOwned}
                          >
                            {subItem.name}
                          </a>
                        ) : (
                          <Link href={subItem?.link}>
                            <a
                              className="hover:underline"
                              data-gtm-path={navigationPath}
                              data-gtm-exit
                              data-gtm-id={isgtmAffesOwned}
                            >
                              {subItem.name}
                            </a>
                          </Link>
                        )
                      ) : (
                        <a
                          href={subItem?.link}
                          target={subItem?.openNewTab ? '_blank' : '_self'}
                          rel="noreferrer"
                          data-gtm-path={navigationPath}
                          data-gtm-exit
                          data-gtm-id={isgtmAffesOwned}
                        >
                          {subItem.name}
                        </a>
                      )
                    ) : (
                      <section
                        onClick={() => {
                          showLogin();
                          setRedirectUrl(subItem?.link);
                        }}
                      >
                        <p className="cursor-pointer hover:underline" data-gtm-path={navigationPath}>
                          {subItem.name}
                        </p>
                      </section>
                    )
                  ) : subItem?.linkType === 'link' ? (
                    subItem?.openNewTab ? (
                      <a
                        href={subItem?.link}
                        target="_blank"
                        rel="noreferrer"
                        data-gtm-path={navigationPath}
                        data-gtm-exit
                        data-gtm-id={isgtmAffesOwned}
                      >
                        {subItem.name}
                      </a>
                    ) : (
                      <Link href={subItem?.link}>
                        <a
                          className="hover:underline"
                          data-gtm-path={navigationPath}
                          data-gtm-exit
                          data-gtm-id={isgtmAffesOwned}
                        >
                          {subItem.name}
                        </a>
                      </Link>
                    )
                  ) : (
                    <a
                      href={subItem?.link}
                      target={subItem?.openNewTab ? '_blank' : '_self'}
                      rel="noreferrer"
                      data-gtm-path={navigationPath}
                      data-gtm-exit
                      data-gtm-id={isgtmAffesOwned}
                    >
                      {subItem.name}
                    </a>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </section>
      <section className="mb-8 hidden md:block">
        <ColumnLinks item={item} loggedIn={loggedIn} showLogin={showLogin} setRedirectUrl={setRedirectUrl} />
      </section>
    </section>
  );
};

function Services({ footerColumns }) {
  const [activeIndex1, setActiveIndex1] = useState(null);
  const [activeIndex2, setActiveIndex2] = useState(null);
  const [activeIndex3, setActiveIndex3] = useState(null);
  const [activeIndex4, setActiveIndex4] = useState(null);
  const { loggedIn } = useAccount();
  const [loginModal, setLoginModal] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(' ');
  const router = useRouter();

  function showLogin() {
    setLoginModal(true);
  }

  function closeLoginModal() {
    setLoginModal(false);
  }

  const handleAccordionClick1 = (index) => {
    setActiveIndex1(index === activeIndex1 ? null : index);
  };
  const handleAccordionClick2 = (index) => {
    setActiveIndex2(index === activeIndex2 ? null : index);
  };
  const handleAccordionClick3 = (index) => {
    setActiveIndex3(index === activeIndex3 ? null : index);
  };
  const handleAccordionClick4 = (index) => {
    setActiveIndex4(index === activeIndex4 ? null : index);
  };

  const { formatMessage } = useFormat({ name: 'common' });

  return (
    <section className="">
      <section
        className="px-4 pt-[33px] md:flex md:justify-between md:px-6 xl:pr-32"
        aria-label="Footer services"
        data-gtm-menu
        data-gtm-id={GTM_FOOTER}
        data-gtm-name={GTM_MAIN_MENU}
      >
        <section className="md:mr-4 md:grid md:grid-rows-2" aria-label="footer links">
          {footerColumns.column1.map((item) => (
            <ColumnSection
              key={item.id}
              item={item}
              activeIndex={activeIndex1}
              handleAccordionClick={handleAccordionClick1}
              loggedIn={loggedIn}
              showLogin={showLogin}
              setRedirectUrl={setRedirectUrl}
            />
          ))}
        </section>

        <section className="md:mr-4 md:grid md:grid-rows-2">
          {footerColumns.column2.map((item) => (
            <ColumnSection
              key={item.id}
              item={item}
              activeIndex={activeIndex2}
              handleAccordionClick={handleAccordionClick2}
              loggedIn={loggedIn}
              showLogin={showLogin}
              setRedirectUrl={setRedirectUrl}
            />
          ))}
        </section>

        <section className="md:mr-4 md:grid md:grid-rows-2">
          {footerColumns.column3.map((item) => (
            <ColumnSection
              key={item.id}
              item={item}
              activeIndex={activeIndex3}
              handleAccordionClick={handleAccordionClick3}
              loggedIn={loggedIn}
              showLogin={showLogin}
              setRedirectUrl={setRedirectUrl}
            />
          ))}
        </section>

        <section className="md:mr-4 md:grid md:grid-rows-2">
          {footerColumns.column4.map((item) => (
            <ColumnSection
              key={item.id}
              item={item}
              activeIndex={activeIndex4}
              handleAccordionClick={handleAccordionClick4}
              loggedIn={loggedIn}
              showLogin={showLogin}
              setRedirectUrl={setRedirectUrl}
            />
          ))}
        </section>
      </section>
      <LoginModal
        loginModal={loginModal}
        closeModal={closeLoginModal}
        closeAccountFlyout={closeLoginModal}
        loginCallBack={() => router.push(redirectUrl)}
      />
    </section>
  );
}

export default Services;
