import React, { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { GrClose } from 'react-icons/gr';
import { useFormat } from 'helpers/hooks/useFormat';

interface MaxLimit {
  closeModal: any;
  customMessage: any;
  isSweepstake?: boolean;
}

function MaxLimit({ closeModal, customMessage, isSweepstake }: MaxLimit) {
  const { formatMessage } = useFormat({ name: 'common' });
  const ref = useRef<HTMLElement>();
  const router = useRouter();
  useEffect(() => {
    function handleClickOutside(event) {
      if (!ref?.current?.contains(event?.target)) {
        closeModal(false);
      }
      if (isSweepstake) {
        router.push('/sweepstakes');
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <section className="fixed top-0 left-0 z-50 flex h-screen w-screen items-center justify-center">
        <section className="overlay z-1 fixed left-0 top-0 h-full w-full bg-black-200 opacity-30"></section>
        <section
          ref={ref}
          className="absolute left-1/2 top-2/4 z-30 w-4/5 -translate-x-2/4 -translate-y-2/4 rounded bg-white p-4 md:w-2/4"
        >
          <section className="modalHeader relative">
            <button
              className="absolute right-0 top-0"
              onClick={() => {
                closeModal(false);
                if (isSweepstake) {
                  router.push('/sweepstakes');
                }
              }}
            >
              <GrClose />
            </button>
          </section>
          <section className="space-y-6 p-6">{customMessage}</section>
          <section className="modalFooter text-center">
            <button
              onClick={() => {
                closeModal(false);
                if (isSweepstake) {
                  router.push('/sweepstakes');
                }
              }}
              className="mr-2 mb-2 rounded-sm border border-blue-900 bg-blue-900 px-5 py-2.5 text-sm text-white hover:bg-white hover:text-blue-900 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-white dark:hover:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-700"
            >
              {formatMessage({ id: 'ok', defaultMessage: 'Ok' })}
            </button>{' '}
          </section>
        </section>
      </section>
    </>
  );
}

export default MaxLimit;
