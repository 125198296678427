import React, { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import router from 'next/router';
import { LineItem } from '@Types/cart/LineItem';
import { ProjectConfiguration } from '@Types/ProjectConfiguration';
import { COMING_SOON, FRONT_SMALL } from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';
import { trackEvent } from 'helpers/utils/gbTracker';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import {
  arePreOrderDatesInFuture,
  arePreOrderDatesInPast,
  isPreOrderEndFutureAndStreetPast,
  isPreOrderable,
  shouldShowAddToCartButton,
} from 'helpers/utils/preOrderable';
import { priceInUSD } from 'helpers/utils/priceConverter';
import { useAccount, useCart } from 'frontastic';
import { updateSaveForLaterItem } from 'frontastic/actions/saveForLater';
import Image from 'frontastic/lib/image';
import AdditionalServices from '../aafes/cart/additionalServices';
import ItemPrice from '../aafes/cart/itemPrice';
import MiniLoader from '../loader/miniLoader';
import Price from '../price';
import AdditionalServicesModal from './additionalServicesModal';
interface Props {
  lineItem: LineItem;
  lineItems: any;
  goToProductPage: (lineItem: LineItem, saveForLaterPrices: object) => void;
  removeLineItem: (lineItemId: string) => void;
  moveToWishlist: (lineItem: LineItem) => void;
  moveToCart: (lineItem: LineItem) => void;
  activeItem;
  setActiveItem;
  setQtyErrorMessage;
  saveForLaterPrices;
  allsaveForLaterPrices?: any;
}
const Item = ({
  lineItem,
  lineItems,
  goToProductPage,
  removeLineItem,
  moveToWishlist,
  moveToCart,
  activeItem,
  setActiveItem,
  setQtyErrorMessage,
  saveForLaterPrices,
  allsaveForLaterPrices,
}: Props) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const [showRemove, setShowRemove] = useState(false);
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { data: cartListItems } = useCart();
  const { account } = useAccount();
  const [showServices, setShowServices] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [personalizeTexts, setPersonalizeTexts] = useState([]);
  const cid = account?.cid;

  const addItemToCart = async (lineitem) => {
    moveToCart(lineitem);
  };
  const blockRef = useRef(null);
  const additionalRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (blockRef.current && !blockRef.current.contains(event.target)) {
        setShowRemove(false);
      }
    };
    document.body.addEventListener('click', handleOutsideClick);
    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, [setShowRemove]);
  const isDisableButton = (price) => {
    if (price) {
      return price?.extPrice === undefined ? true : false;
    }
  };
  const [matchingItem, setMatchingItem] = React.useState([]);

  useEffect(() => {
    setPersonalizeTexts(lineItem?.personalizationTextLines?.filter(Boolean));
  }, [lineItem]);
  return (
    <>
      {
        <section
          className="rounded-md border border-gray-700 p-3 md:block"
          onClick={() => setShowRemove(!showRemove)}
          ref={blockRef}
          aria-label="Save for later"
        >
          <section className="flex-col gap-y-4">
            <section className="mb-[20px] flex min-h-[125px] justify-center">
              <Image
                src={lineItem?.variant?.imageSet?.[FRONT_SMALL]?.src?.[0]?.url || lineItem?.variant?.images[0]}
                alt={lineItem.name}
                className="mb-"
                onClick={() => goToProductPage(lineItem, saveForLaterPrices)}
              />
            </section>
            <div className="cart_savefor_later">
              <Link href={lineItem.slug}>
                <a
                  className="sfl-product-name w-65% mb-2 block cursor-pointer overflow-hidden text-ellipsis text-left text-affes-sm font-bold text-blue-900 md:w-[185px] md:whitespace-nowrap lg:w-[200px] lg:whitespace-nowrap"
                  onClick={() => goToProductPage(lineItem, saveForLaterPrices)}
                >
                  {lineItem.name}
                </a>
              </Link>
            </div>
            <section>
              {lineItem.variant.sku && (
                <p className="mb-2 text-affes-sm font-medium text-gray-500">
                  <span>{formatProductMessage({ id: 'sku', defaultMessage: 'Item #' })}: </span>
                  <span className={'capitalize'}>{lineItem.variant.sku}</span>
                </p>
              )}
            </section>
            <section className="min-h-[24px]">
              {lineItem?.attachments?.length > 0 ? (
                <section
                  className="relative"
                  onMouseLeave={() => setShowServices(false)}
                  onMouseEnter={() => setShowServices(true)}
                >
                  <button
                    type="button"
                    onClick={() => setCloseModal(true)}
                    className="block text-affes-sm font-bold text-blue-900 md:text-[12px] xl:hidden"
                  >
                    + Additional Services
                  </button>
                  <button
                    type="button"
                    className="hidden text-affes-sm font-bold text-blue-900 md:text-[12px] xl:block"
                  >
                    + Additional Services
                  </button>
                  {showServices && (
                    <section
                      onMouseLeave={() => setShowServices(false)}
                      className="absolute left-[0px] bottom-[18px] z-50 w-[240px] rounded-md border border-gray-700 bg-white px-1 py-3 text-left lg:px-3 xl:left-[0px]"
                    >
                      <section className="boxArrow absolute right-12 -bottom-2 h-0 w-0 rotate-180 border-x-[8px] border-b-[8px] border-x-transparent border-b-gray-700 bg-transparent outline-gray-700 xl:right-36">
                        <section className="triangle absolute right-[-8px] top-[1px] h-0 w-0 border-x-[8px] border-b-[8px] border-x-transparent border-b-white bg-transparent"></section>
                      </section>
                      <section>
                        <section className="max-h-[190px] overflow-auto">
                          <AdditionalServices
                            lineItem={lineItem}
                            cartListItems={lineItems}
                            setAddonItemId={setMatchingItem}
                            isSFL={true}
                            saveForLaterPrices={allsaveForLaterPrices}
                          />
                        </section>
                      </section>
                    </section>
                  )}
                </section>
              ) : (
                personalizeTexts?.length > 0 &&
                (personalizeTexts?.length === 1 &&
                personalizeTexts.every((line, idx) => (idx === 1 ? line.length === 0 : line.length > 0)) ? (
                  <section>
                    <button type="button" className="block text-affes-sm font-bold text-blue-900 md:text-[12px]">
                      <span className="inline text-affes-sm font-bold text-blue-900 md:text-[12px]">
                        {formatMessage({ id: 'personalization', defaultMessage: 'Personalization' })}:
                      </span>
                      <span> {personalizeTexts[0]}</span>
                    </button>
                  </section>
                ) : personalizeTexts?.length === 2 && personalizeTexts.every((line) => line.length > 0) ? (
                  <section className="inverted-tooltip">
                    <button
                      type="button"
                      className="block text-affes-sm font-bold text-blue-900 md:text-[12px] xl:hidden"
                    >
                      + Personalizations
                    </button>
                    <button
                      type="button"
                      className="hidden text-affes-sm font-bold text-blue-900 md:text-[12px] xl:block"
                    >
                      + Personalizations
                    </button>
                    {
                      <ul className="inverted-tooltiptext inverted-tooltip-wide-left">
                        {personalizeTexts.map((personalizedLine, idx) => (
                          <li key={idx} className="text-affes-sm font-bold text-blue-900 md:text-[12px]">
                            {personalizedLine}
                          </li>
                        ))}
                      </ul>
                    }
                  </section>
                ) : (
                  <></>
                ))
              )}
            </section>
            <section className="flex">
              <button
                className="mr-3 min-w-[28px] cursor-pointer text-affes-sm font-medium text-blue-900 lg:w-auto"
                onClick={() => moveToWishlist(lineItem)}
                onKeyDown={(ev) => {
                  if (ev.key === 'Enter') {
                    moveToWishlist(lineItem);
                  }
                }}
              >
                {mapLocaleToMeaningfulFormat(router.locale).moveToWishList}
              </button>
              |{' '}
              <section className="relative">
                <button
                  className=" ml-3 text-affes-sm font-medium text-blue-900"
                  onClick={() => {
                    setShowRemove(true);
                    setActiveItem(lineItem);
                  }}
                >
                  {mapLocaleToMeaningfulFormat(router.locale).remove}
                </button>
                {showRemove && activeItem?.productId === lineItem?.productId && (
                  <section className="absolute left-[-36px] top-[-100px] w-28 rounded-sm border border-gray-600 bg-white px-1 py-3 text-center md:left-[-40px] lg:left-[-35px] lg:px-2 xl:left-[-20px]">
                    <section className="boxArrow xl:left-15 absolute left-12 -bottom-4 h-0 w-0 rotate-180 border-x-[16px] border-b-[16px] border-x-transparent border-b-gray-600 bg-transparent outline-gray-600">
                      <section className="triangle absolute left-[-16px] top-[1px] h-0 w-0 border-x-[16px] border-b-[16px] border-x-transparent border-b-white bg-transparent"></section>
                    </section>
                    <p className="mb-2 text-affes-sm font-bold text-gray-900">
                      {formatMessage({ id: 'removeItem', defaultMessage: 'Remove Item?' })}
                    </p>
                    <button
                      type="button"
                      onClick={() => removeLineItem(lineItem?.lineItemId)}
                      className="rounded-sm border border-blue-900 bg-white py-1 px-2 font-bold text-blue-900 hover:bg-blue-900 hover:text-white"
                    >
                      {formatCommonMessage({ id: 'yes', defaultMessage: 'Yes' })}
                    </button>
                    <button
                      type="button"
                      className="ml-1 rounded-sm border border-blue-900 bg-blue-900 py-1 px-2 font-bold text-white hover:bg-white hover:text-blue-900 lg:ml-2"
                      onClick={() => setShowRemove(false)}
                    >
                      {formatCommonMessage({ id: 'no', defaultMessage: 'No' })}
                    </button>
                  </section>
                )}
              </section>
            </section>
            <section className={`my-2 flex min-h-[70px] w-full items-start justify-between`}>
              <p className="text-affes-sm font-bold text-gray-900 ">
                <section className="">
                  {lineItem?.msrpPrice?.centAmount ? (
                    <>
                      <section className="flex items-center justify-start font-bold">
                        {saveForLaterPrices ? (
                          <ItemPrice item={saveForLaterPrices} view="saveForLater" />
                        ) : (
                          <MiniLoader value={'Price'} />
                        )}
                      </section>
                    </>
                  ) : (
                    <>
                      {saveForLaterPrices ? (
                        <ItemPrice item={saveForLaterPrices} view="saveForLater" />
                      ) : (
                        <MiniLoader value={'Price'} />
                      )}
                    </>
                  )}
                </section>
              </p>
            </section>
          </section>
          <section>
            {lineItem?.variant?.isOnStock ? (
              <>
                {lineItem?.variant?.attributes?.isProductShowOnSite &&
                lineItem?.variant?.attributes?.customProductType !== COMING_SOON ? (
                  lineItem?.variant?.attributes?.isPreOrderable ? (
                    arePreOrderDatesInPast(lineItem) ? (
                      <button
                        className={`rounded py-3 px-5 text-affes-sm font-medium text-white ${
                          isDisableButton(saveForLaterPrices) ? 'bg-gray-400' : 'bg-blue-900'
                        }`}
                        onClick={() => {
                          addItemToCart(lineItem);
                        }}
                        disabled={isDisableButton(saveForLaterPrices)}
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).addToCart}
                      </button>
                    ) : arePreOrderDatesInFuture(lineItem) ? (
                      <button
                        className={`rounded py-3 px-5 text-affes-sm font-medium text-white ${
                          isDisableButton(saveForLaterPrices) ? 'bg-gray-400' : 'bg-blue-900'
                        }`}
                        onClick={() => {
                          router.push(lineItem?.slug);
                        }}
                        disabled={isDisableButton(saveForLaterPrices)}
                      >
                        {formatCartMessage({ id: 'preOrder', defaultMessage: 'Pre Order' })}
                      </button>
                    ) : isPreOrderEndFutureAndStreetPast(lineItem) ? (
                      <button
                        className={`rounded py-3 px-5 text-affes-sm font-medium text-white ${
                          isDisableButton(saveForLaterPrices) ? 'bg-gray-400' : 'bg-blue-900'
                        }`}
                        onClick={() => {
                          addItemToCart(lineItem);
                        }}
                        disabled={isDisableButton(saveForLaterPrices)}
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).addToCart}
                      </button>
                    ) : shouldShowAddToCartButton(lineItem) ? (
                      <button
                        className={`rounded py-3 px-5 text-affes-sm font-medium text-white ${
                          isDisableButton(saveForLaterPrices) ? 'bg-gray-400' : 'bg-blue-900'
                        }`}
                        onClick={() => {
                          addItemToCart(lineItem);
                        }}
                        disabled={isDisableButton(saveForLaterPrices)}
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).addToCart}
                      </button>
                    ) : (
                      <button className="rounded bg-gray-400 py-3 px-5 text-affes-sm font-medium text-white" disabled>
                        {formatCartMessage({ id: 'unavailable', defaultMessage: 'Unavailable' })}
                      </button>
                    )
                  ) : shouldShowAddToCartButton(lineItem) ? (
                    <button
                      className={`rounded py-3 px-5 text-affes-sm font-medium text-white ${
                        isDisableButton(saveForLaterPrices) ? 'bg-gray-400' : 'bg-blue-900'
                      }`}
                      onClick={() => {
                        addItemToCart(lineItem);
                      }}
                      disabled={isDisableButton(saveForLaterPrices)}
                    >
                      {mapLocaleToMeaningfulFormat(router.locale).addToCart}
                    </button>
                  ) : (
                    <button className="rounded bg-gray-400 py-3 px-5 text-affes-sm font-medium text-white" disabled>
                      {formatCartMessage({ id: 'unavailable', defaultMessage: 'Unavailable' })}
                    </button>
                  )
                ) : (
                  <button className="rounded bg-gray-400 py-3 px-5 text-affes-sm font-medium text-white" disabled>
                    {formatCartMessage({ id: 'unavailable', defaultMessage: 'Unavailable' })}
                  </button>
                )}
              </>
            ) : (
              <button className="rounded bg-gray-400 py-3 px-5 text-affes-sm font-medium text-white" disabled>
                {formatCartMessage({ id: 'unavailable', defaultMessage: 'Unavailable' })}
              </button>
            )}
          </section>
        </section>
      }
      {closeModal && (
        <AdditionalServicesModal
          lineItem={lineItem}
          cartListItems={lineItems}
          setAddonItemId={setMatchingItem}
          isSFL={true}
          closeModal={() => setCloseModal(false)}
          saveForLaterPrices={allsaveForLaterPrices}
        />
      )}
    </>
  );
};
export default Item;
