import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { LineItem } from '@Types/wishlist/LineItem';
import { Wishlist } from '@Types/wishlist/Wishlist';
import { GA_TYPE_SAVED_FOR_LATER } from 'helpers/constants/aafes';
import useGATrackAddActions from 'helpers/hooks/gaEvents/useGATrackAddActions';
import useGATrackViewActions from 'helpers/hooks/gaEvents/useGATrackViewActions';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference } from 'helpers/reference';
import { priceInUSD } from 'helpers/utils/priceConverter';
import { useElementInViewport } from 'helpers/utils/useElementInViewport';
import { useAccount } from 'frontastic';
import { NextFrontasticImage } from 'frontastic/lib/image';
import MaxLimit from '../aafes/Modals/maxLimitModal';
import Loader from '../loader/Loader';
import Spinner from '../spinner';
import EmptySaveForLater from './emptyList';
import ItemList from './itemList';

export interface Props {
  pageTitle?: string;
  emptyStateImage?: NextFrontasticImage;
  emptyStateTitle?: string;
  emptyStateSubtitle?: string;
  emptyStateCTALabel?: string;
  emptyStateCTALink?: Reference;
  saveForLater: Wishlist;
  readonly removeLineItem: (lineItemId: string) => void;
  readonly moveToWishlist: (lineItem: LineItem) => Promise<void>;
  readonly moveToCart: (lineItem: LineItem) => Promise<void>; // Updated to return a Promise
  SFLPrices?: object;
}

const SaveForLater = ({
  saveForLater,
  removeLineItem,
  moveToWishlist,
  moveToCart,
  pageTitle,
  emptyStateImage,
  emptyStateTitle,
  emptyStateSubtitle,
  emptyStateCTALabel,
  emptyStateCTALink,
  SFLPrices,
}: Props) => {
  const sliderRef = useRef(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [sliderHeight, setSliderHeight] = useState(0);
  // i18n messages
  const { formatMessage } = useFormat({ name: 'cart' });
  const [maxLimitModal, setMaxLimitModal] = useState(false);
  const [maxLimitErrorMessage, setMaxLimitErrorMessage] = useState(null);
  const router = useRouter();
  const { viewItemListTracker, trackSelectItem } = useGATrackViewActions();
  const { trackGAAddToCart } = useGATrackAddActions();

  const isDataReady = useMemo(
    () => saveForLater?.lineItems?.length > 0 && Object.keys(SFLPrices).length > 0,
    [saveForLater, SFLPrices],
  );

  const handleTrackListViewItem = useCallback(() => {
    if (isDataReady) {
      viewItemListTracker({
        product: saveForLater.lineItems,
        type: GA_TYPE_SAVED_FOR_LATER,
        price: SFLPrices,
      });
    }
  }, [isDataReady, saveForLater]);

  const goToProductPage = (lineItem: LineItem, saveForLaterPrices: { extPrice: number }) => {
    router?.push(lineItem?.slug);
    if (lineItem && saveForLaterPrices) {
      const price = priceInUSD(saveForLaterPrices?.extPrice);
      trackSelectItem({
        product: lineItem,
        type: GA_TYPE_SAVED_FOR_LATER,
        price: price,
      });
    }
  };

  const { loggedIn } = useAccount();

  useEffect(() => {
    if (loggedIn && saveForLater?.lineItems) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [saveForLater]);

  useEffect(() => {
    if (!loading && !sliderHeight && loggedIn) {
      const height = sliderRef.current.clientHeight;
      setSliderHeight(height);
    }
  }, [loading]);

  const handleMoveToCart = async (lineItem: LineItem) => {
    setLoading(true);
    try {
      await moveToCart(lineItem).then((response: any) => {
        if (response.errorCode === 500) {
          setMaxLimitModal(true);
          setMaxLimitErrorMessage(response.body);
        } else {
          trackGAAddToCart({
            product: lineItem,
            type: GA_TYPE_SAVED_FOR_LATER,
            price: priceInUSD(SFLPrices[lineItem?.lineItemId]?.extPrice),
          });
        }
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleMoveToWishlist = async (lineItem: LineItem) => {
    setLoading(true);
    try {
      await moveToWishlist(lineItem).then((response: any) => {
        if (response.errorCode === 500) {
          setMaxLimitModal(true);
          setMaxLimitErrorMessage(response.body);
        }
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const filtereItemCount = saveForLater?.lineItems?.filter((lineItem) => !lineItem?.isAttachment);
  useElementInViewport('#save-for-later-section', handleTrackListViewItem, { threshold: 0.25 });

  if (loading) {
    return (
      <section className="wrapper mb-6">
        <Loader />
        <section
          className={` ${
            filtereItemCount?.length <= 0 || saveForLater?.lineItems?.length == undefined ? 'hidden' : ''
          }   flex justify-center`}
          style={{ height: sliderHeight ? sliderHeight + 'px' : 'unset' }}
        ></section>
      </section>
    );
  }

  if (false) {
    return (
      <EmptySaveForLater
        pageTitle={pageTitle}
        image={emptyStateImage}
        title={emptyStateTitle}
        subtitle={emptyStateSubtitle}
        ctaLabel={emptyStateCTALabel}
        ctaLink={emptyStateCTALink}
      />
    );
  }

  return (
    <>
      {maxLimitModal && <MaxLimit closeModal={setMaxLimitModal} customMessage={maxLimitErrorMessage} />}
      {loggedIn && (
        <section>
          <h2 className="mb-5 text-affes-2xl font-bold text-gray-900">
            {formatMessage({ id: 'savedForLater', defaultMessage: 'Saved for Later ii' })} ({filtereItemCount?.length}{' '}
            {formatMessage({ id: 'saveForLaterItems', defaultMessage: 'Items' })})
          </h2>
          <section
            ref={sliderRef}
            className={` ${
              filtereItemCount?.length <= 0 || saveForLater?.lineItems?.length == undefined ? 'hidden' : ''
            } `}
          >
            <ItemList
              saveForLater={saveForLater}
              goToProductPage={goToProductPage}
              removeLineItem={removeLineItem}
              moveToWishlist={handleMoveToWishlist}
              moveToCart={handleMoveToCart}
              SFLPrices={SFLPrices}
            />
          </section>
        </section>
      )}
    </>
  );
};

export default SaveForLater;
