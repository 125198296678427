import { useState, useEffect, useRef } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Category } from '@Types/product/Category';
import { ExternalPrice } from '@Types/product/ExternalPrice';
import { Money } from '@Types/product/Money';
import { Picker } from '@Types/product/Picker';
import { Variant } from '@Types/product/Variant';
import Loader from 'components/commercetools-ui/loader/Loader';
import { TENDER_TYPE } from 'helpers/constants/aafes';
import useGATrackAddActions from 'helpers/hooks/gaEvents/useGATrackAddActions';
import useGATrackViewActions from 'helpers/hooks/gaEvents/useGATrackViewActions';
import { sendToMonetate } from 'helpers/utils/monetateHelper';
import { useAccount, useCart, useProduct } from 'frontastic';
import { addToWishlist } from 'frontastic/actions/wishlist';
import YouMayLikeTastic from 'frontastic/tastics/aafes/you-may-like-tastic';
const LoginModalContent = dynamic(() => import('../Modals/Login/login'));
import MarketplaceProduct from './marketplace-product';
import ProductDetails from './product-details';
import FloatingHeader from './product-details/floatingHeader';
import ProductReview from './product-preview';
import { getVariantPromoAttributes } from 'helpers/utils/getVariantPromoAttributes';
export interface Props {
  product: UIProduct;
  variant: Variant;
  onChangeVariantIdx: (idx: number) => void;
  isGuestPdp?: boolean;
  recommendationComponent?: object;
}

export type UIProduct = {
  productId: string;
  name: string;
  variants: Variant[];
  listPrice?: Money;
  costPrice?: Money;
  msrpPrice?: Money;
  salePrice?: Money;
  isMarketplaceProduct?: boolean;
  isPreOrderable?: boolean;
  isPreOrderableComingSoon?: boolean;
  isProductShowOnSite?: boolean;
  price?: Money;
  images?: UIImage[];
  imageSets: UIImageSet[];
  colors?: UIColor[];
  sizes?: UISize[];
  description: string;
  details?: UIDetail[];
  isOnWishlist?: boolean;
  _url: string;
  categories?: Category[];
  pickers?: Picker[];
  storePrice?: Money;
  storePrices?: ExternalPrice;
  marketingBug?: string;
};
interface UIImage {
  id?: string;
  src?: string;
  alt?: string;
}
interface UIImageSet {
  src?: any;
  type?: string;
}
export interface UIColor {
  name?: string;
  key?: string;
  bgColor?: string;
  selectedColor?: string;
}
export interface UISize {
  label: string;
  key: string;
}
interface UIDetail {
  name: string;
  items: string[];
}

export default function ProductDetail({
  product,
  variant,
  onChangeVariantIdx,
  isGuestPdp,
  recommendationComponent,
}: Props) {
  const {
    setProductDetailPageEventSent,
    studioConfig,
    studioConfigError,
    studioConfigLoading,
    setIsAddItemAfterLogin,
    data: cartList,
  } = useCart();
  //next/router
  const router = useRouter();
  const location = router?.asPath;
  const { trackGAAddToWishlist } = useGATrackAddActions();
  const [loginModal, setLoginModal] = useState(false);
  const [accountFlyout, setAccountFlyout] = useState(false);
  const [continueAsGuestEligible, setContinueAsGuestEligible] = useState(false);
  const { account } = useAccount();
  const [isFloating, setIsFloating] = useState(false);
  const [monetateConfig, setMonetateConfig] = useState(
    studioConfig ? JSON.parse(studioConfig.monetateConstants.replace(/'/g, '"')) : null,
  );
  const [promtionFieldsLabels, setPromtionFieldsLabels] = useState(undefined);
  const [isShowYouMayLikeTasticComponent, setIsShowYouMayLikeTasticComponent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { trackViewItem } = useGATrackViewActions();
  const componentMounted = useRef<boolean>();
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [isLoginModalEligibleForAddToCart, setIsLoginModalEligibleForAddToCart] = useState<boolean>(false);
  const { getDroplets, setIsMarketPlacePdp } = useProduct();
  const [pdpDroplets, setPdpDroplets] = useState([]);
  const handleRedirectCallback = async () => {
    setIsLoading(true);
    await router.push(router.asPath);
    setIsLoading(false);
  };

  const loginCallBack = async () => {
    setIsLoading(true);
    await router.push(router.asPath);
    setIsAddItemAfterLogin(true);
    setIsLoginModalEligibleForAddToCart(false);
    setIsLoading(false);
  };

  const handleAddToWishList = async () => {
    const res = await addToWishlist(variant?.sku, 1);
    if (res?.wishlistId) {
      router.push('/account#wishlist');
      trackGAAddToWishlist({ product, isPDP: true });
    } else {
      res?.errorCode === 500 && setErrorMessage(res?.body);
    }
  };

  function closeLoginModal() {
    setLoginModal(false);
  }

  function showLogin(continueAsGuestEligible = false, eligibleForAddToCart = false) {
    setContinueAsGuestEligible(continueAsGuestEligible);
    setIsLoginModalEligibleForAddToCart(eligibleForAddToCart);
    setLoginModal(true);
  }

  useEffect(() => {
    setIsMarketPlacePdp(!!product?.isMarketplaceProduct);
    if (studioConfig?.monetateConstants && !studioConfigLoading) {
      setMonetateConfig(JSON.parse(studioConfig.monetateConstants.replace(/'/g, '"')));
    }
    if (studioConfig?.promtionFieldsLabels && !studioConfigLoading) {
      setPromtionFieldsLabels(studioConfig?.promtionFieldsLabels);
    }
    return () => {
      setIsMarketPlacePdp(false);
    };
  }, [studioConfig, product]);
  const previousProductRef = useRef<UIProduct | undefined>(undefined);

  useEffect(() => {
    const callMonetate = async () => {
      await sendToMonetate(
        {
          page: 'pdp',
          product: { ...product },
          cart: { ...cartList },
        },
        monetateConfig,
      );
    };

    const fetchData = async () => {
      if (product && monetateConfig) {
        await callMonetate();
        setProductDetailPageEventSent(true);
      }
    };

    if (previousProductRef.current != product && monetateConfig) {
      fetchData();
      previousProductRef.current = product;
    }
  }, [monetateConfig]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsFloating(scrollTop > 600);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (location.indexOf('scs') >= 0) {
      setIsShowYouMayLikeTasticComponent(false);
    } else {
      setIsShowYouMayLikeTasticComponent(true);
    }
  }, [location]);

  useEffect(() => {
    trackViewItem({ product });
  }, []);

  useEffect(() => {
    if (componentMounted?.current) {
      handleRedirectCallback();
    }
    componentMounted.current = true;
  }, [account?.defaultStore?.id]);

  useEffect(() => {
    if (promtionFieldsLabels) {
      fetchPDPDroplets();
    }
  }, [product?.productId, promtionFieldsLabels, account?.isMilStarCardHolder]);

  const fetchPDPDroplets = async () => {
    const categories = [
      ...new Set([
        ...(product?.categories
          ? product.categories.filter((category) => category?.name).map((category) => category.name.trim())
          : []),
        ...(product?.categories?.flatMap((category) => {
          if (!category?.parent) return [];
          return category.parent.flatMap((parentCategory) => {
            if (
              parentCategory &&
              typeof parentCategory === 'object' &&
              parentCategory.name.trim() !== 'Navigation Tree'
            ) {
              return parentCategory.name.trim();
            } else {
              return [];
            }
          });
        }) || []),
      ]),
    ];
    const category = categories?.length > 0 ? [...categories]?.shift() : null;
    const productId = product?.productId;
    const tenderType = TENDER_TYPE;

    const basePayload = {
      productId,
      ...(category && { category: category }),
    };
    const variant = product?.variants[0];

    const { result, activePrice, skuId } = getVariantPromoAttributes(variant, promtionFieldsLabels);

    function formatProductForApi() {
      return {
        ...basePayload,
        skuId: skuId,
        activePrice: activePrice,
        attributes: { ...result, categories, ...(account?.isMilStarCardHolder && { tenderType }) },
      };
    }
    const chunkSize = 32; // limit of the payload length
    const apiRequestPayload = formatProductForApi();
    const promises = [];
    setIsLoading(true);
    if (apiRequestPayload) {
      const payloadArray = [apiRequestPayload]; // Wrap the single item in an array
      promises.push(getDroplets(payloadArray));
    }

    try {
      const allPDPDroplets = await Promise.all(promises)
        .then((results) => results?.flat()?.filter((droplet) => !droplet?.errorCode))
        .catch((error) => {
          console.error('Error in PDP Droplets API calls:', error);
          return [];
        });
      setPdpDroplets(allPDPDroplets);
    } finally {
      setIsLoading(false);
    }
  };

  if (studioConfigLoading) {
    return <Loader />;
  }

  return (
    <div>
      {product?.isMarketplaceProduct ? (
        <MarketplaceProduct product={product} pdpDroplets={pdpDroplets} />
      ) : (
        <div className="md:py-[15px]">
          <div
            className={`${
              isFloating ? 'ease-[cubic-bezier(0.85, 0, 0.15, 1)] sticky top-0 z-10 duration-1000' : 'hidden'
            }`}
          >
            <FloatingHeader
              products={product}
              variants={variant?.attributes}
              sku={variant?.sku}
              showLogin={showLogin}
              accountFlyout={accountFlyout}
              promotion={pdpDroplets}
            />
          </div>
          {studioConfig && (
            <ProductReview
              product={product}
              variant={variant}
              onChangeVariantIdx={onChangeVariantIdx}
              onAddToWishlist={handleAddToWishList}
              showLogin={showLogin}
              isGuestPdp={isGuestPdp}
              studioConfig={studioConfig}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              pdpDroplets={pdpDroplets}
            />
          )}
          {isShowYouMayLikeTasticComponent && (
            <div className="mt-5 border-y border-y-gray-300">
              <YouMayLikeTastic
                data={{
                  shouldAutoScroll: true,
                  autoScrollInterval: 2500,
                  showAdditionalDetails: true,
                  recommendationComponent: recommendationComponent,
                }}
                variant={variant}
              />
            </div>
          )}
          {studioConfig && (
            <ProductDetails product={product} variant={variant} studioConfig={studioConfig} pdpDroplets={pdpDroplets} />
          )}
        </div>
      )}
      {isLoading && <Loader />}
      <div>
        <LoginModalContent
          loginModal={loginModal}
          closeAccountFlyout={setAccountFlyout}
          closeModal={closeLoginModal}
          loginCallBack={isLoginModalEligibleForAddToCart ? loginCallBack : handleRedirectCallback}
          continueAsGuestEligible={continueAsGuestEligible}
        />
      </div>
    </div>
  );
}
