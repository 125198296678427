import React, { useState } from 'react';
import Icon from 'components/icons/infoIcon';
import { GA_SUBSCRIPTION } from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import { useAccount, useCart } from 'frontastic';

function Subscriptions({
  variant,
  subscriptionsFrequencySettings,
  setSubscriptionsFrequencySettings,
  showProductFrequency,
  setshowProductFrequency,
}) {
  const [isDesktopSize] = useMediaQuery(tablet);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const { account } = useAccount();
  const { studioConfig: projectConfiguration } = useCart();
  const domainURL = projectConfiguration?.aafesDomain;

  const handleSubscriptionsClick = (e) => {
    setshowProductFrequency(e?.target?.checked);
    if (!e?.target?.checked) {
      setSubscriptionsFrequencySettings();
    }
  };

  const handleSettingSubscriptionsFrequency = (e) => {
    setSubscriptionsFrequencySettings(e?.target?.value);
  };

  return (
    <>
      {isDesktopSize ? (
        <>
          <section className="mt-4 ml-1 gap-y-4">
            <div className="flex">
              <div>
                <label htmlFor="subscriptionCheckbox" className="sr-only">
                  {formatProductMessage({
                    id: 'subscription',
                    defaultMessage: 'Set up subscription. No fees, cancel anytime',
                  })}
                </label>
                <input
                  type="checkbox"
                  name="subscriptions"
                  className="my-auto rounded"
                  onChange={handleSubscriptionsClick}
                  checked={showProductFrequency}
                  aria-labelledby="subscriptionLabel"
                />
              </div>
              <span id="subscriptionLabel" className="ml-3 h-[34px] w-[229.995px] text-sm text-gray-900">
                {formatProductMessage({
                  id: 'subscription',
                  defaultMessage: 'Set up subscription. No fees, cancel anytime',
                })}
              </span>
              <a
                href={`${domainURL}subscriptions`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={formatProductMessage({
                  id: 'subscription.info',
                  defaultMessage: 'go to subscriptions page',
                })}
                className="ml-[8px] pt-1"
              >
                <Icon className="cursor-pointer" />
              </a>
            </div>
            {showProductFrequency && (
              <>
                <section className="mt-[20.9px]">
                  <p className="h-[20px] text-[13px] font-bold leading-5 text-gray-900" id="deliveryFrequencyLabel">
                    {formatProductMessage({
                      id: 'product.frequency',
                      defaultMessage: 'Deliver Every',
                    })}
                  </p>
                  <section className="mt-[11px]" role="radiogroup" aria-labelledby="deliveryFrequencyLabel">
                    {variant?.attributes?.productFrequences?.map((option, index) => {
                      return (
                        <div key={index} className="mb-[15px] flex items-center pr-1">
                          <input
                            className="mr-[9px] h-[18px] w-[18px] p-1 accent-[#043C63] outline-[#666666] focus:ring-0 dark:focus:ring-blue-600"
                            type="radio"
                            id={`deliveryOption${index}`}
                            name="deliveryOption"
                            value={option}
                            checked={subscriptionsFrequencySettings === option}
                            onChange={handleSettingSubscriptionsFrequency}
                            aria-labelledby={`deliveryOption${index}Label`}
                          />
                          <span
                            id={`deliveryOption${index}Label`}
                            className="h-[20px] min-w-[54px] text-sm leading-5 text-[#333333]"
                          >
                            {option}
                          </span>
                        </div>
                      );
                    })}
                  </section>
                </section>
              </>
            )}
          </section>
        </>
      ) : (
        <>
          <section className="mb-[58px]">
            <div className="my-5 flex">
              <div>
                <input
                  type="checkbox"
                  id="subscriptionsCheckbox"
                  name="subscriptions"
                  className="my-auto rounded"
                  onChange={handleSubscriptionsClick}
                  checked={showProductFrequency}
                  aria-labelledby="subscriptionsLabel"
                />
              </div>
              <span id="subscriptionsLabel" className="ml-[6px] h-[17px] w-[271px] self-center text-sm text-gray-900">
                {formatProductMessage({
                  id: 'subscription',
                  defaultMessage: 'Set up subscription. No fees, cancel anytime',
                })}
              </span>
              <a
                href={`${domainURL}subscriptions`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={formatProductMessage({
                  id: 'subscription.info',
                  defaultMessage: 'go to subscriptions page',
                })}
                className="ml-[12px] self-center pt-1"
              >
                <Icon className="cursor-pointer" />
              </a>
            </div>
            {showProductFrequency && (
              <>
                <section className="mt-[18px]">
                  <p className="h-[20px] text-[13px] font-bold leading-5 text-gray-900">
                    {formatProductMessage({
                      id: 'product.frequency',
                      defaultMessage: 'Deliver Every',
                    })}
                  </p>
                  <section className="mt-[11px]">
                    <button
                      type="button"
                      className="font-lato flex h-[36px] w-full items-center justify-between rounded-[3px] border-[1px] border-[#999999] bg-[#FFFFFF] px-4 text-[14px] font-normal leading-[20px] text-[#333333]"
                      aria-expanded={isDropdownOpen}
                      onClick={() => {
                        setIsDropdownOpen(!isDropdownOpen);
                      }}
                      aria-haspopup="true"
                      aria-controls="frequencyDropdown"
                    >
                      <div>{subscriptionsFrequencySettings}</div>
                      <svg
                        className={`ml-9 h-5 w-5 transform ${isDropdownOpen ? 'rotate-180' : 'rotate-0'}`}
                        aria-hidden="true"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                      </svg>
                    </button>
                  </section>
                  {isDropdownOpen && (
                    <section
                      id="frequencyDropdown"
                      role="menu"
                      className="font-lato w-full rounded-[3px] border-[1px] border-[#999999] bg-[#FFFFFF] text-[14px] font-normal leading-[30px] text-[#333333]"
                    >
                      {variant?.attributes?.productFrequences?.map((option, index) => (
                        <div
                          key={index}
                          className="cursor-pointer pl-[16px]"
                          onClick={() => {
                            setSubscriptionsFrequencySettings(option);
                            setIsDropdownOpen(false);
                          }}
                          role="menuitem"
                        >
                          <span className="h-[20px] min-w-[36px] text-sm leading-5 text-[#333333]">{option}</span>
                        </div>
                      ))}
                    </section>
                  )}
                </section>
              </>
            )}
          </section>
        </>
      )}
    </>
  );
}

export default Subscriptions;
