import React, { Fragment } from 'react';
import NextLink from 'next/link';
import { Category } from '@Types/product/Category';
import DoubleArrow from 'components/icons/double-arrow';
import { GTM_BREADCRUMB, GTM_PAGE } from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';

export type BreadcrumbProps = {
  Separator?: React.ReactNode;
  categories?: Category[];
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ children, Separator = <DoubleArrow />, categories }) => {
  const parentCategories = categories?.[0]?.parent?.length > 0 ? categories?.[0]?.parent : [];
  const modifiedCategories =
    categories?.length > 0
      ? [...parentCategories, categories?.[0]]?.filter((category) => category?.name !== 'Navigation Tree')
      : [];

  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const exchange = formatCommonMessage({ id: 'exchange', defaultMessage: 'Exchange' });
  return (
    <nav
      className="flex pb-2"
      aria-label="Breadcrumb"
      data-gtm-menu
      data-gtm-id={GTM_PAGE}
      data-gtm-name={GTM_BREADCRUMB}
    >
      <ol role="list" className="flex items-center space-x-2 text-sm">
        <li>
          <div>
            <NextLink href="/">
              <a className="text-blue-900" aria-label="Go to homepage">
                <span className="sr-only">{exchange}</span>
                {exchange}
              </a>
            </NextLink>
          </div>
        </li>
        {modifiedCategories?.length > 0 && (
          <>
            <li className="mt-[3px]">{Separator}</li>
            {modifiedCategories?.map((category, index) => {
              if (index < modifiedCategories?.length - 1) {
                return (
                  <Fragment key={index}>
                    <li>
                      <NextLink href={category?.overrideUrl || '/'}>
                        <a className="text-blue-900" aria-label="Go to homepage">
                          <span className="sr-only">{category?.name}</span>
                          {category?.name}
                        </a>
                      </NextLink>
                    </li>
                    <li className="mt-[3px]">{Separator}</li>
                  </Fragment>
                );
              }
              return (
                <li key={index}>
                  <NextLink href={category?.overrideUrl || '/'}>
                    <a className="text-grey-900" aria-label="Go to homepage">
                      <span className="sr-only">{category?.name}</span>
                      {category?.name}
                    </a>
                  </NextLink>
                </li>
              );
            })}
          </>
        )}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
