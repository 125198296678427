import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { MdLockOutline, MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import Loader from 'components/commercetools-ui/loader/Loader';
import Price from 'components/commercetools-ui/price';
import {
  EGIFT_CUSTOM_PRODUCTS,
  customURL,
  GA_INITIATE_CHECKOUT,
  APO_FPO_STATES_MATCH,
  APO_FPO_CITIES_MATCH,
} from 'helpers/constants/aafes';
import useGATrackUserAction from 'helpers/hooks/gaEvents/useGATrackUserAction';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart, useAccount } from 'frontastic';
import DiscountForm from '../discount-form';
import DonationModal from '../Modals/donationModal';
import PrivayAct from '../Modals/privacyActModal';
import SecureCheckout from '../Modals/secureCheckout';
import SiteTermModal from '../Modals/siteTermsConditions';
function OrderSummary({
  cart,
  onSubmit,
  isCheckout = false,
  isOrderConfirmation = false,
  giftCardsApplied = {},
  placeOrderClicked = () => null,
  isOrderConfirmed,
  cardType,
  itemQty,
  setIsReviewButtonDisabled = (isDisabled: boolean) => null,
  itemInStock,
  setProceedToCheckoutError,
  isCardErrorMsg,
  getAllCustomMessages,
  cvv,
  storeClosed,
}) {
  const { loggedIn, account, addresses } = useAccount();
  const [isDisable_Checkout_MP_APOFPO, setIsDisable_Checkout_MP_APOFPO] = useState(false);
  useEffect(() => {
    if (cart?.displayLineItems?.length || cart?.customLineItems?.length) {
      let stockAndDeliveryValid = true;
      let addonAmountConstraintSatisfied = true;
      let isAddonProductAvailable = false;
      let isNonAddonProductAvailable = false;
      let isPickupForAddon = true;
      let isSubscription = true;
      let isShippingAvailable = true;
      let isPriceAvailable = true;
      let storeClosed = false;
      cart?.displayLineItems?.map((lineItems, index) => {
        const addressKey = Object.keys(lineItems);
        for (const key of addressKey) {
          if (Array.isArray(lineItems?.[key])) {
            lineItems?.[key].map((item, index) => {
              if (loggedIn && addresses.length <= 0 && !item?.addresKey) {
                setIsCheckoutDisabled(true);
              }
              if (
                item?.channel?.id &&
                item?.channel.isClickToBrick === false &&
                !EGIFT_CUSTOM_PRODUCTS?.includes(item?.variant?.attributes?.customProductType)
              ) {
                storeClosed = true;
              }
              if (!item?.isAttachment && !item?.isOverSizeFee) {
                if (
                  !itemInStock?.[item?.lineItemId]?.availability?.isOnStock ||
                  ((item.variant.isPickupInStore || item.variant.isShipToAddress) &&
                    !item.addressKey &&
                    !item.channel?.name)
                ) {
                  if (stockAndDeliveryValid) {
                    stockAndDeliveryValid = false;
                  }
                }
                if (item?.variant?.attributes?.isAddon) {
                  isAddonProductAvailable = true;
                } else {
                  isNonAddonProductAvailable = true;
                }
                if (item?.variant?.attributes?.isAddon && !item?.isPickUp) {
                  isPickupForAddon = false;
                }
                if (item?.variant?.attributes?.isAddon && item?.isSubscription) {
                  isSubscription = false;
                }
                if (
                  !EGIFT_CUSTOM_PRODUCTS.includes(item?.variant?.attributes?.customProductType) &&
                  !(item?.variant?.isPickupInStore || item?.variant?.isShipToAddress)
                ) {
                  isShippingAvailable = false;
                }
                if (!(item?.totalPrice?.centAmount > 0) && !item?.isGiftLineItem) {
                  // For Buy One - Get One Products
                  if (item?.discounts) {
                    item?.discounts?.forEach((discountedItem) => {
                      if (
                        discountedItem?.discountedAmount?.centAmount === item?.price?.centAmount &&
                        item?.totalPrice?.centAmount === 0
                      ) {
                        setIsCheckoutDisabled(false);
                      }
                    });
                  } else {
                    isPriceAvailable = false;
                  }
                }
              }
            });
          }
        }
      });
      if (
        (isAddonProductAvailable || isNonAddonProductAvailable) &&
        cart?.addOnMinThreshold > cart?.total?.centAmount &&
        !isPickupForAddon
      ) {
        addonAmountConstraintSatisfied = false;
      }
      if (
        stockAndDeliveryValid &&
        addonAmountConstraintSatisfied &&
        isShippingAvailable &&
        isPriceAvailable &&
        !storeClosed
      ) {
        setIsCheckoutDisabled(false);
        setIsReviewButtonDisabled(false);
      } else if (isCheckout && isCardErrorMsg) {
        setIsReviewButtonDisabled(false);
        setIsCheckoutDisabled(false);
      } else {
        setIsCheckoutDisabled(true);
        setIsReviewButtonDisabled(true);
      }
    } else {
      setIsCheckoutDisabled(true);
      setIsReviewButtonDisabled(true);
    }
    if (
      isCheckout &&
      ((cart?.remainingBalanceToPay > 0 && Object.keys(cart?.billingAddress ? cart.billingAddress : {}).length == 0) ||
        cart?.unallocatedAmount?.centAmount > 0)
    ) {
      setIsCheckoutDisabled(true);
      setIsReviewButtonDisabled(true);
    }
  }, [cart?.displayLineItems, itemInStock]);

  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [checkPromo, setCheckPromo] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isRequired, setIsRequired] = useState(false);
  const [down, setDown] = useState(true);
  const [secureCheckoutModal, setSecureCheckoutModal] = useState(false);
  const [code, setCode] = useState('');
  const [privacyAct, setPrivacyAct] = useState(false);
  const [siteterms, setSiteTerms] = useState(false);
  const [donationModal, setDonationModal] = useState(false);
  const { proceedToCheckout } = useCart();
  const router = useRouter();
  const [showLoader, setShowLoader] = useState(false);
  const [promoCodeApplied, setPromoCodeApplied] = useState<any>({});
  const { trackUserAction } = useGATrackUserAction();
  function handlePrivacyAct() {
    setPrivacyAct(false);
  }
  function handleSiteTerms() {
    setSiteTerms(false);
  }

  const [isCheckoutDisabled, setIsCheckoutDisabled] = useState<boolean>(true);
  const appliedCode = cart?.discountCodes?.find((discountCode) => discountCode?.state === 'MatchesCart');
  function handleArrow() {
    setDown(!down);
  }

  useEffect(() => {
    if (!isOrderConfirmation && !isCheckout) {
      const shouldDisableCheckout = cart?.customLineItems?.some((lineItem) => {
        const isAPOFPOAddress =
          APO_FPO_STATES_MATCH?.includes(lineItem?.shippingAddress?.state) ||
          APO_FPO_CITIES_MATCH?.includes(lineItem?.shippingAddress?.city?.toLowerCase());

        const isProductAllowAPOFPO = lineItem?.variant?.attributes?.isAPOFPOAvailable;
        if (lineItem?.isMarketplaceItem && !isProductAllowAPOFPO && isAPOFPOAddress) {
          return true;
        }
        return false;
      });

      if (shouldDisableCheckout) {
        setIsDisable_Checkout_MP_APOFPO(true);
      } else {
        setIsDisable_Checkout_MP_APOFPO(false);
      }
    }
    let promoDiscount = 0;
    const filteredDiscountedItems =
      cart?.displayLineItems?.map((lineItems: any) => {
        const tempFilteredDiscountedItems = [];
        const shippingAddressKeys = Object.keys(lineItems);
        for (const key of shippingAddressKeys) {
          if (Array.isArray(lineItems?.[key])) {
            const filteredItems = lineItems[key].filter((lineItem: any) => lineItem?.discounts?.length > 0);
            tempFilteredDiscountedItems.push(...filteredItems);
          }
        }
        return tempFilteredDiscountedItems;
      }) || [];

    const discountedItems = filteredDiscountedItems.flat();

    discountedItems?.map((item) => {
      item?.discounts?.map((discount) => {
        promoDiscount += discount?.discountedAmount?.centAmount * discount?.quantity;
      });
    });
    setPromoCodeApplied({
      centAmount: -(cart?.discount?.centAmount + (promoDiscount > 0 && promoDiscount)),
      currencyCode: 'USD',
      fractionDigits: 2,
    });
  }, [cart]);

  function handleDonationModal() {
    setDonationModal(false);
  }

  function closeSecureCheckout() {
    setSecureCheckoutModal(false);
  }

  useEffect(() => {
    if (appliedCode?.name) {
      setCode(appliedCode?.name);
      setShow(true);
      setIsRequired(false);
      setIsValid(true);
      setCheckPromo(false);
      setSuccess(true);
    }
  }, [appliedCode]);

  const EGIFT_CARD_ITEMS = 2;

  useEffect(() => {
    let isInvalidEmail = false;
    if (cart?.displayLineItems?.[EGIFT_CARD_ITEMS]['EGIFT_CARD']?.length) {
      isInvalidEmail = cart?.displayLineItems?.[EGIFT_CARD_ITEMS]['EGIFT_CARD']?.find((lineItem) => {
        return (
          EGIFT_CUSTOM_PRODUCTS?.includes(lineItem?.variant?.attributes?.customProductType) &&
          !lineItem?.recipientEmailAddress &&
          lineItem?.variant?.attributes?.customProductType !== 'DONATIONS'
        );
      });
      isInvalidEmail && setIsCheckoutDisabled(true);

      if (!isInvalidEmail && cart?.lineItems) {
        const isInvalidAddress = cart?.lineItems?.find(
          (lineItem) => lineItem?.addressKey === '' || lineItem.channelKey === '',
        );
        isInvalidAddress && setIsCheckoutDisabled(true);
      }
    }
  }, [cart?.displayLineItems, cart?.lineItems, itemInStock]);

  const handleCheckout = async () => {
    setProceedToCheckoutError(null);
    const res = await proceedToCheckout(!loggedIn);
    if (res.errorCode) {
      setProceedToCheckoutError(
        typeof res.body === 'string'
          ? res.body
          : cart?.lineItems?.map(
              (lineItem) =>
                res.body?.[lineItem?.lineItemId] && (
                  <div key={lineItem?.lineItemId}>{`${res.body?.[lineItem?.lineItemId]} - ${lineItem?.name}`}</div>
                ),
            ),
      );
      setDonationModal(false);
    } else {
      setShowLoader(true);
      loggedIn ? router.push(customURL?.checkout) : router.push(customURL?.guestCheckout);
      setShowLoader(false);
    }
  };
  const [isFixed, setIsFixed] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const isMobile = window.innerWidth <= 767;
      if (isMobile && scrollPosition > 400) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <section className="mb-5  rounded border border-gray-700">
      <section
        className="flex cursor-pointer justify-between  bg-gray-700 py-2 pl-3 font-bold md:hidden"
        onClick={handleArrow}
      >
        <h3 className="text-[18px] text-gray-900 ">
          <span className="leading-[22px]">
            {formatCartMessage({ id: 'order.summary', defaultMessage: 'Order Summary' })}
          </span>
        </h3>
        {!down ? (
          <section className="my-auto mr-3 ">
            <MdOutlineKeyboardArrowDown onClick={handleArrow} className="text-3xl text-gray-900" />
          </section>
        ) : (
          <section className="my-auto mr-3 ">
            <MdOutlineKeyboardArrowUp onClick={handleArrow} className="text-3xl text-gray-900" />
          </section>
        )}
      </section>
      <section className="hidden justify-between  bg-gray-700 py-2 pl-3 font-bold md:flex">
        <h3 className="text-[18px] leading-[22px] text-gray-900">
          {formatCartMessage({ id: 'order.summary', defaultMessage: 'Order Summary' })}
        </h3>
      </section>
      {down && (
        <section className=" orderSummary px-3">
          {!isOrderConfirmed && !isCheckout && loggedIn && (
            <>
              <DiscountForm cart={cart} getAllCustomMessages={getAllCustomMessages}></DiscountForm>
              <hr className="border-[rgba(153, 153, 153, 0.3)] border" />
            </>
          )}

          <section className="mt-2">
            <section className="text-sm leading-7 ">
              <section className="flex justify-between">
                <p className="text-gray-900">
                  {formatCartMessage({ id: 'aafesSubtotal', defaultMessage: 'Subtotal :' })}
                </p>
                <p className="font-bold text-gray-900">
                  <Price
                    price={cart?.subTotal || {}}
                    className="text-base font-bold text-gray-900 dark:text-light-100"
                  />
                </p>
              </section>
              <section className="flex justify-between">
                <p className="text-gray-900">
                  {formatCartMessage({ id: 'estimatedShipping', defaultMessage: 'Estimated Shipping : ' })}
                </p>
                {cart?.shippingCost?.centAmount === 0 ? (
                  <p className="font-normal text-gray-900">
                    {formatCheckoutMessage({ id: 'free', defaultMessage: 'Free' })}
                  </p>
                ) : (
                  <p className="text-gray-900">
                    <Price price={cart?.shippingCost} />
                  </p>
                )}
              </section>
              {cart?.overSizeFee && (
                <section className="flex justify-between">
                  <p className="text-gray-900">
                    {formatCartMessage({ id: 'Oversize.fees', defaultMessage: 'Oversize Fee :' })}
                  </p>
                  <p className="font-bold text-gray-900">
                    <Price
                      price={cart?.overSizeFee || {}}
                      className="text-base font-bold text-gray-900 dark:text-light-100"
                    />
                  </p>
                </section>
              )}
              {promoCodeApplied.centAmount < 0 && (
                <section className="flex justify-between">
                  <p className="text-gray-900">
                    {formatCheckoutMessage({ id: 'promotion', defaultMessage: 'Promotion :' })}
                  </p>
                  <p className="text-gray-900">
                    <Price price={promoCodeApplied || {}}></Price>
                  </p>
                </section>
              )}
              {(isCheckout || isOrderConfirmed) && Object.keys(giftCardsApplied).length > 0 && (
                <section className="flex justify-between">
                  <p className="text-gray-900">
                    {formatCheckoutMessage({ id: 'giftCards', defaultMessage: 'Gift Card(s):' })}
                  </p>
                  <p className="text-gray-900">
                    <Price price={giftCardsApplied}></Price>
                  </p>
                </section>
              )}
              {loggedIn && (
                <section className="flex justify-between">
                  <p className="text-gray-900">
                    {formatCartMessage({ id: 'yourBenefit', defaultMessage: 'Your Benefit :' })}
                  </p>
                  <p className="text-gray-900">{formatCartMessage({ id: 'noTax', defaultMessage: 'No Tax' })}</p>
                </section>
              )}
              <section className="mt-5 flex justify-between">
                <p className="flex items-center text-base font-bold text-gray-900">
                  <span className="leading-[19px]">
                    {formatCartMessage({ id: 'estimatedTotal', defaultMessage: 'Order Total :' })}
                  </span>
                </p>
                <p>
                  {isOrderConfirmation ? (
                    <Price
                      price={cart?.orderSummary || {}}
                      className="text-base font-bold leading-9 text-gray-900 dark:text-light-100"
                    />
                  ) : (
                    <>
                      {isCheckout && loggedIn ? (
                        <Price
                          price={cart?.remainingBalanceToPay || {}}
                          className="text-base font-bold leading-9 text-gray-900 dark:text-light-100"
                        />
                      ) : (
                        <Price
                          price={cart?.total || {}}
                          className="text-base font-bold leading-9 text-gray-900 dark:text-light-100"
                        />
                      )}
                    </>
                  )}
                </p>
              </section>
              {cart?.totalSavings?.centAmount > 0 ? (
                <section className="flex justify-between">
                  <p className="leading-10 text-red-900">
                    {formatCartMessage({ id: 'totalSavings', defaultMessage: 'Your Total Savings :' })}
                  </p>
                  <p className="leading-10 text-red-900">
                    <Price price={cart?.totalSavings || {}} className="leading-10 text-red-900" />
                  </p>
                </section>
              ) : (
                <></>
              )}
              {isCheckout && cart?.custom?.fields?.containsMilStarPayment && (
                <section className="mb-6 mt-3">
                  <h3 className="mb-[6px] font-bold leading-[16.8px] text-gray-900">
                    {formatCartMessage({ id: 'military.star.rewards', defaultMessage: 'Military Star Rewards' })}
                  </h3>
                  <section className="flex justify-between">
                    <p className="leading-[16.8px] text-gray-900 ">
                      {formatCartMessage({
                        id: 'military.points.earned',
                        defaultMessage: 'Points earned on this purchase:',
                      })}
                    </p>
                    <p className="font-bold leading-[16.8px] text-gray-900">
                      {Math.round(cart?.cartTotal?.militaryStarReward || cart?.total?.militaryStarReward)}
                    </p>
                  </section>
                </section>
              )}

              {isCheckout ? (
                <>
                  <section className="pb-4">
                    {loggedIn && (
                      <p className="mb-[11px] text-affes-sm leading-[21px] text-black-900 lg:text-center">
                        <span className="lg:block">
                          {formatCartMessage({
                            id: 'military.placing.order',
                            defaultMessage: 'By placing your order, you agree to',
                          })}{' '}
                        </span>{' '}
                        <span className="text-blue-900">
                          <button onClick={() => setPrivacyAct(true)}>
                            {formatCartMessage({ id: 'privacy.policy', defaultMessage: 'Privacy Policy' })}
                          </button>
                        </span>
                        <span className="ml-1">{formatCartMessage({ id: 'and.text', defaultMessage: 'and' })} </span>
                        <span className="text-blue-900">
                          <button onClick={() => setSiteTerms(true)}>
                            {formatCartMessage({ id: 'terms.conditions', defaultMessage: 'Terms & Conditions' })}
                          </button>
                        </span>
                        .
                      </p>
                    )}

                    <button
                      name=""
                      disabled={
                        isOrderConfirmed ||
                        isCardErrorMsg ||
                        storeClosed ||
                        (loggedIn
                          ? cart?.remainingBalanceToPay?.centAmount > 0 && cvv?.length !== (cardType === 'Amex' ? 4 : 3)
                          : false)
                      }
                      className={` ${
                        !loggedIn && 'mt-[11px]'
                      } mx-auto h-[44px] w-full rounded bg-blue-900 text-affes-xl font-bold text-white md:w-[300px] ${
                        isOrderConfirmed ||
                        isCardErrorMsg ||
                        storeClosed ||
                        (loggedIn
                          ? cart?.remainingBalanceToPay?.centAmount > 0 && cvv?.length !== (cardType === 'Amex' ? 4 : 3)
                          : false)
                          ? 'opacity-50'
                          : ''
                      }`}
                      onClick={() => {
                        placeOrderClicked();
                      }}
                    >
                      {loggedIn
                        ? formatCartMessage({ id: 'place.your.order', defaultMessage: 'Place Your Order' })
                        : formatCartMessage({ id: 'place.order', defaultMessage: 'Place Order' })}
                    </button>
                  </section>
                  <section className="flex justify-center py-4 text-sm text-[#043C63]">
                    <MdLockOutline className="my-auto text-lg" />
                    <button
                      className="ml-2 font-bold"
                      onClick={() => {
                        setSecureCheckoutModal(true);
                      }}
                    >
                      {formatCartMessage({ id: 'securedCheckout', defaultMessage: 'Secure Checkout Guaranteed' })}
                    </button>
                    <SecureCheckout secureCheckoutModal={secureCheckoutModal} closeModal={closeSecureCheckout} />
                  </section>
                </>
              ) : (
                <>
                  {!isOrderConfirmed && (
                    <section className={isFixed ? 'fixed bottom-0 left-0 z-20 w-full bg-white px-4' : ''}>
                      <button
                        disabled={isCheckoutDisabled || isDisable_Checkout_MP_APOFPO}
                        className={`mt-2 w-full rounded py-2 text-center text-[16px] font-bold text-white ${
                          isCheckoutDisabled || isDisable_Checkout_MP_APOFPO ? 'bg-[#9bb1c1]' : 'bg-blue-900'
                        }`}
                        onClick={() => {
                          onSubmit();
                          setDonationModal(true);
                          if (loggedIn) {
                            trackUserAction(GA_INITIATE_CHECKOUT, account);
                          }
                        }}
                      >
                        {formatCartMessage({ id: 'proceedCheckout', defaultMessage: 'Procced To Checkout' })}
                      </button>
                      <section className="flex justify-center py-4 text-sm text-[#043C63]">
                        <MdLockOutline className="my-auto text-lg" />
                        <button
                          className="ml-2 font-bold hover:underline"
                          onClick={() => {
                            setSecureCheckoutModal(true);
                          }}
                        >
                          {formatCartMessage({ id: 'securedCheckout', defaultMessage: 'Secure Checkout Guaranteed' })}
                        </button>
                        <SecureCheckout secureCheckoutModal={secureCheckoutModal} closeModal={closeSecureCheckout} />
                      </section>
                    </section>
                  )}
                </>
              )}
            </section>
          </section>
        </section>
      )}
      {privacyAct && <PrivayAct closeModal={handlePrivacyAct} privacyActModal={privacyAct} />}
      {siteterms && <SiteTermModal closeModal={handleSiteTerms} siteTermsModal={siteterms} />}
      {donationModal && (
        <DonationModal
          closeModal={handleDonationModal}
          donationModal={donationModal}
          itemQty={itemQty}
          handleCheckout={handleCheckout}
        />
      )}

      {showLoader && <Loader></Loader>}
    </section>
  );
}

export default OrderSummary;
