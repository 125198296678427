import React, { useEffect, useState } from 'react';
import AccountDetails, { AccountDetailsProps } from 'components/commercetools-ui/account/details';
import { useAccount } from 'frontastic';
import Redirect from 'helpers/redirect';
import Head from 'next/head';
import { useFormat } from 'helpers/hooks/useFormat';
import Loader from 'components/commercetools-ui/loader/Loader';
import { useRouter } from 'next/router';

export interface Props {
  data: AccountDetailsProps;
}

const AccountDetailsTastic: React.FC<Props> = ({ data }) => {
  const router = useRouter();
  //I18n messages
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  //account data
  const { loggedIn, account, isPasswordChanged, isSessionExpired } = useAccount();
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  }, [account]);

  if (isLoader) return <Loader />;

  //user not logged in
  if (!loggedIn && !isLoader && !isPasswordChanged && !isSessionExpired)
    return <Redirect target={`/?login=true&callBackUrl=${router?.asPath}`} />;

  return (
    <>
      <Head>
        <title>{formatAccountMessage({ id: 'account', defaultMessage: 'Account' })}</title>
      </Head>
      <AccountDetails {...data} />
    </>
  );
};

export default AccountDetailsTastic;
