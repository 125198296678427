import Link from 'next/link';
import { AAFES_OWNED_SITE, NON_AAFES_OWNED_SITE } from 'helpers/constants/aafes';
import { isAAFESownedSite } from 'helpers/utils/commonGAUtils';

const RightColumnItem = ({ Item, loggedIn, showLogin, setRedirectUrl, weeklyAd = {}, rightId }) => {
  const isAAFESOwned = isAAFESownedSite(Item?.links);
  const isgtmAffesOwned = isAAFESOwned ? AAFES_OWNED_SITE : NON_AAFES_OWNED_SITE;
  return (
    <>
      {Item?.loginRequired ? (
        loggedIn ? (
          <section key={rightId} className="mr-5 mb-3 flex items-center hover:underline xl:mb-0">
            <Link href={Item?.links}>
              <a
                className="flex cursor-pointer items-center text-sm font-semibold text-blue-900"
                data-gtm-exit
                data-gtm-id={isgtmAffesOwned}
              >
                <img src={Item?.icon} alt={`${Item.header} Icon`} className="mr-3" />
                {Item.header}
              </a>
            </Link>
          </section>
        ) : (
          <section
            key={rightId}
            onClick={() => {
              showLogin();
              setRedirectUrl(Item?.links);
            }}
            className="mr-5 mb-3 flex items-center hover:underline xl:mb-0"
          >
            <p
              className="flex cursor-pointer items-center text-sm font-semibold text-blue-900"
              data-gtm-exit
              data-gtm-id={isAAFESownedSite(Item?.links) ? AAFES_OWNED_SITE : NON_AAFES_OWNED_SITE}
            >
              <img src={Item?.icon} alt={`${Item.header} Icon`} className="mr-3" /> {Item.header}
            </p>
          </section>
        )
      ) : (
        <section key={rightId} className="my-3 mr-5 flex items-center hover:underline md:mt-0 xl:mb-0">
          <Link href={Item?.links}>
            <a
              className="flex cursor-pointer items-center text-sm font-semibold text-blue-900"
              data-gtm-exit
              data-gtm-id={isgtmAffesOwned}
            >
              <img src={Item?.icon} alt={`${Item.header} Icon`} className="mr-3" />
              {Item.header}
            </a>
          </Link>
        </section>
      )}
    </>
  );
};

export default RightColumnItem;
