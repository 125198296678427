import { useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Product } from '@Types/product/Product';
import { Variant } from '@Types/product/Variant';
import ProductDetails, { UIProduct } from 'components/commercetools-ui/aafes/pdp';
import { useAccount, useWishlist } from 'frontastic';
import { MARKETPLACE_PRODUCT } from 'helpers/constants/aafes';

function ProductDetailsTastic({ data }) {
  const router = useRouter();
  const { previewDateInSessionData, account } = useAccount();
  const [isSettingFromPDP, setIsSettingFromPDP] = useState(false);
  const {
    product,
    isGuestPdp,
    errorType,
  }: { product: Product; isGuestPdp?: boolean; error?: string; errorType?: string } = data?.data?.dataSource || {};

  const pageMounted = useRef(false);

  const currentVariantIdx = useMemo<number>(() => {
    const currentVariantSKU = router?.asPath?.split('/')?.[isGuestPdp ? 3 : 2];
    const tempCurrentVariantIndex = product?.variants?.findIndex(({ sku }) => sku === currentVariantSKU);
    return tempCurrentVariantIndex >= 0 ? tempCurrentVariantIndex : 0;
  }, [product, router?.asPath]);

  const variants = product?.variants || [];

  function getDefaultVariant(variants: any[], urlSku: string) {
    if (!variants || variants?.length === 0) {
      return null;
    }
    const findVariant = (condition: (variant: any) => boolean) => variants.find(condition);
    // 1. Check if the SKU in the URL is STA and has STA inventory
    let defaultVariant = findVariant((variant) => variant?.sku === urlSku && variant?.isOnStock);
    if (!defaultVariant) {
      // 2. Find the first STA variant with inventory and select it
      defaultVariant = findVariant((variant) => variant?.isOnStock);
    }
    if (!defaultVariant) {
      // 3. Check if the SKU in the URL is PUIS and has PUIS inventory
      defaultVariant = findVariant((variant) => variant?.sku === urlSku && variant?.isOnStockPickupInStore);
    }
    if (!defaultVariant) {
      // 4. Find the first PUIS variant with inventory and select it
      defaultVariant = findVariant((variant) => variant?.isOnStockPickupInStore);
    }
    if (!defaultVariant) {
      // 5. Fall back to the SKU in the URL
      defaultVariant = findVariant((variant) => variant?.sku === urlSku);
    }
    return defaultVariant || product?.variants[0];
  }

  const defaultVariant = useMemo(() => {
    return getDefaultVariant(variants, router?.asPath?.split('/')?.[isGuestPdp ? 3 : 2]);
  }, [product, router?.asPath, account?.defaultStore?.key]);

  const variant = useMemo<Variant>(() => {
    if (pageMounted?.current) {
      return product?.variants?.[currentVariantIdx];
    }
    return defaultVariant;
  }, [product, currentVariantIdx, defaultVariant]);

  const getMarketingBug = () => {
    const variantLevelMarketingBug =
      product?.gbProduct?.variants?.length > 0
        ? product?.gbProduct?.variants?.find((gbVariant) => gbVariant?.skuId === variant?.sku)?.marketingBug
        : null;
    return variantLevelMarketingBug;
  };

  const { data: wishlist } = useWishlist();

  const prod = useMemo<UIProduct>(
    () => ({
      productId: product?.productId,
      name: product?.name,
      _url: product?._url,
      isPreOrderable: product?.isPreOrderable,
      isPreOrderableComingSoon: product?.isPreOrderableComingSoon,
      isProductShowOnSite: product?.isProductShowOnSite,
      // add variants as well, so we can select and filter
      variants: product?.variants,
      listPrice: variant?.listPrice,
      costPrice: variant?.costPrice,
      msrpPrice: variant?.msrpPrice,
      salePrice: variant?.salePrice,
      storePrice: variant?.storePrice,
      storePrices: variant?.storePrices,
      isMarketplaceProduct: variant?.attributes?.customProductType === MARKETPLACE_PRODUCT,
      marketplaceRequestUrl: variant?.attributes?.requestUrl,
      price: variant?.price,
      imageSets: variant?.imageSet,
      // rating: 4,
      images: variant?.images?.map((img: string, id: number) => ({
        id: `${variant?.sku}-${id}`,
        src: img,
        alt: variant?.sku,
      })),
      isOnWishlist: !!wishlist?.lineItems?.find((lineItem) =>
        product?.variants?.find((variant) => variant?.sku === lineItem?.variant?.sku),
      ),
      description: `${product?.description || ''}`,
      categories: product?.categories,
      pickers: product?.pickers,
      swatches: product?.swatches,
      marketingBug: getMarketingBug(),
    }),
    [product, variant],
  );

  const handleVariantIdxChange = (idx: number) => {
    const variant = product?.variants[idx];
    const url = `${router.asPath
      .split('/')
      .slice(0, isGuestPdp ? 3 : 2)
      .join('/')}/${variant.sku}`;
    router.replace(url, undefined, { shallow: true });
  };

  useEffect(() => {
    if (!pageMounted?.current) {
      const currentVariantId = product?.variants?.findIndex((variant) => {
        return variant?.sku == defaultVariant?.sku;
      });
      pageMounted.current = true;
      handleVariantIdxChange(currentVariantId);
    }
  }, [pageMounted?.current]);

  useEffect(() => {
    if (product?.productKey) {
      const currentVariantId = product?.variants?.findIndex((variant) => {
        return variant?.sku == defaultVariant?.sku;
      });
      handleVariantIdxChange(currentVariantId);
    }
  }, [product?.productKey]);

  //For SSR render when going back
  useEffect(() => {
    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        router.replace(as, undefined);
        return false;
      }

      return true;
    });

    return () => router.beforePopState(() => true);
  }, []);

  if (!product || !variant || !prod) return <></>;

  useEffect(() => {
    const storedPreviewDate = window.localStorage.getItem('previewDateInSessionData');
    if (
      storedPreviewDate !== undefined &&
      storedPreviewDate !== null &&
      storedPreviewDate !== 'undefined' &&
      previewDateInSessionData !== undefined
    ) {
      if (storedPreviewDate === JSON.stringify(previewDateInSessionData)) {
        return;
      } else {
        if (isSettingFromPDP) {
          router.reload();
        }
      }
    }
    window.localStorage.setItem('previewDateInSessionData', JSON.stringify(previewDateInSessionData));
    setIsSettingFromPDP(true);
  }, [previewDateInSessionData]);
  return (
    <>
      <ProductDetails
        product={prod}
        variant={variant}
        onChangeVariantIdx={handleVariantIdxChange}
        isGuestPdp={isGuestPdp}
        recommendationComponent={data?.recommendationComponent}
      />
    </>
  );
}

export default ProductDetailsTastic;
