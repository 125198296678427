import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { BiSearch } from 'react-icons/bi';
import { FiCheck } from 'react-icons/fi';
import { MdArrowForwardIos, MdOutlineClose } from 'react-icons/md';
import Loader from 'components/commercetools-ui/loader/Loader';
import { CATEGORY, GA_EXCHANGE_PAGE } from 'helpers/constants/aafes';
import useGATrackUserAction from 'helpers/hooks/gaEvents/useGATrackUserAction';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { generateFilterPath } from 'helpers/utils/commonGAUtils';
import { isCategory } from 'helpers/utils/findCategory';
import { desktop, tablet } from 'helpers/utils/screensizes';
import { useCart } from 'frontastic';
import Clearance from './clearance';
import FilterCMSContents from './filterCMSContents';
import FilterSpecificUI from './filterSpecificUI';

type Props = {
  filterProperties: any;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  contents?: any;
};

export default function Filter({ filterProperties, setCurrentPage, contents }: Props) {
  const { formatMessage } = useFormat({ name: 'common' });
  const [filterSearch, setFilterSearch] = useState({});
  const [isDesktop] = useMediaQuery(tablet);
  const [isMediumDevice] = useMediaQuery(desktop);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const router = useRouter();
  const { trackApplyFilter } = useGATrackUserAction();
  const [isAppendInURL, setIsAppendInURL] = useState(isDesktop ? true : false);
  const [tempRefinements, setTempRefinements] = useState(filterProperties?.selectedRefinements);

  const { studioConfig } = useCart();
  const FILTER_SEARCHBAR_ATTRIBUTES = studioConfig?.filterSearchbarAttributes;
  const FILTER_ATTRIBUTE_CLEARANCE = studioConfig?.filterAttributeClearance;
  const FILTER_SPECIFIC_UI_ATTRIBUTES = studioConfig?.filterSpecificUIAttributes;
  const FILTER_ATTRIBUTE_RATING = studioConfig?.filterAttributeRating;
  const toggleDivVisibility = (name) => {
    const index = filterProperties?.isShowRefinements?.findIndex((entry) => entry.name === name);

    if (index !== -1) {
      // If the name exists, remove the entry
      const updatedVisibility = filterProperties?.isShowRefinements?.filter((entry) => entry.name !== name);
      filterProperties?.setIsShowRefinements(updatedVisibility);
    } else {
      // If the name doesn't exist, add a new entry with initial visibility as true
      filterProperties?.setIsShowRefinements([...filterProperties?.isShowRefinements, { name, isVisible: true }]);
    }
  };

  const handleAppendURL = async (updatedRefinements) => {
    setIsLoad(true);
    const CURRENT_URL = window?.location?.href;
    const url = new URL(CURRENT_URL);
    let facets = url?.searchParams?.get?.('facets');
    facets = updatedRefinements;
    if (Object.keys?.(updatedRefinements)?.length === 0) url?.searchParams?.delete?.('facets');
    else {
      const encodedFacets = encodeURIComponent(JSON?.stringify?.(facets));
      url?.searchParams?.set('facets', encodedFacets);
    }
    url?.searchParams?.delete('pageNo');
    setCurrentPage(1);
    router
      ?.push(url?.toString())
      .then(() => {
        setIsLoad(false);
      })
      .catch(() => setIsLoad(false));
  };

  const handleTempRefinement = async (refinement, name) => {
    const refinementName = refinement?.name
      ? refinement?.name
      : name === FILTER_ATTRIBUTE_RATING
      ? refinement?.low?.toFixed(1)
      : `${refinement?.low?.toFixed(2)}_${refinement?.high?.toFixed(2)}`;
    const selectedRefinementArray = tempRefinements?.[name] || [];

    let updatedTempRefinements;

    if (selectedRefinementArray?.includes(refinementName)) {
      updatedTempRefinements = {
        ...tempRefinements,
        [name]: selectedRefinementArray?.filter((selectedName) => selectedName !== refinementName),
      };

      if (updatedTempRefinements?.[name].length === 0) {
        delete updatedTempRefinements?.[name];
      }
    } else {
      if (isCategory(name)) {
        updatedTempRefinements = {
          ...tempRefinements,
          [name]: [refinementName],
        };
      } else {
        updatedTempRefinements = {
          ...tempRefinements,
          [name]: [...selectedRefinementArray, refinementName],
        };
      }
    }
    setTempRefinements(updatedTempRefinements);
  };

  const handleRefinementClick = async (refinement, name, index) => {
    const refinementName = refinement?.name
      ? refinement?.name
      : name === FILTER_ATTRIBUTE_RATING
      ? refinement?.low?.toFixed(1)
      : `${refinement?.low?.toFixed(2)}_${refinement?.high?.toFixed(2)}`;
    const selectedRefinementArray = filterProperties?.selectedRefinements?.[name] || [];

    let updatedRefinements;

    if (selectedRefinementArray?.includes(refinementName)) {
      updatedRefinements = {
        ...filterProperties?.selectedRefinements,
        [name]: selectedRefinementArray?.filter((selectedName) => selectedName !== refinementName),
      };

      if (updatedRefinements?.[name]?.length === 0) {
        delete updatedRefinements?.[name];
      }
    } else {
      updatedRefinements = {
        ...filterProperties?.selectedRefinements,
        [name]: [...selectedRefinementArray, refinementName],
      };
    }
    filterProperties?.setSelectedRefinements(updatedRefinements);
    handleAppendURL(updatedRefinements);
    trackApplyFilter({
      contentId: GA_EXCHANGE_PAGE,
      filterTerm: refinementName,
      filterPath: generateFilterPath(filterProperties?.data, name, refinementName),
    });
  };

  const handleDisplayFilter = () => {
    filterProperties?.setIsMobileFilter((prev) => !prev);
    filterProperties?.setIsFilterclicked((prev) => !prev);
  };

  const handleClickReset = () => {
    if (filterProperties?.selectedRefinements?.length !== 0) {
      handleAppendURL({});
      filterProperties?.setSelectedRefinements([]);
      setTempRefinements([]);
      handleDisplayFilter();
    } else if (tempRefinements?.length !== 0) {
      setTempRefinements([]);
    }
  };

  useEffect(() => {
    if (isAppendInURL && Object.keys(tempRefinements)?.length !== 0) {
      filterProperties?.setSelectedRefinements(tempRefinements);
      handleAppendURL(tempRefinements);
      handleDisplayFilter();
    }
    setIsAppendInURL(false);
  }, [isAppendInURL]);

  useEffect(() => {
    if (!isDesktop) setTempRefinements(filterProperties?.selectedRefinements);
  }, [filterProperties?.selectedRefinements]);

  useEffect(() => {
    if (!isDesktop) {
      filterProperties?.setIsMobileFilter(true);
      filterProperties?.setIsFilterclicked(false);
    } else {
      filterProperties?.setIsMobileFilter(false);
      filterProperties?.setIsFilterclicked(false);
    }
  }, [isDesktop]);

  useEffect(() => {
    setFilterSearch({});
    filterProperties?.setIsShowRefinements([]);
  }, [router?.asPath]);

  return (
    <>
      <section className="h-full w-full">
        {contents?.leftNavContentTop && isMediumDevice && <FilterCMSContents content={contents?.leftNavContentTop} />}
        {isDesktop ? (
          <div>
            <h2 className="text-base font-bold leading-5 text-gray-900" aria-label="Filters">
              {formatMessage({ id: 'filters', defaultMessage: 'Filters' })}
            </h2>
            <hr className="mt-4 border border-[#00000033] opacity-40" />
          </div>
        ) : (
          <div
            onClick={() => {
              handleDisplayFilter();
            }}
            className={`flex cursor-pointer justify-between ${
              filterProperties?.isFilterclicked ? 'border-b' : 'h-full rounded border'
            }   border-[#00000033] p-2.5 text-sm font-bold leading-5 text-gray-900`}
            role="button"
            aria-expanded={filterProperties?.isMobileFilter}
            aria-haspopup="true"
          >
            {filterProperties?.isMobileFilter ? (
              <p className="flex items-center justify-center">
                {formatMessage({ id: 'filters', defaultMessage: 'Filters' })}
              </p>
            ) : (
              <p>{formatMessage({ id: 'allFilters', defaultMessage: 'All Filters' })}</p>
            )}
            <div className="flex items-center justify-center">
              {filterProperties?.isMobileFilter ? (
                <MdArrowForwardIos className="h-4 w-4" />
              ) : (
                <MdOutlineClose className="h-5 w-5" />
              )}
            </div>
          </div>
        )}
        {/* mapping available of Filters categories, brands, etc..*/}
        <div
          className={`h-[calc(h-full-100px)] ${
            !isDesktop && `px-3 ${!filterProperties?.isMobileFilter ? 'block' : 'hidden'}`
          } flex flex-col place-content-between transition-all duration-300`}
        >
          <div className={`mt-2 mb-2 flex flex-col gap-y-2 transition-all duration-300 `}>
            {filterProperties?.data?.map((res, index) => {
              if (res?.isHidden) return null;
              if (res?.name === FILTER_ATTRIBUTE_CLEARANCE)
                return (
                  <Clearance
                    key={index}
                    res={res}
                    handleRefinementClick={handleRefinementClick}
                    selectedRefinements={filterProperties?.selectedRefinements}
                    handleDisplayFilter={handleDisplayFilter}
                  />
                );
              else
                return (
                  <>
                    <div key={index}>
                      <button
                        onClick={() => {
                          toggleDivVisibility(res?.name);
                        }}
                        className={`w-full py-2 text-gray-900 transition-all duration-300 ${
                          filterProperties?.isShowRefinements?.some(
                            (entry) => entry?.name === res?.name && entry?.isVisible,
                          ) && 'mb-2'
                        }`}
                        aria-expanded={filterProperties?.isShowRefinements?.some(
                          (entry) => entry?.name === res?.name && entry?.isVisible,
                        )}
                        type="button"
                      >
                        <div className="flex justify-between">
                          <p className="text-sm font-bold leading-4 ">
                            {isCategory(res?.name) ? CATEGORY : res?.displayName}
                          </p>
                          <MdArrowForwardIos
                            className={`h-4 w-4 duration-300 ${
                              filterProperties?.isShowRefinements?.some(
                                (entry) => entry?.name === res?.name && entry?.isVisible,
                              )
                                ? 'rotate-90'
                                : ''
                            }`}
                          />
                        </div>
                      </button>
                      {isCategory(res?.name) || FILTER_SPECIFIC_UI_ATTRIBUTES?.includes(res?.name) ? (
                        <FilterSpecificUI
                          res={res}
                          handleRefinementClick={handleRefinementClick}
                          isShowRefinements={filterProperties?.isShowRefinements}
                          index={index}
                          selectedRefinements={filterProperties?.selectedRefinements}
                          handleTempRefinement={handleTempRefinement}
                          tempRefinements={tempRefinements}
                          handleDisplayFilter={handleDisplayFilter}
                        />
                      ) : (
                        <>
                          {FILTER_SEARCHBAR_ATTRIBUTES?.includes(res?.name) && (
                            <div
                              className={`relative ${
                                filterProperties?.isShowRefinements?.some(
                                  (entry) => entry?.name === res?.name && entry?.isVisible,
                                )
                                  ? 'mt-2 h-auto'
                                  : 'mt-0 hidden h-0'
                              } flex justify-between`}
                              role="search"
                            >
                              <label htmlFor={`filterSearchInput_${res?.displayName}`} className="sr-only">
                                Search Filters
                              </label>
                              <input
                                id={`filterSearchInput_${res?.displayName}`}
                                type="text"
                                value={filterSearch?.[res?.displayName] || ''}
                                onChange={(e) => {
                                  setFilterSearch((prevFilterSearch) => ({
                                    ...prevFilterSearch,
                                    [res?.displayName]: e?.target?.value,
                                  }));
                                }}
                                className="mb-3 h-11 w-full rounded border pr-8 text-sm text-gray-800 ring-0 focus:ring-0"
                                placeholder={res?.displayName}
                              />
                              <BiSearch className="absolute right-3 top-3 flex h-5 w-5 cursor-pointer" />
                            </div>
                          )}
                          <div
                            className={`${
                              !isDesktop ? 'px-4' : 'left-nav-scrollbar'
                            } overflow-y-auto transition-all duration-300 ${
                              filterProperties?.isShowRefinements?.some(
                                (entry) => entry?.name === res?.name && entry?.isVisible,
                              )
                                ? `max-h-[224px] opacity-100`
                                : 'mt-0 max-h-0 opacity-0'
                            }`}
                          >
                            <div className={`mr-1 flex flex-col gap-y-3`}>
                              {res?.refinements
                                ?.filter((refinement) => {
                                  if (
                                    filterSearch?.[res?.displayName]?.length > 0 &&
                                    FILTER_SEARCHBAR_ATTRIBUTES?.includes(res?.name)
                                  ) {
                                    return refinement?.name
                                      ?.toLowerCase()
                                      ?.includes(filterSearch?.[res?.displayName]?.toLowerCase());
                                  } else {
                                    return true; // Show all refinements when no search term
                                  }
                                })
                                .map((refinement, refinementIndex) => {
                                  return refinement?.name ? (
                                    <a
                                      aria-label="Filter on new tab"
                                      href="#"
                                      key={refinementIndex}
                                      onClick={() => {
                                        isDesktop
                                          ? handleRefinementClick(refinement, res?.name, index)
                                          : handleTempRefinement(refinement, res?.name);
                                      }}
                                      className="grid w-fit cursor-pointer grid-cols-[minmax(18px,18px)_1fr] gap-x-2"
                                      role="button"
                                      aria-pressed={
                                        isDesktop
                                          ? filterProperties?.selectedRefinements?.[res?.name]?.includes(
                                              refinement?.name,
                                            )
                                          : tempRefinements?.[res?.name]?.includes(refinement?.name)
                                      }
                                    >
                                      <div className="h-[18px] w-full rounded border border-[#0000004D]">
                                        {isDesktop ? (
                                          filterProperties?.selectedRefinements?.[res?.name]?.includes(
                                            refinement?.name,
                                          ) ? (
                                            <FiCheck className="h-full w-full text-blue-900" />
                                          ) : null
                                        ) : tempRefinements?.[res?.name]?.includes(refinement?.name) ? (
                                          <FiCheck className="h-full w-full text-blue-900" />
                                        ) : null}
                                      </div>
                                      <div className="break-words text-sm leading-4 text-gray-900">
                                        {refinement?.name} <span>({refinement?.count})</span>
                                      </div>
                                    </a>
                                  ) : (
                                    <a
                                      aria-label="Filter on new tab"
                                      href="#"
                                      key={refinementIndex}
                                      onClick={() => {
                                        isDesktop
                                          ? handleRefinementClick(refinement, res?.name, index)
                                          : handleTempRefinement(refinement, res?.name);
                                      }}
                                      className="grid w-fit cursor-pointer grid-cols-[minmax(18px,18px)_1fr] gap-x-2"
                                      role="button"
                                      aria-pressed={
                                        isDesktop
                                          ? filterProperties?.selectedRefinements?.[res?.name]?.includes(
                                              `${refinement?.low?.toFixed(2)}_${refinement?.high?.toFixed(2)}`,
                                            )
                                          : tempRefinements?.[res?.name]?.includes(
                                              `${refinement?.low?.toFixed(2)}_${refinement?.high?.toFixed(2)}`,
                                            )
                                      }
                                    >
                                      <div className="h-[18px] w-full rounded border border-[#0000004D]">
                                        {isDesktop ? (
                                          filterProperties?.selectedRefinements?.[res?.name]?.includes(
                                            `${refinement?.low?.toFixed(2)}_${refinement?.high?.toFixed(2)}`,
                                          ) ? (
                                            <FiCheck className="h-full w-full text-blue-900" />
                                          ) : null
                                        ) : tempRefinements?.[res?.name]?.includes(
                                            `${refinement?.low?.toFixed(2)}_${refinement?.high?.toFixed(2)}`,
                                          ) ? (
                                          <FiCheck className="h-full w-full text-blue-900" />
                                        ) : null}
                                      </div>
                                      <span className="text-sm leading-4 text-gray-900">
                                        ${refinement?.low?.toFixed(2)} - ${refinement?.high?.toFixed(2)}{' '}
                                        <span>({refinement?.count})</span>
                                      </span>
                                    </a>
                                  );
                                })}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <hr className="border border-[#00000033] opacity-40" />
                  </>
                );
            })}
          </div>
          <div className="flex justify-center gap-x-2 py-5 md:hidden">
            <button
              type="button"
              onClick={() => {
                setIsAppendInURL(true);
              }}
              className="w-3/5 rounded bg-blue-900 py-3 text-center text-base leading-5 text-white"
            >
              {formatMessage({ id: 'viewResults', defaultMessage: 'View Results' })}
            </button>
            <button
              type="button"
              onClick={() => {
                handleClickReset();
              }}
              className="w-2/5 rounded py-3 text-center text-base leading-5 text-blue-900"
            >
              {formatMessage({ id: 'reset', defaultMessage: 'Reset' })}
            </button>
          </div>
        </div>
        {contents?.leftNavContentBottom && isMediumDevice && (
          <FilterCMSContents content={contents?.leftNavContentBottom} />
        )}
      </section>
      {isLoad && <Loader />}
    </>
  );
}
