import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Link from 'next/link';
import router, { Router } from 'next/router';
import { LineItem } from '@Types/cart/LineItem';
import { FaFacebookF, FaTwitter, FaPinterestP, FaLink } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { mutate } from 'swr';
import AdditionalServices from 'components/commercetools-ui/aafes/cart/additionalServices';
import ItemPrice from 'components/commercetools-ui/aafes/cart/itemPrice';
import MaxLimit from 'components/commercetools-ui/aafes/Modals/maxLimitModal';
import ShareEmailModal from 'components/commercetools-ui/aafes/Modals/shareEmail';
import Loader from 'components/commercetools-ui/loader/Loader';
import MiniLoader from 'components/commercetools-ui/loader/miniLoader';
import LinkIcon from 'components/icons/link';
import {
  GA_WISHLIST,
  NETWORKS,
  COMING_SOON,
  FRONT_SMALL,
  GTM_PRODUCT,
  GTM_SHARE,
  GTM_WISHLIST,
} from 'helpers/constants/aafes';
import useGATrackAddActions from 'helpers/hooks/gaEvents/useGATrackAddActions';
import useGATrackUserAction from 'helpers/hooks/gaEvents/useGATrackUserAction';
import useGATrackViewActions from 'helpers/hooks/gaEvents/useGATrackViewActions';
import { useFormat } from 'helpers/hooks/useFormat';
import { trackEvent } from 'helpers/utils/gbTracker';
import { sendToMonetate } from 'helpers/utils/monetateHelper';
import {
  arePreOrderDatesInFuture,
  arePreOrderDatesInPast,
  isPreOrderEndFutureAndStreetPast,
  isPreOrderable,
  shouldShowAddToCartButton,
} from 'helpers/utils/preOrderable';
import { priceInUSD } from 'helpers/utils/priceConverter';
import RenderVariantPickers from 'helpers/utils/renderVariantPickers';
import { useAccount, useCart, useWishlist } from 'frontastic';
import { getPriceforWishlist } from 'frontastic/actions/wishlist';
import Image from 'frontastic/lib/image';
import { InlineShareButtons } from '../../../aafes/sharethis';

const Wishlist = ({ closeModal }) => {
  const { data: wishlistData, removeLineItem, updateLineItem, isValidating } = useWishlist();
  const { addItem, setCartProductDetailEventSent, studioConfig, data } = useCart();
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const [itemsWithRemove, setItemsWithRemove] = useState({});
  const [activeItem, setActiveItem] = useState<LineItem | null>(null);
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const editItemQuantity = (lineItemId: string, newQuantity: number) => updateLineItem(lineItemId, newQuantity);
  const [shareByEmail, setShareByEmail] = useState(false);
  const [maxLimitModal, setMaxLimitModal] = useState(false);
  const [maxLimitErrorMessage, setMaxLimitErrorMessage] = useState(null);
  const { formatMessage } = useFormat({ name: 'cart' });
  const { trackGAAddToCart } = useGATrackAddActions();
  const { viewItemListTracker, trackSelectItem } = useGATrackViewActions();
  const { account, previewDateInSessionData } = useAccount();
  const shareThisBaseURL = window.location.origin;
  const [tooltipStates, setTooltipStates] = useState({});
  const [tooltipTexts, setTooltipTexts] = useState(Array(wishlistData?.lineItems?.length).fill('Copy to Clipboard'));
  const [wishListPrices, setWishListPrices] = useState({});
  const [addonItemId, setAddonItemId] = useState();
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const removeWishlistTooltip = useRef(null);

  const isDataReady = useMemo(
    () => wishlistData?.lineItems?.length > 0 && Object.keys(wishListPrices).length > 0,
    [wishlistData, wishListPrices],
  );

  const handleTrackListViewItem = useCallback(() => {
    if (isDataReady) {
      viewItemListTracker({
        product: wishlistData.lineItems,
        type: GTM_WISHLIST,
        price: wishListPrices,
      });
    }
  }, [isDataReady, wishlistData, wishListPrices]);

  useEffect(() => {
    handleTrackListViewItem();
  }, [handleTrackListViewItem]);

  const copyUrlToClipboard = (link, index) => {
    const url = link;
    navigator?.clipboard?.writeText(url);
    const newTexts = [...tooltipTexts];
    newTexts[index] = 'Copied';
    setTooltipTexts(newTexts);
  };
  const cid = account?.cid;
  const handleClick = (wish) => {
    const itemId = wish.lineItemId || wish.productId; // Use a unique identifier for the item

    setItemsWithRemove((prev) => ({
      [itemId]: !prev[itemId], // Toggle the state for the clicked item
    }));
  };
  const openEmailModal = () => {
    setShareByEmail(true);
  };

  const closeEmailModal = () => {
    setShareByEmail(false);
  };
  const addtoCartEvent = async (variant, qty, lineItemId, personalization, additionalSku, wish) => {
    setIsLoad(true);
    const channelId = !variant?.isShipToAddress && variant?.isPickupInStore ? account?.defaultStore?.key : undefined;
    addItem(variant, qty, channelId, personalization, null, additionalSku).then((response: any) => {
      if (response.errorCode === 500) {
        setMaxLimitModal(true);
        setMaxLimitErrorMessage(response.body);
        setIsLoad(false);
      } else {
        removeLineItem(lineItemId)
          .then(() => setIsLoad(false))
          .finally(() => {
            setIsLoad(false);
            trackGAAddToCart({
              product: wish,
              type: GTM_WISHLIST,
              price: wishListPrices && priceInUSD(wishListPrices[wish?.lineItemId]?.extPrice),
            });
          });
      }
    });
    const matchingProduct = wishlistData?.lineItems?.find((item) => item?.lineItemId === lineItemId);
    trackEvent(
      'addToCart',
      {
        cart: {
          id: data?.cartId,
          items: [
            {
              title: matchingProduct?.name,
              price: priceInUSD(wishListPrices[lineItemId]?.extPrice),
              quantity: qty,
              collection: variant?.[0]?.attributes?.customProductType,
              category: variant?.categories?.[0]?.name,
              productId: matchingProduct?.productKey ?? variant?.sku,
              sku: `sku-${variant?.sku}`,
            },
          ],
        },
      },
      cid,
    );
  };
  useEffect(() => {
    mutate('/action/wishlist/getWishlist');
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (!removeWishlistTooltip?.current?.contains(event?.target)) {
        setItemsWithRemove({});
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getPrice = async () => {
    const prices = await getPriceforWishlist();
    setWishListPrices(prices);
  };
  useEffect(() => {
    getPrice();
  }, [previewDateInSessionData]);
  const isDisableButton = (wish) => {
    if (wishListPrices?.hasOwnProperty(wish?.lineItemId) && wishListPrices[wish?.lineItemId]?.extPrice !== undefined) {
      return false;
    }
    if (wish?.variant?.attributes?.isPreOrderable) {
      return isPreOrderable(wish);
    }

    return true;
  };
  const filteredWishData = wishlistData?.lineItems?.filter((item) => !item?.isAttachment);
  const wishListLength = wishlistData?.lineItems?.filter((item) => !item?.isAttachment).length;
  const getAdditionalSkus = (wish) => {
    let additionalSKUs;
    if (wish?.additionalLineItemIds?.length > 0) {
      additionalSKUs = wish?.attachments?.map((item) => item?.obj?.masterData?.current?.masterVariant?.sku);
      return additionalSKUs;
    } else {
      return null;
    }
  };
  const [monetateConfig, setMonetateConfig] = useState(null);
  const [monetateSent, setMonetateSent] = useState(false);
  useEffect(() => {
    if (studioConfig?.monetateConstants) {
      try {
        const monetateConfig = JSON?.parse(studioConfig?.monetateConstants?.replace(/'/g, '"'));
        setMonetateConfig(monetateConfig);
      } catch (error) {
        console.error('Errorparsing projectConfig:', error);
      }
    }
  }, [studioConfig]);

  useEffect(() => {
    const processMonetate = async () => {
      const callMonetate = async () => {
        setMonetateSent(true);
        await sendToMonetate(
          {
            page: 'wish_list',
            cart: {
              ...data,
            },
          },
          monetateConfig,
        );
      };
      if (account && monetateConfig && !monetateSent) {
        await callMonetate();
        setCartProductDetailEventSent(true);
      }
    };
    const fetchData = async () => {
      await processMonetate();
    };
    fetchData();
  }, [wishlistData, monetateConfig]);

  return (
    <section>
      {maxLimitModal && <MaxLimit closeModal={setMaxLimitModal} customMessage={maxLimitErrorMessage} />}
      <div aria-labelledby="wishlist-section" className="flex justify-between">
        <h3 id="wishlist-section" className="text-[21px] font-bold leading-6 text-gray-900">
          {formatCartMessage({ id: 'wishList', defaultMessage: 'Wish List' })} ({wishListLength})
        </h3>
        <button
          disabled={wishlistData?.lineItems?.length <= 0}
          onClick={openEmailModal}
          className="inline-block text-sm text-[#043C63]"
        >
          <HiOutlineMail className="mr-2 inline-block text-[#666666]" />
          {formatCartMessage({ id: 'shareByEmail', defaultMessage: 'Share By Email' })}
        </button>
      </div>
      <ShareEmailModal shareByEmail={shareByEmail} closeModal={closeEmailModal} />
      <div className="mt-2 grid grid-cols-1 gap-4">
        {filteredWishData?.map((wish, i) => (
          <div className="col-span rounded border border-slate-300 p-4" key={i}>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-4 md:col-span-2">
                <Link href={wish?.slug}>
                  <Image
                    src={wish?.variant?.imageSet?.[FRONT_SMALL]?.src?.[0]?.url || wish?.variant?.images[0]}
                    alt={wish?.name}
                    onClick={() =>
                      trackSelectItem({
                        product: wish,
                        type: GTM_WISHLIST,
                        price: priceInUSD(wishListPrices[wish?.lineItemId]?.extPrice),
                      })
                    }
                    className="h-[75px] w-[75px] flex-none cursor-pointer rounded-md bg-gray-200 object-fill md:h-[105px] md:w-[105px]"
                  />
                </Link>
              </div>
              <div className="col-span-8 text-sm  md:col-span-3">
                <Link href={wish?.slug}>
                  <a
                    className="cursor-pointer font-bold text-[#043C63]"
                    onClick={() =>
                      trackSelectItem({
                        product: wish,
                        type: GTM_WISHLIST,
                        price: priceInUSD(wishListPrices[wish?.lineItemId]?.extPrice),
                      })
                    }
                  >
                    {wish?.name}
                  </a>
                </Link>
                <p className="mb-2 text-sm font-medium">
                  {formatAccountMessage({ id: 'item', defaultMessage: 'Item #:' })} {wish?.variant?.sku}
                </p>
                {wish?.personalizationTextLines?.length > 0 && (
                  <p className="mb-2 inline-flex text-sm font-medium">
                    <span className="mr-2">
                      {formatMessage({ id: 'personalization', defaultMessage: 'Personalization' })}:
                    </span>
                    <ul className="break-all">
                      {wish?.personalizationTextLines.map((personalizedLine, idx) => (
                        <li key={idx}>{personalizedLine}</li>
                      ))}
                    </ul>
                  </p>
                )}
                {wish?.variant?.attributes?.variantPickers && (
                  <dl className="leading-6 text-[#666666]">
                    <RenderVariantPickers item={wish} />
                  </dl>
                )}
              </div>
              <div className="col-span-4 md:hidden"></div>
              <div className="order-4 col-span-8 text-sm text-gray-900 md:order-none md:col-span-3">
                <div className="font-bold text-gray-900">
                  {wishListPrices?.hasOwnProperty(wish?.lineItemId) ? (
                    <ItemPrice item={wishListPrices[wish?.lineItemId]} view="wishlist"></ItemPrice>
                  ) : (
                    <MiniLoader value={'Price'} />
                  )}
                </div>
              </div>
              <div className="order-6 col-span-4 md:order-none md:col-span-2">
                <div className="relative mt-2 text-sm md:mt-4">
                  <button
                    className="text-[#043C63]"
                    onClick={() => {
                      handleClick(wish);
                    }}
                  >
                    {' '}
                    {formatAccountMessage({ id: 'removeItem', defaultMessage: 'Remove Item' })}
                  </button>
                  {itemsWithRemove[wish.lineItemId || wish.productId] && (
                    <section
                      ref={removeWishlistTooltip}
                      className="absolute right-0 top-[-100px] w-40 rounded-sm border border-gray-600 bg-white p-3 text-center sm:left-[-40px]"
                    >
                      <section className="boxArrow absolute left-14 -bottom-4 h-0 w-0 rotate-180 border-x-[16px] border-b-[16px] border-x-transparent border-b-gray-600 bg-transparent outline-gray-600">
                        <section className="triangle absolute left-[-16px] top-[1px] h-0 w-0 border-x-[16px] border-b-[16px] border-x-transparent border-b-white bg-transparent"></section>
                      </section>
                      <p className="mb-2 font-bold text-gray-900">
                        {formatAccountMessage({ id: 'removeItem', defaultMessage: 'Remove Item?' })}
                      </p>
                      <button
                        type="button"
                        className="mr-2 rounded-sm border border-blue-900 bg-white py-1 px-4 font-bold text-blue-900 hover:bg-blue-900 hover:text-white"
                        onClick={() => {
                          setIsLoad(true);
                          removeLineItem(wish?.lineItemId).finally(() => {
                            setIsLoad(false);
                          });
                        }}
                      >
                        {formatCommonMessage({ id: 'yes', defaultMessage: 'Yes' })}
                      </button>
                      <button
                        type="button"
                        className="rounded-sm border border-blue-900 bg-blue-900 py-1 px-4 font-bold text-white hover:bg-white hover:text-blue-900"
                        onClick={() => setItemsWithRemove({})}
                      >
                        {formatCommonMessage({ id: 'no', defaultMessage: 'No' })}
                      </button>
                    </section>
                  )}
                </div>
              </div>
              <div className="order-5 col-span-8 flex flex-col md:order-none md:col-span-2">
                {wish?.variant?.isOnStock ? (
                  <>
                    {wish?.variant?.attributes?.isProductShowOnSite &&
                    wish?.variant?.attributes?.customProductType !== COMING_SOON ? (
                      wish?.variant?.attributes?.isPreOrderable ? (
                        arePreOrderDatesInPast(wish) ? (
                          <button
                            type="button"
                            className="mb-4 rounded border bg-primaryBtn px-3 py-2 text-sm text-white"
                            onClick={() => {
                              addtoCartEvent(
                                wish?.variant,
                                1,
                                wish?.lineItemId,
                                wish?.personalizationTextLines,
                                getAdditionalSkus(wish),
                                wish,
                              );
                            }}
                          >
                            {formatCartMessage({ id: 'addToCart', defaultMessage: 'Add To Cart' })}
                          </button>
                        ) : arePreOrderDatesInFuture(wish) ? (
                          <button
                            type="button"
                            className={`mb-4 rounded border px-3 py-2 text-sm text-white ${
                              isDisableButton(wish) ? 'bg-gray-400' : 'bg-primaryBtn'
                            }`}
                            onClick={() => {
                              router.push(wish?.slug);
                            }}
                          >
                            {formatCartMessage({ id: 'preOrder', defaultMessage: 'Pre Order' })}
                          </button>
                        ) : isPreOrderEndFutureAndStreetPast(wish) ? (
                          <button
                            type="button"
                            className="mb-4 rounded border bg-primaryBtn px-3 py-2 text-sm text-white"
                            onClick={() => {
                              addtoCartEvent(
                                wish?.variant,
                                1,
                                wish?.lineItemId,
                                wish?.personalizationTextLines,
                                getAdditionalSkus(wish),
                                wish,
                              );
                            }}
                          >
                            {formatCartMessage({ id: 'addToCart', defaultMessage: 'Add To Cart' })}
                          </button>
                        ) : shouldShowAddToCartButton(wish) ? (
                          <button
                            type="button"
                            className="mb-4 rounded border bg-primaryBtn px-3 py-2 text-sm text-white"
                            onClick={() => {
                              addtoCartEvent(
                                wish?.variant,
                                1,
                                wish?.lineItemId,
                                wish?.personalizationTextLines,
                                getAdditionalSkus(wish),
                                wish,
                              );
                            }}
                          >
                            {formatCartMessage({ id: 'addToCart', defaultMessage: 'Add To Cart' })}
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="mb-4 rounded border bg-gray-400 px-3 py-2 text-sm text-white"
                            disabled
                          >
                            {formatCartMessage({ id: 'unavailable', defaultMessage: 'Unavailable' })}
                          </button>
                        )
                      ) : shouldShowAddToCartButton(wish) ? (
                        <button
                          type="button"
                          className="mb-4 rounded border bg-primaryBtn px-3 py-2 text-sm text-white"
                          onClick={() => {
                            addtoCartEvent(
                              wish?.variant,
                              1,
                              wish?.lineItemId,
                              wish?.personalizationTextLines,
                              getAdditionalSkus(wish),
                              wish,
                            );
                          }}
                        >
                          {formatCartMessage({ id: 'addToCart', defaultMessage: 'Add To Cart' })}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="mb-4 rounded border bg-gray-400 px-3 py-2 text-sm text-white"
                          disabled
                        >
                          {formatCartMessage({ id: 'unavailable', defaultMessage: 'Unavailable' })}
                        </button>
                      )
                    ) : (
                      <button
                        type="button"
                        className="mb-4 rounded border bg-gray-400 px-3 py-2 text-sm text-white"
                        disabled
                      >
                        {formatCartMessage({ id: 'unavailable', defaultMessage: 'Unavailable' })}
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    type="button"
                    className="mb-4 rounded border bg-gray-400 px-3 py-2 text-sm text-white"
                    disabled
                  >
                    {formatCartMessage({ id: 'unavailable', defaultMessage: 'Unavailable' })}
                  </button>
                )}
                <div className="mb-3 flex justify-between md:mb-0">
                  <InlineShareButtons
                    config={{
                      alignment: 'center',
                      color: 'grey',
                      enabled: true,
                      labels: null,
                      language: 'en',
                      networks: NETWORKS,
                      padding: 0,
                      url: `${shareThisBaseURL}${wish?._url}`,
                      productId: wish?.productId,
                    }}
                  />
                  <div
                    className="icon-container relative mt-3 ml-4"
                    data-gtm-share
                    data-gtm-method={GTM_SHARE}
                    data-gtm-type={GTM_PRODUCT}
                    data-gtm-id={wish?.productId}
                  >
                    <div
                      className="icon ease cursor-pointer transition-transform duration-200 hover:translate-y-[-5px] hover:opacity-80"
                      onClick={() => copyUrlToClipboard(window.location.host + wish?._url, i)}
                      onMouseEnter={() => setTooltipStates((prev) => ({ ...prev, [i]: true }))}
                      onMouseLeave={() => {
                        setTooltipStates((prev) => ({ ...prev, [i]: false }));
                        const newTexts = [...tooltipTexts];
                        newTexts[i] = 'Copy to Clipboard';
                        setTooltipTexts(newTexts);
                      }}
                    >
                      <LinkIcon />
                    </div>
                    {tooltipStates[i] && (
                      <div
                        className={`absolute top-full left-1/2 -translate-x-1/2 transform rounded-md bg-blue-700 px-2 py-1 text-xs text-white  `}
                      >
                        {tooltipTexts[i]}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="order-6 col-span-4 md:order-none md:hidden">
                <div className="mt-2 text-sm">
                  <a href="#" className="text-[#043C63]">
                    {' '}
                    {formatAccountMessage({ id: 'removeItem', defaultMessage: 'Remove Item' })}
                  </a>
                </div>
              </div> */}
            </div>
            <div className=" w-full md:w-[40%] ">
              {wish?.additionalLineItemIds?.length > 0 && (
                <AdditionalServices
                  lineItem={wish}
                  cartListItems={wishlistData}
                  setAddonItemId={setAddonItemId}
                  isFromSFLtoWishlist={true}
                  wishListData={wishListPrices}
                  isWishlist={true}
                />
              )}
            </div>
          </div>
        ))}
      </div>
      {isLoad || (isValidating && <Loader />)}
    </section>
  );
};
export default Wishlist;
