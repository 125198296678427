import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ProjectConfiguration } from '@Types/ProjectConfiguration';
import { mutate } from 'swr';
import Loader from 'components/commercetools-ui/loader/Loader';
import { GTM_MY_ACC, GTM_SIDEBAR } from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';
import useHash from 'helpers/hooks/useHash';
import Redirect from 'helpers/redirect';
import { Reference } from 'helpers/reference';
import { sendToMonetate } from 'helpers/utils/monetateHelper';
import { useCart, useAccount } from 'frontastic';
import Breadcrumb from '../breadcrumb';
import {
  AddressesSection,
  GeneralSection,
  OrdersHistorySection,
  AccountContent,
  Wallet,
  Subscriptions,
  Wishlist,
  CommunicationPreference,
  OrderDetail,
} from './sections/exporter';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export interface AccountDetailsProps {
  loginLink?: Reference;
}
interface tabObject {
  name: string;
  href: string;
}

const AccountDetails: React.FC<AccountDetailsProps> = (data) => {
  const { loggedIn, account, isPasswordChanged, isSessionExpired } = useAccount();
  const [isLoader, setIsLoader] = useState(true);
  const router = useRouter();

  useEffect(() => {
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  }, [account]);

  const { data: cartList, studioConfig: projectConfiguration } = useCart();
  //i18n messages
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  //current window hash
  const [hash, setHash] = useState(window.location.hash || '#');
  //tabs
  const tabs = [
    { name: formatAccountMessage({ id: 'accountHome', defaultMessage: 'Account Home' }), href: '#' },
    { name: formatAccountMessage({ id: 'orders.history', defaultMessage: 'Order History' }), href: '#orders' },
    { name: formatAccountMessage({ id: 'subscriptions', defaultMessage: 'Subscriptions' }), href: '#subscription' },
    { name: formatAccountMessage({ id: 'wallet', defaultMessage: 'Wallet' }), href: '#wallet' },
    { name: formatAccountMessage({ id: 'my.addresses', defaultMessage: 'Address Book' }), href: '#addresses' },
    { name: formatAccountMessage({ id: 'wishlist', defaultMessage: 'Wishlist' }), href: '#wishlist' },
    { name: formatAccountMessage({ id: 'profile', defaultMessage: 'Profile' }), href: '#profile' },
    { name: formatAccountMessage({ id: 'order.detail', defaultMessage: 'Order Detail' }), href: '#order-detail' },
    {
      name: formatAccountMessage({ id: 'CommunicationPreferences', defaultMessage: 'Communication Preferences' }),
      href: '#communicationPreference',
    },
  ];

  //tabs change (mobile only)
  const handleTabChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    window.location.hash = e.target.value;
  };

  //breadcrumb change
  const [tabName, setTabName] = useState<tabObject>({ name: '', href: '' });
  //tabs-content mapping
  const mapping = {
    '#': AccountContent,
    '#addresses': AddressesSection,
    '#orders': OrdersHistorySection,
    '#subscription': Subscriptions,
    '#wallet': Wallet,
    '#wishlist': Wishlist,
    '#communicationPreference': CommunicationPreference,
    '#profile': GeneralSection,
    '#order-detail': OrderDetail,
  };
  const [monetateSent, setMonetateSent] = useState(false);
  useEffect(() => {
    const callMonetate = async () => {
      const monetateConfig = JSON.parse(projectConfiguration?.monetateConstants.replace(/'/g, '"'));
      setMonetateSent(true);
      sendToMonetate(
        {
          page: 'myAccount',
          cart: cartList,
        },
        monetateConfig,
      );
    };
    if (cartList && !monetateSent && projectConfiguration?.monetateConstants) {
      callMonetate();
    }
  }, [cartList, projectConfiguration?.monetateConstants]);

  useEffect(() => {
    const handleHashChange = () => {
      setHash(window.location.hash || '#');
    };
    const handleLocationChange = () => {
      setHash(window.location.hash || '#');
    };

    window.addEventListener('hashchange', handleHashChange);
    router.events.on('routeChangeComplete', handleLocationChange);
    router.events.on('hashChangeComplete', handleHashChange);

    // Set the initial value of tabName
    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].href === hash) {
        if (tabs[i].name === 'Account Home') {
          setTabName({ name: '', href: '' });
        } else {
          setTabName(tabs[i]);
          break;
        }
      }
    }
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
      router.events.off('routeChangeComplete', handleLocationChange);
      router.events.off('hashChangeComplete', handleHashChange);
    };
  }, [hash]);

  //current rendered content
  const Content = mapping[hash];

  if (isLoader) return <Loader />;

  //user not logged in
  if (!loggedIn && !isLoader && !isPasswordChanged && !isSessionExpired)
    return <Redirect target={`/?login=true&callBackUrl=${router?.asPath}`} />;
  return (
    <>
      <div>
        {/* Content area */}
        <div className="">
          {typeof window !== 'undefined' && window.location.hash !== '#order-detail' ? (
            <Breadcrumb pageName={'accountPage'} pageLink={tabName} />
          ) : (
            <Breadcrumb pageName={'orderDetail'} pageLink={tabName} />
          )}
          <div className="w-full pb-16 lg:m-auto xl:w-[1147px]">
            <div className="w-full">
              <div className="lg:flex lg:py-6">
                {/* Mobile */}
                <div className="mb-6 lg:hidden">
                  <label htmlFor="selected-tab" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    id="selected-tab"
                    name="selected-tab"
                    className="select mt-1 block w-full rounded-sm border-[#999] stroke-accent-400 py-2  pr-10 pl-3 text-base font-bold text-[#333333] focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                    value={
                      hash === '#order-detail'
                        ? '#orders'
                        : (tabs.find((tab) => tab.href === hash) || { href: '#' }).href
                    }
                    onChange={handleTabChange}
                  >
                    {tabs.map((tab) => (
                      <>
                        {tab.name !== 'Order Detail' && (
                          <option key={tab.name} value={tab.href} className="text-gray-600">
                            {tab.name}
                          </option>
                        )}
                      </>
                    ))}
                  </select>
                </div>

                {/* Desktop */}
                {typeof window !== 'undefined' && window.location.hash !== '#order-detail' && (
                  <div
                    className="hidden w-[20%] lg:mr-[20px] lg:block lg:w-[213px]"
                    data-gtm-menu
                    data-gtm-id={GTM_SIDEBAR}
                    data-gtm-name={GTM_MY_ACC}
                  >
                    <h2 className="mb-2 border-b-2 pb-2 font-bold">
                      {formatAccountMessage({ id: 'myAccount', defaultMessage: 'My Account' })}
                    </h2>
                    <div>
                      <nav className="-mb-px flex flex-col">
                        {tabs.map((tab) => (
                          <>
                            {tab.name !== 'Order Detail' && (
                              <a
                                key={tab.name}
                                href={tab.href}
                                className={classNames(
                                  tab.href === hash
                                    ? 'bg-[#EFEFEF] font-bold text-gray-900 hover:underline'
                                    : 'border-transparent text-gray-900',
                                  'my-1 whitespace-nowrap rounded-sm py-2 pl-[15px] pr-[24px] text-sm hover:underline',
                                )}
                              >
                                {tab.name}
                              </a>
                            )}
                          </>
                        ))}
                      </nav>
                    </div>
                  </div>
                )}
                {Content && (
                  <div className="grow lg:w-[914px]">
                    <Content cardData={data} projectConfiguration={projectConfiguration} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountDetails;
