import React, { useEffect, useState, useCallback, useRef } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import InformationRequired from 'components/commercetools-ui/aafes/Modals/informationRequired';
import Loader from 'components/commercetools-ui/loader/Loader';
import { FORM_PROGRESS } from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';
import Redirect from 'helpers/redirect';
import { Reference } from 'helpers/reference';
import { logEventToDataLayer } from 'helpers/utils/analytics';
import { useAccount, useCart } from 'frontastic';
import { NextFrontasticImage } from 'frontastic/lib/image';
const Breadcrumb = dynamic(() => import('../breadcrumb'));
const CreateAccount = dynamic(() => import('./createAccount'));
const CreateAccountCard = dynamic(() => import('./createAccountCard'));
const ForgotCredentials = dynamic(() => import('./forgotCredentials'));
const RegistrationForm = dynamic(() => import('./registrationForm'));
const DischargePaperConfirmation = dynamic(() => import('./uploadDischargePaper'));
const MissingDocument = dynamic(() => import('./missingDocument'));

export interface RegisterProps {
  logo?: NextFrontasticImage;
  loginLink?: Reference;
  ListText?: any;
  benefitSubheading?: any;
}

const Register: React.FC<RegisterProps> = ({ logo, loginLink, ListText, benefitSubheading }) => {
  //i18n messages
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage } = useFormat({ name: 'common' });
  const { getBranchesOfService, isEmailExist } = useAccount();
  const [dataBranchOfService, setDataBranchOfService] = useState(null);
  const { studioConfig } = useCart();
  const eligibilityCodesRes = studioConfig?.eligibilityCodes;
  const eligibilityCode = eligibilityCodesRes ? JSON.parse(eligibilityCodesRes?.replace(/'/g, '"')) : '';
  const [svrcCode, setSvrcCode] = useState();
  const branchesOfService = useCallback(async () => {
    const data = await getBranchesOfService();
    setDataBranchOfService(data);
  }, []);
  const [errorMessage, setErrormessage] = useState(null);
  useEffect(() => {
    branchesOfService();
  }, [branchesOfService]);

  //account actions
  const { register, loggedIn, getCcdValidationCheck, vetVerify } = useAccount();
  const [verified, setVerified] = useState(false);
  const [formData, setFormData] = useState('');
  const [branchServiceError, setBranchServiceError] = useState('');
  const [SSNMessage, setSSNmessage] = useState(true);
  const isFullSSN = useRef(false);
  const [informationRequired, setInformationRequired] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [ccdApiResponse, setCCDApiResponse] = useState(null);
  const [isCIDExist, setIsCIDExist] = useState(false);
  const [isEligible, setIsEligible] = useState(false);
  const [vetVerifyResponse, setVetVerifyResponse] = useState('');
  const [MissingDocumentSection, setMissingDocumentSection] = useState(false);
  const [DischargePaperConfirmationSection, setDischargePaperConfirmation] = useState(false);
  const [createAccountSection, setCreateAccountSection] = useState(true);
  const [eligibilityResponse, setEligibilityResponse] = useState('');
  const Abcd = studioConfig ? studioConfig?.svrcCode : '';
  const [enableTryAgain, setEnableTryAgain] = useState('');
  function handleInformationRequired() {
    setInformationRequired(false);
  }

  const handleSvrcCode = (code) => {
    setSvrcCode(code);
  };
  let parsedSvrcData;
  try {
    parsedSvrcData = JSON.parse(Abcd);
  } catch (error) {
    console.error('Error parsing JSON:', error);
  }
  useEffect(() => {
    if (parsedSvrcData && svrcCode && parsedSvrcData.hasOwnProperty(svrcCode)) {
      setEligibilityResponse(parsedSvrcData[svrcCode]);
      if (svrcCode === '1' || svrcCode === '2' || svrcCode === '3' || svrcCode === '4') {
        setEnableTryAgain('Please try again.');
      } else if (svrcCode === 'P') {
        setEnableTryAgain('You will be notified of the results of our reviews in 2-3 business days.');
      } else if (svrcCode === 'C' || svrcCode === 'D') {
        setEnableTryAgain('Please retry later.');
      } else if (svrcCode === 'I') {
        setEnableTryAgain("I'm sorry, you are ineligible to shop.");
      }
    }
  }, [parsedSvrcData, svrcCode]);
  const router = useRouter();
  const [selectedRadioButton, setSelectedRadioButton] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [uploadDocSection, setUploadDocSection] = useState(false);
  const handleRadioButtonChange = (event) => {
    setSelectedRadioButton(event?.target?.value);
  };
  const renderDropdownOptions = () => {
    const selectedData = dataBranchOfService?.find((data) => data?.key === selectedRadioButton);

    if (selectedData && selectedData.value && selectedData.value.ranks) {
      return selectedData?.value?.ranks?.map((rank) => (
        <option key={rank} value={rank}>
          {rank}
        </option>
      ));
    } else {
      return null;
    }
  };

  const {
    register: customRegister,
    handleSubmit: getFormData,
    formState: { errors },
    watch,
    reset,
  } = useForm();
  const watchEmail = watch('email', '');
  const renderDayOptions = () => {
    const days = [];
    for (let i = 1; i <= 31; i++) {
      const formattedNumber = i < 10 ? `0${i}` : i;
      days.push(
        <option key={i} value={formattedNumber}>
          {formattedNumber}
        </option>,
      );
    }
    return days;
  };

  const renderMonthOptions = () => {
    const months = [
      { value: '', label: 'Month', disabled: true, selected: true },
      { value: '01', label: 'January' },
      { value: '02', label: 'February' },
      { value: '03', label: 'March' },
      { value: '04', label: 'April' },
      { value: '05', label: 'May' },
      { value: '06', label: 'June' },
      { value: '07', label: 'July' },
      { value: '08', label: 'August' },
      { value: '09', label: 'September' },
      { value: '10', label: 'October' },
      { value: '11', label: 'November' },
      { value: '12', label: 'December' },
    ];
    return months.map((month) => (
      <option key={month.value} value={month.value} disabled={month?.disabled} selected={month?.selected}>
        {month.label}
      </option>
    ));
  };

  const renderYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 100;
    const years = [];
    for (let year = currentYear; year >= startYear; year--) {
      years.push(
        <option key={year} value={year}>
          {year}
        </option>,
      );
    }
    return years;
  };

  const handleFormSubmit = async (data) => {
    data.firstName = data.firstName.replace(/\s+/g, ' ').trim();
    data.lastName = data.lastName.replace(/\s+/g, ' ').trim();
    data.email = data.email.toLowerCase();
    data.confirmEmail = data.confirmEmail.toLowerCase();
    if (!data.branchService) {
      setBranchServiceError('Please select Branch of Service');
      return;
    } else if (data.branchService) {
      setBranchServiceError('');
    }
    const chosenData = dataBranchOfService?.find((data) => data?.key === selectedRadioButton);
    const dataLayerData = {
      form_name: 'Sign Up',
      content_id: 'Account',
      step: 'step 2: Check Eligibility',
    };
    logEventToDataLayer(FORM_PROGRESS, dataLayerData);
    setFormData({
      ...data,
      branchOfService: chosenData?.value?.branchOfService,
      branchOfServiceDesc: chosenData?.value?.branchOfServiceDesc,
    });
    setShowLoader(true);
    const payload = { ...data };

    try {
      const emailExist = await isEmailExist(payload?.email);
      if (emailExist?.errorCode) {
        setErrormessage(emailExist?.body);
        setShowLoader(false);
      } else {
        try {
          const ccdDataCheck = await getCcdValidationCheck(data);
          if (SSNMessage) {
            if (ccdDataCheck?.errorCode) {
              setErrormessage(ccdDataCheck?.body);
              setVerified(false);
              setShowLoader(false);
              setSSNmessage(false);
              setIsCIDExist(ccdDataCheck?.isCIDExist ? true : false);
            } else if (eligibilityCode.includes(ccdDataCheck?.EligibilityCode || '')) {
              setShowLoader(false);
              setVerified(false);
              setUploadDocSection(true);
              setIsEligible(true);
              const foundService = dataBranchOfService.find((service) => service.key === data.branchService);
              const serviceCode = foundService ? [foundService.value.branchOfService[0]] : [];
              const vetVerifyPayload = { ...data, serviceCode: serviceCode[0] };
              const res = await vetVerify(vetVerifyPayload);
              setVetVerifyResponse(res);
              if (res?.message === 'NEED_MORE_INFO' || 'DEERS_MULTI_MATCH' || 'DEERS_NO_RECORD') {
                setUploadDocSection(true);
              } else if (res?.message === 'SHOP_NOW_EXISTING_STATUS' || 'SHOP_NOW') {
                setVerified(true);
              } else if (res?.message === 'INELIGIBLE' || 'PROCESS_MORE_INFO' || 'CCD_DOWN' || 'DD214_SERVICE_DOWN') {
                setMissingDocumentSection(true);
              }
            } else {
              setCCDApiResponse(ccdDataCheck);
              setVerified(true);
              setShowLoader(false);
            }
          } else if (!SSNMessage) {
            setShowLoader(false);
            if (ccdDataCheck?.errorCode) {
              setShowLoader(true);
              router.push('/retrieveusernamefailure');
            } else if (eligibilityCode.includes(ccdDataCheck?.EligibilityCode)) {
              setUploadDocSection(true);
              setErrormessage('');
              const foundService = dataBranchOfService.find((service) => service.key === data.branchService);
              const serviceCode = foundService ? [foundService.value.branchOfService[0]] : [];
              const vetVerifyPayload = { ...data, serviceCode: serviceCode[0] };
              const res = await vetVerify(vetVerifyPayload);
              setVetVerifyResponse(res);
              if (res?.message === 'NEED_MORE_INFO' || 'DEERS_MULTI_MATCH' || 'DEERS_NO_RECORD') {
                setUploadDocSection(true);
              } else if (res?.message === 'SHOP_NOW_EXISTING_STATUS' || 'SHOP_NOW') {
                setVerified(true);
              } else if (res?.message === 'INELIGIBLE' || 'PROCESS_MORE_INFO' || 'CCD_DOWN' || 'DD214_SERVICE_DOWN') {
                setMissingDocumentSection(true);
              }
            } else {
              setCCDApiResponse(ccdDataCheck);
              setVerified(true);
              setShowLoader(false);
            }
          } else {
            setCCDApiResponse(ccdDataCheck);
            setVerified(true);
            setShowLoader(false);
          }
        } catch (ccdError) {
          console.error('Error during ccdDataCheck:', ccdError);
          setShowLoader(false);
        }
      }
    } catch (error) {
      console.error('Error during ssnCheck or existEmail:', error);
      setShowLoader(false);
    }
  };
  const isLoggedInWithCreateAccount = useRef(false);
  if (!isLoggedInWithCreateAccount.current && loggedIn) {
    return <Redirect target="/" />;
  }
  const hasInvalidDOB = errors.dobDay || errors.dobMonth || errors.dobYear;
  const formattedRank = selectedRadioButton.replace(/([A-Z])/g, ' $1').trim();

  return (
    <>
      <section className="">
        <Breadcrumb pageName={'createAccount'} pageLink={{ name: 'Create Account', href: '/register' }} />
      </section>
      <>
        {!verified ? (
          <div className="py-7 lg:m-auto xl:w-[1156px] xl:px-[30px]">
            <h1 className="mb-4 text-[24px] font-bold leading-[28.9px] text-[#333333]">
              {formatAccountMessage({ id: 'createAccount', defaultMessage: 'Create Account' })}
            </h1>

            <div className="block md:flex">
              <div className="order-1 mb-4 md:order-2 md:mb-0 md:w-[19rem]">
                <CreateAccountCard listData={ListText} accountSubHead={benefitSubheading} />
              </div>
              {createAccountSection && (
                <div className="order-2 col-span-8 pt-7 md:order-1 md:mr-4 xl:w-[796px]">
                  <section className="flex items-center justify-between text-center">
                    <h2 className="text-[18px] font-normal leading-[21.6px] text-[#666666]">
                      {formatAccountMessage({ id: 'checkEligibility', defaultMessage: 'Check eligibility' })}
                    </h2>
                    <p className="text-right text-[14px] font-normal leading-[16.8px] text-[#B81335]">
                      {formatAccountMessage({ id: 'required', defaultMessage: '* Required' })}
                    </p>
                  </section>
                  <hr className="my-[13px] bg-[rgb(0,0,0,0)]/20" />
                  <p className="mb-4 text-[14px] font-normal leading-[16.8px] text-[#666666]">
                    {formatAccountMessage({
                      id: 'eligibilityCheck',
                      defaultMessage:
                        'Before you can create your AAFES account, we need to make sure you are eligible.',
                    })}
                  </p>
                  <ul className="my-4 ml-4 list-disc">
                    {errors?.firstName && <li className="text-[#cd0808]">{errors?.firstName?.message}</li>}
                    {errors?.lastName && <li className="text-[#cd0808]">{errors?.lastName?.message}</li>}
                    {errors?.ssn && <li className="text-[#cd0808]">{errors?.ssn?.message}</li>}
                    {hasInvalidDOB && <li className="text-[#cd0808]">Invalid date of birth.</li>}
                    {branchServiceError && <div className="text-[#cd0808]">{branchServiceError}</div>}
                    {/* { <li className="text-[#cd0808]">{errors?.dobYear.message}</li>} */}
                    {errors?.email && <li className="text-[#cd0808]">{errors?.email.message}</li>}
                    {errors?.confirmEmail && (
                      <li className="text-[#cd0808]">
                        {formatAccountMessage({ id: 'emailNotMatch', defaultMessage: 'Email addresses do not match' })}
                      </li>
                    )}
                    {errors?.phoneNumber && <li className="text-[#cd0808]">{errors?.phoneNumber?.message}</li>}
                    {errors?.branchService && <li className="text-[#cd0808]">{errors?.branchService?.message}</li>}
                    {errors?.branchRankService && (
                      <li className="text-[#cd0808]">{errors?.branchRankService?.message}</li>
                    )}
                    {errorMessage && <li className="text-[#cd0808]">{errorMessage}</li>}
                  </ul>
                  {isCIDExist && (
                    <div className="my-4">
                      <ForgotCredentials />
                    </div>
                  )}

                  <RegistrationForm
                    getFormData={getFormData}
                    handleFormSubmit={handleFormSubmit}
                    setInformationRequired={setInformationRequired}
                    formatAccountMessage={formatAccountMessage}
                    formatMessage={formatMessage}
                    isEligible={isEligible}
                    isCIDExist={isCIDExist}
                    uploadDocSection={uploadDocSection}
                    customRegister={customRegister}
                    SSNMessage={SSNMessage}
                    isPasswordVisible={isPasswordVisible}
                    setIsPasswordVisible={setIsPasswordVisible}
                    renderDayOptions={renderDayOptions}
                    renderMonthOptions={renderMonthOptions}
                    renderYearOptions={renderYearOptions}
                    watchEmail={watchEmail}
                    dataBranchOfService={dataBranchOfService}
                    selectedRadioButton={selectedRadioButton}
                    handleRadioButtonChange={handleRadioButtonChange}
                    formattedRank={formattedRank}
                    renderDropdownOptions={renderDropdownOptions}
                    vetVerifyResponse={vetVerifyResponse}
                    setDischargePaperConfirmation={setDischargePaperConfirmation}
                    setMissingDocumentSection={setMissingDocumentSection}
                    setCreateAccountSection={setCreateAccountSection}
                    handleSvrcCode={handleSvrcCode}
                  />
                </div>
              )}
              {DischargePaperConfirmationSection && (
                <div className="order-2 col-span-8 mb-10 pt-7 md:order-1 md:mr-4 xl:w-[796px]">
                  <DischargePaperConfirmation />
                </div>
              )}
              {MissingDocumentSection && (
                <div className="order-2 col-span-8 mb-10 pt-7 md:order-1 md:mr-4 xl:w-[796px]">
                  <MissingDocument eligibilityResponse={eligibilityResponse} enableTryAgain={enableTryAgain} />
                </div>
              )}
            </div>
            {showLoader && <Loader />}
          </div>
        ) : (
          <CreateAccount
            registerData={formData}
            isLoggedInWithCreateAccount={isLoggedInWithCreateAccount}
            listData={ListText}
            accountSubHead={benefitSubheading}
            ccdApiResponse={ccdApiResponse}
          />
        )}
        {informationRequired && (
          <InformationRequired informationRequired={informationRequired} closeModal={handleInformationRequired} />
        )}
      </>
    </>
  );
};

export default Register;
