import React, { useEffect, useRef, useState } from 'react';
import NextLink from 'next/link';
import { HiOutlineMinus, HiOutlinePlus } from 'react-icons/hi';
import { MdOutlineFavorite, MdOutlineFavoriteBorder } from 'react-icons/md';
import useSWR from 'swr';
import Loader from 'components/commercetools-ui/loader/Loader';
import { MARKETING_BUG_NAMES, MAX_COLOR } from 'helpers/constants/aafes';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import useGATrackViewActions from 'helpers/hooks/gaEvents/useGATrackViewActions';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import { fetchApiHub, revalidateOptions, useAccount, useCart } from 'frontastic';
import Image from 'frontastic/lib/image';
import AddOn from '../../../../images/add-on-logo.png';

export default function ProductCell({ product, index, ...productProps }) {
  const {
    storePriceFBP,
    CompareSection,
    handleAddWishlist,
    handleRemoveWishlist,
    handleBorder,
    wishListItems,
    PriceDetailSection,
    LoginForPriceSection,
    productDroplets,
    redirectFrom,
    handleProductImageHover,
    handleProdutImageMouseLeave,
    hoverImage,
    hoveringSku,
  } = productProps;
  const swatchesRef = useRef(null);
  const [isDesktop] = useMediaQuery(tablet);
  const { loggedIn } = useAccount();
  const MASTER_VARIANT = 0;
  const [variant, setVariant] = useState(product?.variants?.[MASTER_VARIANT]);
  const [variantColors, setVariantColors] = useState([]);
  const [swatchHoverImage, setSwatchHoverImage] = useState(null);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const { trackSelectItem } = useGATrackViewActions();
  const productMarketingBug = product?.marketingBug;
  const isMarketPlaceItem = product?.product_type === 'INTEGRATED_VENDOR';
  const { studioConfig: projectConfiguration } = useCart();
  const currentPrice = storePriceFBP?.[variant?.skuId]
    ? CurrencyHelpers.formatForCurrency(storePriceFBP?.[variant?.skuId] * 100)
    : '';
  const [maxColorToShow, setMaxColorToShow] = useState<number>(MAX_COLOR);
  let lineItem = '';
  const isSwatchView = variantColors?.length > MAX_COLOR && maxColorToShow === variantColors?.length;
  const handleChangeVariant = (color) => {
    setVariant(product?.variants?.find((variant) => variant?.colorSwatchURL === color));
    setMaxColorToShow(MAX_COLOR);
  };

  const handleHoverImage = (color) => {
    const hoveredVariant = product?.variants?.find((variant) => variant?.colorSwatchURL === color);
    setSwatchHoverImage(hoveredVariant?.mediumImage);
  };

  const handleOutsideClick = (e) => {
    if (swatchesRef?.current && !swatchesRef?.current?.contains(e?.target)) {
      setMaxColorToShow(MAX_COLOR);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('touchstart', handleOutsideClick);
    };
  }, []);
  useEffect(() => {
    setVariant(product?.variants?.[MASTER_VARIANT]);
    const colorSwatchURLArray = product?.variants?.map((variant) => variant?.colorSwatchURL)?.filter(Boolean);
    setVariantColors([...new Set(colorSwatchURLArray)]);
  }, [product]);
  const isOutletCategory = product?.parentCategories?.some(
    (category) => category === projectConfiguration?.outletCategoryName,
  );
  const [getAllCustomMessages, setGetCustomMessages] = useState(null);

  const { data } = useSWR(
    `/action/customObjects/getCustomMessages?messages=outletFinalSalePromo`,
    fetchApiHub,
    revalidateOptions,
  );
  useEffect(() => {
    setGetCustomMessages(data);
  }, [data]);

  const handleClick = (event) => {
    trackSelectItem({ product, type: redirectFrom });
    if (event.ctrlKey || event.metaKey) return;
    setIsLoad(true);
  };
  return (
    <>
      <div
        className={`relative h-auto w-full p-4 ${
          isDesktop ? `${handleBorder(index)}` : 'rounded border'
        } flex flex-col place-content-between`}
      >
        <div className="flex flex-row gap-x-4 gap-y-3 md:flex-col">
          <NextLink href={variant?.pdpURL ? variant?.pdpURL : '#'}>
            <a aria-label="Go to PDP page" className="h-fit w-fit md:mx-auto">
              <div
                className="mt-5 h-[90px] w-[90px] cursor-pointer sm:h-[174px] sm:w-[174px]"
                data-pid={variant?.skuId}
              >
                <Image
                  id="productImage"
                  aria-labelledby="productName"
                  className="h-full w-full"
                  src={(hoveringSku === variant?.skuId ? hoverImage : null) ?? swatchHoverImage ?? variant?.mediumImage}
                  alt={variant?.displayName}
                  onClick={(event) => {
                    handleClick(event);
                  }}
                  onMouseEnter={() => {
                    handleProductImageHover(variant?.skuId);
                  }}
                  onMouseLeave={handleProdutImageMouseLeave}
                />
              </div>
            </a>
          </NextLink>
          <div className={`flex flex-col gap-y-3 pr-2 md:p-0`}>
            {variantColors?.length > 1 && (
              <div className={`relative flex gap-2 md:justify-center ${isSwatchView ? 'mb-5' : ''}`}>
                <div
                  className={`flex max-w-[146px] flex-wrap gap-2 ${
                    isSwatchView ? 'absolute  z-[3] border border-gray-400 bg-white p-1 md:p-2' : ''
                  } `}
                  ref={swatchesRef}
                >
                  {variantColors?.slice(0, isSwatchView ? MAX_COLOR : maxColorToShow)?.map((item, index) => {
                    return (
                      <Image
                        key={index}
                        src={item}
                        alt="Variant Item"
                        id={`colorVariantImage-${index}`}
                        aria-labelledby="productName"
                        onMouseEnter={() => {
                          handleHoverImage(item);
                        }}
                        onMouseLeave={() => {
                          setSwatchHoverImage(null);
                        }}
                        onClick={() => {
                          handleChangeVariant(item);
                        }}
                        className={`h-[18px] w-[18px] cursor-pointer rounded-full border-2 ${
                          item === variant?.colorSwatchURL ? 'border-[#043C63]' : 'border-gray-400'
                        }`}
                      ></Image>
                    );
                  })}
                  {variantColors?.length > MAX_COLOR && (
                    <button className="z-[3] h-5 w-5 rounded border px-0.5">
                      {maxColorToShow !== variantColors?.length ? (
                        <HiOutlinePlus
                          onClick={() => {
                            setMaxColorToShow(variantColors?.length);
                          }}
                          className="text-gray-900"
                        />
                      ) : (
                        <HiOutlineMinus
                          onClick={() => {
                            setMaxColorToShow(MAX_COLOR);
                          }}
                          className="text-gray-900"
                        />
                      )}
                    </button>
                  )}
                  {isSwatchView &&
                    variantColors?.slice(MAX_COLOR, maxColorToShow)?.map((item, index) => {
                      return (
                        <Image
                          key={index}
                          src={item}
                          alt="Variant Item"
                          id={`colorVariantImage-${index}`}
                          aria-labelledby="productName"
                          onMouseEnter={() => {
                            handleHoverImage(item);
                          }}
                          onMouseLeave={() => {
                            setSwatchHoverImage(null);
                          }}
                          onClick={() => {
                            handleChangeVariant(item);
                          }}
                          className={`h-[18px] w-[18px] cursor-pointer rounded-full border-2 ${
                            item === variant?.colorSwatchURL ? 'border-[#043C63]' : 'border-gray-400'
                          }`}
                        ></Image>
                      );
                    })}
                </div>
              </div>
            )}
            <NextLink href={variant?.pdpURL ? variant?.pdpURL : '#'}>
              <a aria-label="Go to PDP page">
                <div
                  id="productName"
                  data-pid={variant?.skuId}
                  aria-labelledby="productName"
                  className="w-fit cursor-pointer text-sm font-bold leading-5 text-blue-900 hover:underline sm:m-0"
                  onClick={(event) => {
                    handleClick(event);
                  }}
                >
                  {product?.displayName}
                </div>
              </a>
            </NextLink>
            {product?.useFacilityBasedPrice && !isMarketPlaceItem && (
              <>{loggedIn ? currentPrice : <LoginForPriceSection />}</>
            )}
            {!product?.useFacilityBasedPrice && !isMarketPlaceItem && (
              <>
                {' '}
                {product?.msrpRestriction
                  ? loggedIn
                    ? PriceDetailSection(product)
                    : LoginForPriceSection()
                  : PriceDetailSection(product)}{' '}
              </>
            )}
            <section className="flex flex-col gap-y-1">
              {productDroplets?.promotions?.map((promotion, index) => {
                return promotion?.isShowOnPLP ? (
                  <div key={index}>
                    <span className="text-[13px] leading-5 text-red-900">{promotion?.name}</span>
                  </div>
                ) : null;
              })}
            </section>
            {isOutletCategory && (
              <div className="outlet mt-1">
                <span className="w-fit text-sm font-bold leading-5 text-gray-900 sm:m-0">
                  <strong>{getAllCustomMessages?.outletFinalSalePromo}</strong>
                </span>
              </div>
            )}
            {!isMarketPlaceItem && <div id={`BVRRInlineRating-${product?.repositoryId}`}></div>}
            {product?.isAddon && <Image className="h-auto w-10" src={AddOn?.src} alt="Addon Product" />}
            <div className="md:hidden">{CompareSection(product?.variants?.[MASTER_VARIANT])}</div>
          </div>
          {isLoad && <Loader />}
        </div>
        <div className="hidden md:block">{CompareSection(product?.variants?.[MASTER_VARIANT])}</div>
        {!isMarketPlaceItem && (
          <>
            {wishListItems?.some((item) => {
              if (item?.sku === variant?.skuId) {
                lineItem = item?.lineItemId;
                return true;
              }
            }) ? (
              <MdOutlineFavorite
                onClick={() => {
                  handleRemoveWishlist(lineItem);
                }}
                className="absolute bottom-4 right-2 h-5 w-5 cursor-pointer text-[#000000b3] md:top-2"
              />
            ) : (
              <MdOutlineFavoriteBorder
                onClick={() => {
                  handleAddWishlist(variant?.skuId, product);
                }}
                className="absolute bottom-4 right-2 h-5 w-5 cursor-pointer text-[#000000b3] md:top-2"
              />
            )}
          </>
        )}
        {productMarketingBug && !isMarketPlaceItem && (
          <div
            className={`item-tag absolute top-1.5 left-1.5 rounded-sm px-1 py-0.5 text-xs font-bold leading-5 text-white ${productMarketingBug}`}
          >
            {MARKETING_BUG_NAMES?.[productMarketingBug]}
          </div>
        )}
      </div>
    </>
  );
}
