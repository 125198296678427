import { useEffect, useState } from 'react';

export const useElementInViewport = (selector, onView, options = { threshold: 0.1 }) => {
  const [hasTriggered, setHasTriggered] = useState(false);

  useEffect(() => {
    if (hasTriggered) return; // Prevent re-triggering

    const element = document.querySelector(selector);
    if (!element) return;

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !hasTriggered) {
        onView();
        setHasTriggered(true);
        observer.disconnect(); // Stop observing after triggering
      }
    }, options);

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [selector, onView, options, hasTriggered]);
};

export const useContentViewTracker = (selector, onView, options = { threshold: 0.1 }) => {
  const [trackedElements, setTrackedElements] = useState(new Set());

  useEffect(() => {
    const elements = document.querySelectorAll(selector);
    if (!elements.length) return;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const element = entry.target;

        if (entry.isIntersecting && !trackedElements.has(element)) {
          const value = element.getAttribute('value');
          if (value) {
            onView(value);
            setTrackedElements((prev) => new Set(prev).add(element));
          }
        }
      });
    }, options);
    elements.forEach((element) => observer.observe(element));

    return () => {
      observer.disconnect();
    };
  }, [selector, onView, options, trackedElements]);
};
