import React from 'react';
import Link from 'next/link';
import UniformStar from 'components/icons/uniformStar';
import { GTM_UNIFORM_BUILDER } from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';

export default function BuildUniformSet() {
  const { studioConfig } = useCart();
  const { formatMessage } = useFormat({ name: 'common' });
  const uniformBuilderLink = studioConfig?.uniformBuilderBreadcrumbLink ?? '/';
  return (
    <section className="my-4">
      <div className="flex gap-x-3 text-blue-900" data-gtm-cta data-gtm-id={GTM_UNIFORM_BUILDER}>
        <UniformStar className="fill-current" />
        <div className="flex items-center justify-center">
          <Link href={uniformBuilderLink}>
            <a>
              <p className="w-fit text-sm leading-5 underline hover:cursor-pointer hover:no-underline">
                {formatMessage({ id: 'buildUniformSet', defaultMessage: 'Build a Uniform Set' })}
              </p>
            </a>
          </Link>
        </div>
      </div>
    </section>
  );
}
