import { useCallback } from 'react';
import { EXTPRICE_BRONZE, GA_REMOVE_FROM_CART } from 'helpers/constants/aafes';
import { logEventToDataLayer } from 'helpers/utils/analytics';
import { calculateTotalValue, transformItemData } from 'helpers/utils/commonGAUtils';

const useGATrackRemoveAction = () => {
  const trackGARemoveFromCart = useCallback((trackRemoveFromCartObj: any) => {
    const { product, type, quantity } = trackRemoveFromCartObj;
    try {
      const transformedItem = transformItemData(product, undefined, undefined, quantity);
      const totalValue = calculateTotalValue(transformedItem);
      const cartItem = {
        content_id: type,
        ecommerce: {
          value: totalValue,
          currency: EXTPRICE_BRONZE.currencyCode,
          items: transformedItem,
        },
      };
      logEventToDataLayer(GA_REMOVE_FROM_CART, cartItem);
    } catch (error) {
      console.error('ga4 remove from cart event fail', error);
    }
  }, []);

  return { trackGARemoveFromCart };
};

export default useGATrackRemoveAction;
