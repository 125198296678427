import React, { useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { ReferenceLink } from 'helpers/reference';
import { useAccount } from 'frontastic';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import GuestProfileValidation from '../Modals/guestProfileValidation';
const GuestMiniCart = dynamic(() => import('./guestMiniCart'));
const GuestHeader = ({
  exchangeLogo,
  cartLogo,
  homeLink,
  cartLink,
  frequentlyAskedQuestionsLink,
  termsAndConditionsLink,
  cartList,
}) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { loggedIn } = useAccount();
  const [showModal, setShowModal] = useState(false);
  const [isNotGuestPDP, setIsNotGuestPDP] = useState(false);
  const router = useRouter();
  const headerRef = useRef<HTMLDivElement>();
  const height = headerRef?.current?.clientHeight;
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (router?.asPath) {
      const isGuestPdp = RegExp(/\/([^\/]+)\/scs\/(DSCO\d{10}|\d{7})/).exec(window?.location?.pathname);
      setIsNotGuestPDP(!!!isGuestPdp);
    }
  }, [router?.asPath]);

  useEffect(() => {
    if (loggedIn) {
      handleOpenModal();
    }
  }, [loggedIn]);
  return (
    <>
      <section className="w-full">
        <section className="-mx-4 lg:-mx-0">
          <section
            ref={headerRef}
            className={`header mb-5 px-3 pb-[1px] shadow-[0_2px_4px_0_rgba(0,0,0,0.2)] md:px-[30px] lg:border-b lg:border-blue-900 lg:pb-0 lg:shadow-none xl:pb-0 ${
              isNotGuestPDP ? 'fixed z-20 w-full max-w-[1440px] bg-white' : 'relative'
            }`}
          >
            <section className="wrapper">
              <section className="headerTop relative z-10 mb-3 flex cursor-pointer items-center justify-between pt-[20px]">
                <section>
                  <NextLink aria-Label="home button" href="/">
                    <a className="group relative -m-2 flex items-center p-2">
                      <img src={exchangeLogo} alt="My Exchage Logo" className="mr-3" />
                    </a>
                  </NextLink>
                </section>
                <GuestMiniCart cartList={cartList} cartLogo={cartLogo} cartLink={cartLink} />
              </section>
            </section>
            <div className="mb-[14px] mt-[25px] flex items-center gap-x-[10px] text-sm md:text-base ">
              <span className="whitespace-nowrap text-[12px] font-semibold leading-4 text-blue-900 xs:text-sm">
                <ReferenceLink aria-Label="home link" target={homeLink}>
                  {formatMessage({ id: 'home', defaultMessage: 'Home' })}
                </ReferenceLink>
              </span>{' '}
              |{' '}
              <span className="whitespace-nowrap text-[12px] font-semibold leading-4 text-blue-900 xs:text-sm">
                <ReferenceLink aria-Label="link" target={frequentlyAskedQuestionsLink}>
                  {formatMessage({ id: 'frequentlyAskedQuestions', defaultMessage: 'Frequently Asked Questions' })}
                </ReferenceLink>
              </span>{' '}
              |{' '}
              <span className="whitespace-nowrap text-[12px] font-semibold leading-4 text-blue-900 xs:text-sm">
                <ReferenceLink aria-Label="link" target={termsAndConditionsLink}>
                  {formatMessage({ id: 'termsAndConditions', defaultMessage: 'Terms and Conditions' })}
                </ReferenceLink>
              </span>
            </div>
            {showModal && <GuestProfileValidation closeModal={handleCloseModal} />}
          </section>
          <div style={{ paddingTop: height && isNotGuestPDP ? height + 20 : 0 }} />
        </section>
      </section>
    </>
  );
};
export default GuestHeader;
