import React, { useState, Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { GrClose } from 'react-icons/gr';
import * as Yup from 'yup';
import Loader from 'components/commercetools-ui/loader/Loader';
import Price from 'components/commercetools-ui/price';
import { GA_CHECK_GIFT_BAL, GTM_WALLET } from 'helpers/constants/aafes';
import useGATrackUserAction from 'helpers/hooks/gaEvents/useGATrackUserAction';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';

const CheckGiftCardModal = ({ configuredData, closeBalanceModal }) => {
  const { checkGiftCardBalance } = useCart();
  const { trackFormComplete } = useGATrackUserAction();
  const [responseError, setResponseError] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [result, setResult] = useState(null);
  const [cardNoError, setCardNoError] = useState('');
  const [gcCharLimit, setGcCharLimit] = useState({});
  const [giftcardNumber, setGiftcardNumber] = useState('');
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const checkGiftCardFormSchema = Yup.object().shape({
    cardNumber: Yup.string()
      .required(formatCommonMessage({ id: 'enterGcNumber', defaultMessage: 'Please enter a Gift Card number.' }))
      .matches(
        /^[0-9]+$/,
        formatCommonMessage({ id: 'validGcNumber', defaultMessage: 'Please enter valid Gift Card number.' }),
      ),

    cardPin: Yup.string()
      .required(formatCommonMessage({ id: 'enterPin', defaultMessage: 'Please enter your PIN.' }))
      .min(4, formatCommonMessage({ id: 'pinShouldBe4', defaultMessage: 'Pin should be 4 digits long.' }))
      .matches(/^[0-9]+$/, formatCommonMessage({ id: 'pinShouldBeNumeric', defaultMessage: 'PIN should be numeric.' })),
  });

  const checkGiftCardForm: any = {
    resolver: yupResolver(checkGiftCardFormSchema),
    mode: 'all',
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm(checkGiftCardForm);

  useEffect(() => {
    const charLimit = configuredData?.gcCharLimit;
    const giftcardCharlimit = Function('giftcardlimit', 'return (' + charLimit + ')')();
    setGcCharLimit(giftcardCharlimit);
  }, []);
  const checkGiftCardFormHandler = async (values: any) => {
    if (giftcardNumber.length < gcCharLimit['min'] || giftcardNumber.length > gcCharLimit['max']) {
      setCardNoError(
        `The required length for GiftCard should be between ${gcCharLimit['min']} and ${gcCharLimit['max']} characters.`,
      );
    } else {
      setCardNoError('');
      setShowLoader(true);
      checkGiftCardBalance(values.cardNumber, values.cardPin)
        .then((res) => {
          if (res.errorCode) {
            setResponseError(res.body);
          } else {
            setResult(res);
            trackFormComplete({
              contentId: GTM_WALLET,
              formName: GA_CHECK_GIFT_BAL,
            });
            setShowForm(false);
            reset();
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setShowLoader(false));
    }
  };
  return (
    <>
      <Transition appear show={true} as={Fragment}>
        <Dialog as="div" className="relative z-[99]" onClose={closeBalanceModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <section className="absolute inset-0">
                  <section
                    className="absolute top-1/2 left-1/2 max-h-[90vh] w-[90%] -translate-x-1/2 -translate-y-1/2 overflow-y-auto rounded bg-white dark:bg-primary-200 md:mt-9 md:w-[600px] lg:mt-3"
                    onClick={(e) => e.stopPropagation()}
                    role="dialog"
                    aria-labelledby="checkGiftBalanceDialogTitle"
                  >
                    <section className="mb-[21px]">
                      <section className="flex justify-between px-[30px] pt-8">
                        <h3
                          id="checkGiftBalanceDialogTitle"
                          className="text-[18px] font-bold leading-[21.6px] text-gray-900"
                        >
                          {' '}
                          {formatCheckoutMessage({
                            id: 'checkGiftBalance',
                            defaultMessage: 'Check Gift Card Balance',
                          })}
                        </h3>
                        <button
                          aria-label="Close Modal"
                          type="button"
                          onClick={closeBalanceModal}
                          className="h-[14px] w-[14px]"
                        >
                          <GrClose />
                        </button>
                      </section>
                      <hr className="border-[1px solid #EDEDED] mt-[18px] mb-[15px] border border-t" />
                      {showForm && (
                        <section className="px-[30px] py-4">
                          <form
                            onSubmit={handleSubmit(checkGiftCardFormHandler)}
                            role="form"
                            aria-labelledby="checkGiftCardFormTitle"
                          >
                            {responseError && (
                              <p
                                id="checkGiftCardErrorMessage"
                                role="alert"
                                className="mb-4 text-left text-affes-base leading-[16.8px] text-red-500"
                              >
                                {responseError}
                              </p>
                            )}
                            <p className="mr-[10px] mb-[15px]">
                              <label
                                htmlFor="checkGiftCardNumber"
                                className="mb-[5px] block w-full text-left text-[12px] font-bold text-gray-800"
                              >
                                {formatCheckoutMessage({ id: 'card.number', defaultMessage: 'Card Number' })}
                                <span className="ml-2 text-affes-sm font-bold leading-[14.4px] text-red-500">*</span>
                              </label>
                              <input
                                id="checkGiftCardNumber"
                                type="text"
                                name="cardNumber"
                                className={`block h-[36px] w-3/4 rounded py-[6px] px-[10px] ${
                                  responseError || errors?.cardNumber?.message ? 'border-red-500 text-red-500' : ''
                                }`}
                                {...register('cardNumber', {
                                  onChange: (e) => {
                                    setGiftcardNumber(e.target.value);
                                    setCardNoError('');
                                    setResponseError('');
                                  },
                                })}
                                maxLength={23}
                                aria-invalid={!!errors?.cardNumber?.message}
                                aria-describedby="checkGiftCardNumberError"
                              />
                              {errors?.cardNumber?.message && (
                                <span
                                  id="checkGiftCardNumberError"
                                  role="alert"
                                  className="block text-left text-sm text-red-500"
                                >
                                  {errors?.cardNumber?.message}
                                </span>
                              )}
                            </p>
                            {cardNoError && (
                              <p
                                id="checkGiftCardNumberError"
                                role="alert"
                                className="mb-3 text-left text-sm text-red-500"
                              >
                                {cardNoError}
                              </p>
                            )}
                            <p className="mr-[10px] mb-[15px]">
                              <label
                                htmlFor="checkGiftCardPin"
                                className="mb-[5px] block w-full text-left text-[12px] font-bold text-gray-800"
                              >
                                {formatCheckoutMessage({ id: 'card.pin', defaultMessage: 'PIN' })}
                                <span className="ml-2 text-affes-sm font-bold leading-[14.4px] text-red-500">*</span>
                              </label>
                              <input
                                id="checkGiftCardPin"
                                type="text"
                                name="cardPin"
                                maxLength={4}
                                className={`block h-[36px] w-3/4 rounded py-[6px] px-[10px] ${
                                  responseError || errors?.cardPin?.message ? 'border-red-500 text-red-500' : ''
                                }`}
                                {...register('cardPin', {
                                  onChange: () => setResponseError(''),
                                })}
                                aria-invalid={!!errors?.cardPin?.message}
                                aria-describedby="checkGiftCardPinError"
                              />
                              {errors?.cardPin?.message && (
                                <span
                                  id="checkGiftCardPinError"
                                  role="alert"
                                  className="block text-left text-sm text-red-500"
                                >
                                  {errors?.cardPin?.message}
                                </span>
                              )}
                            </p>
                            <section>
                              <p className="mb-4 text-left text-affes-base leading-[16.8px] text-gray-900">
                                {formatCheckoutMessage({
                                  id: 'scratch.off.pin',
                                  defaultMessage:
                                    'Scratch-off PIN is in the lower right corner on the back of the card.',
                                })}
                              </p>
                              <p className="text-left text-affes-base leading-[16.8px] text-gray-900">
                                {formatCheckoutMessage({
                                  id: 'egiftcard.four.digits',
                                  defaultMessage: 'For e-gift cards please use the last four digits of the PIN.',
                                })}
                              </p>
                            </section>
                            <p className="mr-[10px] mt-[26px]">
                              <button
                                type="submit"
                                className="mb-4 block h-11 w-full rounded bg-blue-900 text-affes-xl font-bold leading-[19.2px] text-white disabled:opacity-50 md:mb-0 md:w-[136px]"
                                disabled={!isDirty || !isValid}
                              >
                                {formatCheckoutMessage({ id: 'check.balance', defaultMessage: 'Check Balance' })}
                              </button>
                            </p>
                          </form>
                        </section>
                      )}
                      {!showForm && (
                        <>
                          <section className="mx-auto flex w-2/3 px-[30px] pt-4">
                            <p className="mb-4 w-1/2 text-left text-affes-base font-bold leading-[16.8px] text-gray-900">
                              Gift Card Number :
                            </p>
                            <p className="mb-4 w-1/2 text-left text-affes-base font-bold leading-[16.8px] text-gray-900">
                              {result.giftCardNumber}
                            </p>
                          </section>
                          <section className="mx-auto flex w-2/3 px-[30px] pt-4">
                            <p className="mb-4 w-1/2 text-left text-affes-base font-bold leading-[16.8px] text-gray-900">
                              Gift Card Balance :
                            </p>
                            <p className="w-1/2] mb-4 text-left text-affes-base font-bold leading-[16.8px] text-gray-900">
                              <Price className="inline" price={result.giftCardBalance || 0} />
                            </p>
                          </section>

                          <section className="flex justify-center px-[30px] py-4">
                            <button
                              type="button"
                              className="mb-4 block h-11 w-full rounded bg-blue-900 text-affes-xl font-bold leading-[19.2px] text-white md:mb-0 md:w-[136px]"
                              onClick={closeBalanceModal}
                            >
                              {formatCheckoutMessage({ id: 'close', defaultMessage: 'Close' })}
                            </button>
                          </section>
                        </>
                      )}
                    </section>
                  </section>
                </section>
                <section>{showLoader && <Loader aria-live="polite"></Loader>}</section>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default CheckGiftCardModal;
