import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import useSWR from 'swr';
import Loader from 'components/commercetools-ui/loader/Loader';
import { GA_ADDRESS_BOOK } from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';
import { checkSessionExpiration } from 'helpers/utils/checkSessionExpiration';
import { sendToMonetate } from 'helpers/utils/monetateHelper';
import { fetchApiHub, revalidateOptions, useAccount, useCart, useCustomMessages } from 'frontastic';
import AddAddressModal from '../../../aafes/Modals/addAddressModal';
const LoginModal = dynamic(() => import('../../../aafes/Modals/Login/login'));
import Address from '../address';

const Addresses = () => {
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [loginModal, setLoginModal] = useState(false);
  const [isRememberMeSessionExpired, setRememberMeSessionExpired] = useState(false);
  const { formatMessage } = useFormat({ name: 'account' });
  const { addresses = [], isAddressesLoading: isLoading } = useAccount();
  const [showAddAddressModal, setShowAddAddressModal] = useState(false);
  const { studioConfig, setCartProductDetailEventSent, data } = useCart();
  const addressesMaxQty = studioConfig?.addressesMaxQty;
  const [maxAddresses, setMaxAddresses] = useState(20);
  const { account } = useAccount();

  const { getCustomMessages } = useCustomMessages();
  const [getAllCustomMessages, setGetCustomMessages] = useState(null);

  const maxAddressError = getAllCustomMessages?.addressesLimit;

  const queryParams = new URLSearchParams({
    messageKey: 'addressesLimit',
  });
  const { data: maxAddressesMessage } = useSWR(
    `/action/customObjects/getCustomMessage?${queryParams}`,
    fetchApiHub,
    revalidateOptions,
  );

  useEffect(() => {
    if (addressesMaxQty) {
      setMaxAddresses(addressesMaxQty);
    }
    if (addresses?.length >= addressesMaxQty ?? maxAddresses) {
      setGetCustomMessages(maxAddressesMessage);
    }
  }, [addressesMaxQty]);

  const openAddAddressModal = () => {
    const expirationDetails = checkSessionExpiration(account?.lastActivity, studioConfig?.sessionAuthExpirationMins);

    if (expirationDetails?.checkedKeepMeSignedIn) {
      setRememberMeSessionExpired(expirationDetails?.isUserSessionExpired);
      if (expirationDetails?.isUserSessionExpired) {
        setLoginModal(true);
      } else {
        setShowAddAddressModal(true);
      }
    } else {
      setShowAddAddressModal(true);
    }
  };

  const closeAddAddressModal = () => {
    setShowAddAddressModal(false);
    setIsLoad(true);
  };
  function closeLoginModal() {
    setLoginModal(false);
  }

  const [monetateConfig, setMonetateConfig] = useState(null);
  const [monetateSent, setMonetateSent] = useState(false);
  useEffect(() => {
    if (studioConfig?.monetateConstants) {
      try {
        const monetateConfig = JSON?.parse(studioConfig?.monetateConstants?.replace(/'/g, '"'));
        setMonetateConfig(monetateConfig);
      } catch (error) {
        console.error('Errorparsing projectConfig:', error);
      }
    }
  }, [studioConfig]);

  useEffect(() => {
    const processMonetate = async () => {
      const callMonetate = async () => {
        setMonetateSent(true);
        await sendToMonetate(
          {
            page: 'address_book',
            cart: {
              ...data,
            },
          },
          monetateConfig,
        );
      };
      if (account && monetateConfig && !monetateSent) {
        await callMonetate();
        setCartProductDetailEventSent(true);
      }
    };

    processMonetate();
  }, [account, monetateConfig]);

  return (
    <>
      <div className="">
        {isLoad && <Loader />}
        <div>
          <div aria-labelledby="address-book-section" className="flex justify-between">
            <h3 id="address-book-section" className="text-[21px] font-bold leading-[25.2px] text-gray-900">
              {formatMessage({ id: 'address.myAddresses', defaultMessage: 'Address Book' })}
            </h3>
            <div className={`${addresses?.length >= maxAddresses ? 'tooltip' : ''}`}>
              <button
                type="button"
                className="ease-out; outline-width: 0px; text-center text-base font-medium tracking-wide text-[#043C63] transition-colors duration-150 hover:underline focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 disabled:cursor-not-allowed lg:block"
                onClick={() => {
                  openAddAddressModal();
                }}
                disabled={addresses?.length >= maxAddresses}
              >
                {formatMessage({ id: 'address.add', defaultMessage: 'Add New Address' })}
              </button>
              {addresses?.length >= maxAddresses && (
                <span className="tooltiptext tooltip-wide-left !text-red-900">{maxAddressesMessage}</span>
              )}
            </div>
          </div>
        </div>
        <div className="mt-2 grid grid-cols-1 gap-4 md:grid-cols-2">
          {isLoading ? (
            <Loader />
          ) : addresses?.length === 0 ? (
            <>{formatMessage({ id: 'address.noAddress', defaultMessage: 'There are no saved addresses.' })}</>
          ) : (
            addresses?.map((address) => (
              <Address key={address?.addressId} address={address} existingAddresses={addresses} />
            ))
          )}
        </div>
      </div>
      {showAddAddressModal && (
        <AddAddressModal
          withoutSavingAddress={() => {}}
          afterSavingAddress={() => {}}
          closeAddAddressModal={() => setShowAddAddressModal(false)}
          parentLoaderState={isLoading}
          alwaysSave={true}
          defaultValues={null}
          editAddress={false}
          isCart={false}
          existingAddresses={addresses}
          getAllCustomMessages={getAllCustomMessages}
        />
      )}
      {loginModal && (
        <LoginModal
          loginModal={loginModal}
          closeModal={closeLoginModal}
          closeAccountFlyout={closeLoginModal}
          loginCallBack={() => {}}
          isRememberMeSessionExpired={isRememberMeSessionExpired}
        />
      )}
    </>
  );
};

export default Addresses;
