export function formatDateCDTToLocal(cdtTimeString) {
  try {
    const date = new Date(cdtTimeString.replace(' ', 'T'));
    // Determine if the date is within the CDT range or CST range
    const year = date.getUTCFullYear();
    const cdtStart = new Date(Date.UTC(year, 2, 8));
    const cdtEnd = new Date(Date.UTC(year, 10, 1));
    cdtStart.setUTCDate(8 + ((7 - cdtStart.getUTCDay()) % 7));
    cdtEnd.setUTCDate(1 + ((7 - cdtEnd.getUTCDay()) % 7));
    const isCDT = date >= cdtStart && date < cdtEnd;
    // Set the correct offset based on whether it’s CDT or CST
    const offset = isCDT ? '-05:00' : '-06:00';
    const cdtTime = new Date(cdtTimeString.replace(' ', 'T') + offset);
    const localTime = cdtTime.toISOString();
    const localOrderTime = new Date(localTime);
    return localOrderTime;
  } catch (error) {
    console.error('Error converting time:', error);
    return null;
  }
}
