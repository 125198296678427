// Doc Examples
import SimpleButton from './doc-examples/simple-button';
import HelloWorld from './doc-examples/hello-world';
import StarWarsOpeningCrawl from './doc-examples/star-wars/movies';
import StarWarsCharacterSearch from './doc-examples/star-wars/character-search';
import StarWarsCharacterFilter from './doc-examples/star-wars/character-filter';
//import ContentfulBlog from './doc-examples/contentful/blog';

import AccountDetails from './account/details';
import AccountLogin from './account/login';
import AccountOrdersHistory from './account/orders';
import AccountRegister from './account/register';
import ResetPassword from './account/reset-password';
import AccountTest from './account/test';
import Cart from './cart';
import Checkout from './checkout';
import ThankYou from './checkout/thank-you';
import Blog from './content/blog';
import Markdown from './content/markdown';
import Spacer from './content/spacer';
import Tile from './content/tile';
import Footer from './footer';
import Header from './header';
import Hero from './hero';
import Newsletter from './newsletter';
import NotFound from './not-found';
import ProductDetails from './products/details';
import ProductSyndicatedContentTastic from './products/syndicated-content';
import UBPersonalisationTastic from './aafes/uniform-builder/personalisation';
import ProductList from './products/product-list';
import ProductSlider from './products/slider';
import SimilarProducts from './products/similar-products';
import Showcase from './showcase';
import Wishlist from './wishlist';
import CategoryTeasers from './content/category-teasers';
import NewsCardTastic from './news-card';
// AAFES Tastics
import CartTastic from './aafes/cart';
import OrderConfirmationTastic from './order-confirmation';
import HeaderTastic from './aafes/header';
import MiniHeaderTastic from './aafes/miniHeader';
import MiniFooterTastic from './aafes/miniFooter';
import guestFooterTastic from './aafes/guest/footer';
import YouMayLikeTastic from './aafes/you-may-like-tastic';
import SaveForLaterTastic from './saveforlater';
import CheckoutTastic from './aafes/checkout';
import AdSenseTastic from './aafes/adsense';
import SocialMediaLinks from './aafes/socialMediaLinks';
import UBBundleSelectTastic from './aafes/uniform-builder/bundle-select';
import AssetsCmsTastics from './aafes/assets-cms-tastics';
import AssetsCmsJsonTastics from './aafes/aafesJsonAssetPath';
import TemplateCMS from './aafes/aafesCmsAssetPath';
import OrderConfirmationThanks from './aafes/aafes-orderConfirmation-thankYou';
import OrderSummaryTastics from './aafes/aafes-orderSummary';
import AAFESFooter from './aafes/aafesFooter';
import GuestLandingPageTastic from './aafes/guest/landingPage';
import GuestHeaderTastic from './aafes/guest/header';
import UBLandingTastic from './aafes/uniform-builder/landing';
import HomeMonetateTastic from './home-monetate-handler';
import UBThankYouTastic from './aafes/uniform-builder/thankYou';
import BrandsListing from './aafes/brands-listing';
import PageTitle from './aafes/pageTitle';
import ProductsCompareTastic from './products/compare';
import WinReign from './aafes/sweepstakes';
import ConfirmDeleteTastic from './aafes/confirm-delete';
import HomepageSeo from './aafes/seo/aafes-homepage-seo';
import ListingpagesSeo from './aafes/seo/aafes-listingpages-seo';
import MaoChat from './aafes/aafes-mao-chat';
import CmsHeaderTastic from './aafes/aafesCmsHeader';
export const tastics = {
  // Doc Examples
  'example/simple-button': SimpleButton,
  'example/hello-world': HelloWorld,
  'example/star-wars/movie': StarWarsOpeningCrawl,
  'example/star-wars/character-search': StarWarsCharacterSearch,
  'example/star-wars/character-filter': StarWarsCharacterFilter,
  //'contentful/blog': ContentfulBlog,

  'commercetools/ui/checkout': Checkout,
  'commercetools/ui/thank-you': ThankYou,
  'commercetools/ui/cart': Cart,
  'commercetools/ui/footer': Footer,
  'commercetools/ui/header': Header,
  'commercetools/ui/content/blog': Blog,
  'commercetools/ui/content/tile': Tile,
  'commercetools/ui/content/spacer': Spacer,
  'commercetools/ui/content/showcase': Showcase,
  'commercetools/ui/content/markdown': Markdown,
  'commercetools/ui/content/newsletter': Newsletter,
  'commercetools/ui/products/details': ProductDetails,
  'commercetools/ui/products/syndicated-content': ProductSyndicatedContentTastic,
  'commercetools/ui/products/product-list': ProductList,
  'commercetools/ui/products/slider': ProductSlider,
  'commercetools/ui/products/similar-products': SimilarProducts,
  'commercetools/ui/wishlist': Wishlist,
  'commercetools/ui/account/test': AccountTest,
  'commercetools/ui/account/details': AccountDetails,
  'commercetools/ui/account/login': AccountLogin,
  'commercetools/ui/account/register': AccountRegister,
  'commercetools/ui/account/orders': AccountOrdersHistory,
  'commercetools/ui/account/reset-password': ResetPassword,
  'commercetools/ui/content/category-teasers': CategoryTeasers,
  'commercetools/ui/content/news-card': NewsCardTastic,
  'commercetools/ui/hero': Hero,
  'aafes/ui/header': HeaderTastic,
  'aafes/ui/cart': CartTastic,
  'aafes/ui/you-may-like-tastic': YouMayLikeTastic,
  'aafes/ui/saveforlater': SaveForLaterTastic,
  'aafes/ui/checkout': CheckoutTastic,
  'aafes/ui/adsense': AdSenseTastic,
  'aafes/ui/miniHeader': MiniHeaderTastic,
  'aafes/ui/miniFooter': MiniFooterTastic,
  'aafes/ui/socialmedialinks': SocialMediaLinks,
  'aafes/ui/uniform-builder/select-bundle': UBBundleSelectTastic,
  'aafes/ui/aafesuicms': AssetsCmsTastics,
  'aafes/ui/cmsJsonAssetPath': AssetsCmsJsonTastics,
  'aafes/ui/order-confirmation': OrderConfirmationTastic,
  'aafes/ui/cmsAssetPath': TemplateCMS,
  'aafes/ui/orderConfirmationThankYou': OrderConfirmationThanks,
  'aafes/ui/orderSummary': OrderSummaryTastics,
  'aafes/ui/uniform-builder/details': UBPersonalisationTastic,
  'aafes/ui/footerNew': AAFESFooter,
  'aafes/ui/guestLandingPage': GuestLandingPageTastic,
  'aafes/ui/guestHeader': GuestHeaderTastic,
  'aafes/ui/uniformBuilderLanding': UBLandingTastic,
  'aafes/ui/home-monetate-handler': HomeMonetateTastic,
  'aafes/ui/uniform-builder/thank-You': UBThankYouTastic,
  'aafes/ui/guestFooter': guestFooterTastic,
  'aafes/ui/brands/listing': BrandsListing,
  'aafes/ui/pageTitle': PageTitle,
  'aafes/ui/products/compare': ProductsCompareTastic,
  'aafes/ui/sweepstakes/win-reign': WinReign,
  'aafes/ui/confirmDeletePage': ConfirmDeleteTastic,
  'aafes/ui/homepageseo': HomepageSeo,
  'aafes/ui/listingPagesSeo': ListingpagesSeo,
  'aafes/ui/mao-chat': MaoChat,
  'aafes/ui/cmsHeader': CmsHeaderTastic,
  default: NotFound,
};
