import { useState } from 'react';
import { GA_TYPE_YOU_MAY_LIKE } from 'helpers/constants/aafes';
import useGATrackViewActions from 'helpers/hooks/gaEvents/useGATrackViewActions';
import { useElementInViewport } from 'helpers/utils/useElementInViewport';
import { useCart } from 'frontastic';
import AafesSlider, { SliderProps } from '../aafes-slider';
import YouMayLikeTile from './you-may-like-tile';
const YouMayLikeComponent = ({
  slidesPerView,
  showArrows,
  showDots,
  showDetails,
  className = '',
  products,
  autoplayInterval = undefined,
  data,
  variant,
}: {
  slidesPerView: any;
  showArrows: any;
  showDots: any;
  showDetails: any;
  className?: string;
  products: any;
  autoplayInterval?: number;
  data?: any;
  variant?: any;
}) => {
  let youMayLikeSliderConfig: SliderProps = {
    slidesPerView: slidesPerView,
    arrows: showArrows,
    dots: showDots,
    className: className + ' custom-slider',
  };

  if (autoplayInterval) {
    youMayLikeSliderConfig = {
      ...youMayLikeSliderConfig,
      autoplay: {
        delay: autoplayInterval,
        disableOnInteraction: false,
      },
    };
  }
  const { studioConfig } = useCart();
  const productMaxLimit = studioConfig?.productRecommendationLimit;
  const [youMayLikeComponent, setYouMayLikeComponent] = useState<boolean>(true);
  const { trackViewItemList } = useGATrackViewActions();
  const handleView = () => {
    const viewType = () => {
      const listType = data?.carouselTitle ? `${data?.carouselTitle}  List` : GA_TYPE_YOU_MAY_LIKE;
      return listType.charAt(0).toUpperCase() + listType.slice(1).toLowerCase();
    };
    if (products?.length > 0) {
      trackViewItemList({ res: products, type: viewType() });
    }
  };
  useElementInViewport('#sec-carousel', handleView, { threshold: 0.25 });

  return (
    <div id="sec-carousel">
      <AafesSlider youMayLikeComponent={youMayLikeComponent} {...youMayLikeSliderConfig}>
        {products.slice(0, productMaxLimit).map((product, index: number) => (
          <YouMayLikeTile product={product} showDetails={showDetails} key={index} variant={variant} />
        ))}
      </AafesSlider>
    </div>
  );
};

export default YouMayLikeComponent;
