import { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { useRouter } from 'next/router';
import { Product } from '@Types/product/Product';
import { Variant } from '@Types/product/Variant';
import SyndicatedContentHOC from 'components/commercetools-ui/aafes/pdp/syndicated-content';

function ProductSyndicatedContentTastic({ data }) {
  const router = useRouter();
  const {
    product,
    isGuestPdp,
    errorType,
  }: { product: Product; isGuestPdp?: boolean; error?: string; errorType?: string } = data?.data?.dataSource || {};

  const pageMounted = useRef(false);

  const parseAndReturnSpecs = useCallback(() => {
    let specs = [];
    try {
      specs = JSON.parse(variant?.attributes?.specifications);
      return specs;
    } catch (err) {
      console.warn(err);
    }
  }, []);

  const currentVariantIdx = useMemo<number>(() => {
    const currentVariantSKU = router?.asPath?.split('/')?.[isGuestPdp ? 3 : 2];
    const tempCurrentVariantIndex = product?.variants?.findIndex(({ sku }) => sku === currentVariantSKU);
    return tempCurrentVariantIndex >= 0 ? tempCurrentVariantIndex : 0;
  }, [product, router?.asPath]);

  const variants = product?.variants || [];

  function getDefaultVariant(variants: any[], urlSku: string) {
    if (!variants || variants?.length === 0) {
      return null;
    }
    const findVariant = (condition: (variant: any) => boolean) => variants.find(condition);
    // 1. Check if the SKU in the URL is STA and has STA inventory
    let defaultVariant = findVariant((variant) => variant?.sku === urlSku && variant?.isOnStock);
    if (!defaultVariant) {
      // 2. Find the first STA variant with inventory and select it
      defaultVariant = findVariant((variant) => variant?.isOnStock);
    }
    if (!defaultVariant) {
      // 3. Check if the SKU in the URL is PUIS and has PUIS inventory
      defaultVariant = findVariant((variant) => variant?.sku === urlSku && variant?.isOnStockPickupInStore);
    }
    if (!defaultVariant) {
      // 4. Find the first PUIS variant with inventory and select it
      defaultVariant = findVariant((variant) => variant?.isOnStockPickupInStore);
    }
    if (!defaultVariant) {
      // 5. Fall back to the SKU in the URL
      defaultVariant = findVariant((variant) => variant?.sku === urlSku);
    }
    return defaultVariant || product?.variants[0];
  }

  const defaultVariant = useMemo(() => {
    return getDefaultVariant(variants, router?.asPath?.split('/')?.[isGuestPdp ? 3 : 2]);
  }, [product]);

  const variant = useMemo<Variant>(() => {
    if (pageMounted?.current) {
      return product?.variants?.[currentVariantIdx];
    }
    return defaultVariant;
  }, [product, currentVariantIdx, defaultVariant]);

  //For SSR render when going back
  useEffect(() => {
    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        router.replace(as, undefined);
        return false;
      }

      return true;
    });

    return () => router.beforePopState(() => true);
  }, []);

  return (
    <>
      <SyndicatedContentHOC
        productId={product.productId}
        model={parseAndReturnSpecs()?.filter((item) => item?.label?.startsWith('Model'))?.[0]}
        variant={variant}
      />
    </>
  );
}

export default ProductSyndicatedContentTastic;
