import React from 'react';
import Link from 'next/link';
import { FiCheck } from 'react-icons/fi';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { isCategory } from 'helpers/utils/findCategory';
import { tablet } from 'helpers/utils/screensizes';
import { useCart } from 'frontastic';
import RatingStars from './ratingStars';

export default function FilterSpecificUI({
  res,
  handleRefinementClick,
  isShowRefinements,
  handleTempRefinement,
  tempRefinements,
  index,
  selectedRefinements,
  handleDisplayFilter,
}) {
  const [isDesktop] = useMediaQuery(tablet);
  const { studioConfig } = useCart();
  const FILTER_ATTRIBUTE_COLOR = studioConfig?.filterAttributeColor;
  const FILTER_ATTRIBUTE_SIZE = studioConfig?.filterAttributeSize;
  const FILTER_ATTRIBUTE_RATING = studioConfig?.filterAttributeRating;

  return (
    <>
      {isCategory(res?.name) && (
        <div
          className={`${
            !isDesktop ? 'px-4' : 'left-nav-scrollbar'
          } flex flex-col gap-y-3 overflow-y-auto transition-all duration-300 ${
            isShowRefinements?.some((entry) => entry?.name === res?.name && entry?.isVisible)
              ? 'max-h-[224px] opacity-100'
              : 'mt-0 max-h-0 opacity-0'
          }`}
        >
          {res?.refinements?.map((refinement, index) => {
            return (
              <div key={index}>
                <a
                  aria-label="Filter on new tab"
                  href="#"
                  onClick={() => {
                    handleDisplayFilter();
                    handleRefinementClick(refinement, res?.name, res?.displayName);
                  }}
                  className={`w-fit text-left text-sm leading-4 text-gray-900 ${
                    !isDesktop && tempRefinements?.[res?.name]?.includes(refinement?.name) ? 'font-bold' : ''
                  }`}
                >
                  {refinement?.name} <span>({refinement?.count})</span>
                </a>
              </div>
            );
          })}
        </div>
      )}

      {res?.name === FILTER_ATTRIBUTE_SIZE && (
        <div
          className={`${
            !isDesktop ? 'px-4' : 'left-nav-scrollbar'
          } flex flex-wrap gap-3 overflow-y-auto transition-all duration-300 ${
            isShowRefinements?.some((entry) => entry?.name === res?.name && entry?.isVisible)
              ? 'max-h-[224px] opacity-100'
              : 'mt-0 max-h-0 opacity-0'
          }`}
        >
          {res?.refinements?.map((refinement, index) => {
            return (
              <a
                aria-label="Filter on new tab"
                href="#"
                title={refinement?.name}
                className="hover:no-underline"
                key={index}
              >
                <div
                  onClick={() => {
                    {
                      isDesktop
                        ? handleRefinementClick(refinement, res?.name)
                        : handleTempRefinement(refinement, res?.name);
                    }
                  }}
                  className={`h-10 w-10 cursor-pointer overflow-hidden rounded border pt-0.5 text-center text-xs leading-4 text-gray-900
                ${
                  isDesktop
                    ? selectedRefinements?.[res?.name]?.includes(refinement?.name) && 'border-2 border-gray-900'
                    : tempRefinements?.[res?.name]?.includes(refinement?.name) && 'border-2 border-gray-900'
                }`}
                >
                  <span className="break-words">{refinement?.name}</span>
                </div>
              </a>
            );
          })}
        </div>
      )}

      {res?.name === FILTER_ATTRIBUTE_COLOR && (
        <div
          className={`${
            !isDesktop ? 'px-4' : 'left-nav-scrollbar'
          } flex flex-wrap gap-3 overflow-y-auto transition-all duration-300 ${
            isShowRefinements?.some((entry) => entry?.name === res?.name && entry?.isVisible)
              ? 'max-h-[224px] opacity-100'
              : 'mt-0 max-h-0 opacity-0'
          }`}
        >
          {res?.refinements?.map((refinement, index) => {
            const swatchColorClass = refinement?.name?.toLowerCase();
            const refinementName =
              refinement?.name?.length > 8 ? `${refinement?.name?.substring(0, 8)}...` : refinement?.name;
            return (
              <div key={index} className="w-12">
                <div className="flex flex-col">
                  <div className="px-1">
                    <a aria-label="Filter on new tab" href="#">
                      <button
                        title={refinement?.name}
                        onClick={() => {
                          {
                            isDesktop
                              ? handleRefinementClick(refinement, res?.name)
                              : handleTempRefinement(refinement, res?.name);
                          }
                        }}
                        className={`aafes-swatch h-10 w-10 rounded-full border ${swatchColorClass} ${
                          isDesktop
                            ? selectedRefinements?.[res?.name]?.includes(refinement?.name) && 'border-2 border-gray-900'
                            : tempRefinements?.[res?.name]?.includes(refinement?.name) && 'border-2 border-gray-900'
                        }`}
                      ></button>
                    </a>
                  </div>
                  <span className="text-center text-xs">{refinementName}</span>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {res?.name === FILTER_ATTRIBUTE_RATING && (
        <div
          className={`${
            !isDesktop ? 'px-4' : 'left-nav-scrollbar'
          } flex flex-col gap-y-3 overflow-y-auto transition-all duration-300 ${
            isShowRefinements?.some((entry) => entry?.name === res?.name && entry?.isVisible)
              ? 'max-h-[224px] opacity-100'
              : 'mt-0 max-h-0 opacity-0'
          }`}
        >
          {res?.refinements?.map((refinement, index) => {
            return (
              <a
                aria-label="Filter on new tab"
                className="w-fit"
                href="#"
                key={index}
                onClick={() => {
                  {
                    isDesktop
                      ? handleRefinementClick(refinement, res?.name)
                      : handleTempRefinement(refinement, res?.name);
                  }
                }}
              >
                <div key={index} className="grid cursor-pointer grid-cols-[minmax(18px,18px)_1fr] gap-x-2">
                  <div className="h-[18px] w-full rounded border border-[#0000004D]">
                    {isDesktop ? (
                      selectedRefinements?.[res?.name]?.includes(refinement?.low?.toFixed(1)) ? (
                        <FiCheck className="h-full w-full text-blue-900" />
                      ) : null
                    ) : tempRefinements?.[res?.name]?.includes(refinement?.low?.toFixed(1)) ? (
                      <FiCheck className="h-full w-full text-blue-900" />
                    ) : null}
                  </div>
                  <div className="flex gap-x-1">
                    <RatingStars ratings={refinement?.low} />
                    {`(${refinement?.count})`}
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      )}
    </>
  );
}
