import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import NextLink from 'next/link';
import router from 'next/router';
import Price from 'components/commercetools-ui/price';
import { GA_TYPE_YOU_MAY_LIKE } from 'helpers/constants/aafes';
import useGATrackViewActions from 'helpers/hooks/gaEvents/useGATrackViewActions';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount, useProduct } from 'frontastic';
import Image from 'frontastic/lib/image';
const LoginModal = dynamic(() => import('../Modals/Login/login'));

const YouMayLikeTile = ({ product, showDetails, variant }) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const [loginModal, setLoginModal] = useState(false);
  const { loggedIn } = useAccount();
  const { trackSelectItem } = useGATrackViewActions();
  const { getMarketingBugProducts } = useProduct();
  const productPrice = {
    centAmount: product.price * 100,
    currencyCode: 'USD',
    fractionDigits: 2,
  };
  const productSalePrice = {
    centAmount: product?.salePrice * 100,
    currencyCode: 'USD',
    fractionDigits: 2,
  };
  const formattedLogInText = formatProductMessage({
    id: 'exchangePricing',
    defaultMessage: 'Log in for Exchange pricing',
  });
  function showLogin() {
    setLoginModal(true);
  }
  function closeLoginModal() {
    setLoginModal(false);
  }
  if (!product?.msrprestricted) {
    console.warn('Unable to get msrprestricted attribute');
  }
  const handleLinkClicked = (event) => {
    const fetchData = async () => {
      try {
        const res = await getMarketingBugProducts(product?.id);
        const trackObj = {
          product: product,
          type: GA_TYPE_YOU_MAY_LIKE,
          variant: res?.records?.[0],
        };
        trackSelectItem(trackObj);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  };
  return (
    <>
      <NextLink href={product?.link}>
        <a className="relative w-full" onClick={(event) => handleLinkClicked(event)}>
          <section className="mb-[20px] flex min-h-[125px] justify-center">
            <Image
              src={product.imageLink}
              alt={product.title}
              className="h-auto w-full object-cover group-hover:opacity-75"
            />
          </section>
          <div className="w-full">
            <h3 className="mt-4 text-sm font-bold text-blue-900">{product.title}</h3>
            {showDetails && ((product?.msrprestricted === '1' && loggedIn) || product?.msrprestricted === '0') && (
              <>
                <div>
                  {product.discountedPrice && (
                    <div className="inline text-sm text-gray-800 line-through">
                      {formatCartMessage({
                        id: 'compareAt',
                        defaultMessage: 'Compare At:',
                      })}{' '}
                    </div>
                  )}
                  <Price
                    price={productPrice}
                    className={`${
                      product.discountedPrice ? 'text-gray-800 line-through' : 'font-bold text-gray-900'
                    } inline text-sm`}
                  />
                  {product.discountedPrice && (
                    <Price price={product.discountedPrice} className="text-sm font-bold text-gray-900" />
                  )}
                </div>
                <div>
                  {product?.salePrice && (
                    <div className="inline text-sm font-bold text-red-900">
                      {formatCartMessage({
                        id: 'sale',
                        defaultMessage: 'Sale',
                      })}{' '}
                      <Price price={productSalePrice} className={`inline text-sm font-bold text-red-900`} />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </a>
      </NextLink>
      {!loggedIn && product?.msrprestricted === '1' && (
        <button className="cursor-pointer" onClick={() => showLogin()}>
          <p className="py-4 text-base font-semibold leading-[24px] text-blue-900 hover:underline">
            {formattedLogInText}
          </p>
        </button>
      )}
      <LoginModal
        loginModal={loginModal}
        closeModal={closeLoginModal}
        loginCallBack={() => {
          router.push(router.asPath);
        }}
      />
    </>
  );
};

export default YouMayLikeTile;
