import { Variant } from '@Types/product/Variant';

/**
 * The function `getDefaultShippingMethod` determines the default shipping method based on the
 * variant's attributes.
 * @param {Variant} variant - The `getDefaultShippingMethod` function takes a `variant` object as a
 * parameter.
 */
export const getDefaultShippingMethod = (variant: Variant) => {
  const isStaShipping = variant?.isShipToAddress && variant?.isOnStock;
  const variantIsShipToStore = variant?.attributes?.isShipToStore;
  const variantIsPickUpInStore = variant?.attributes?.isPickupInStore;
  const defaultShippingMethod = {
    isPickupInStore: isStaShipping
      ? false
      : (variantIsPickUpInStore && variant?.isOnStockPickupInStore) || (variantIsShipToStore && variant?.isOnStock),
    isShipToAddress: isStaShipping,
  };
  return defaultShippingMethod;
};
