import React, { useState } from 'react';
import Link from 'next/link';
import {
  GTM_HEADER,
  GTM_MILITARY_STAR,
  GTM_GENERIC,
  AAFES_OWNED_SITE,
  NON_AAFES_OWNED_SITE,
} from 'helpers/constants/aafes';
import { isAAFESownedSite } from 'helpers/utils/commonGAUtils';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import MilitaryStar from '../../../icons/military-star';
function MilitryStar({ militaryStarData, militaryStarApply, militaryStarParentURL }) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { formatMessage } = useFormat({ name: 'common' });
  const [militaryStar, setMilitaryStar] = useState(false);
  const militaryParentURL = militaryStarParentURL?.links;
  const isAAFESOwned = isAAFESownedSite(militaryParentURL);
  const isgtmAffesOwned = isAAFESOwned ? AAFES_OWNED_SITE : NON_AAFES_OWNED_SITE;
  return (
    <>
      <section
        className="relative order-5 hidden text-black-900 md:mr-2 md:flex "
        onMouseEnter={() => setMilitaryStar(true)}
        onMouseLeave={() => setMilitaryStar(false)}
      >
        <Link href={militaryParentURL}>
          <a
            className="flex cursor-pointer items-center"
            data-gtm-menu
            data-gtm-id={`${GTM_HEADER},${isgtmAffesOwned}`}
            data-gtm-name={GTM_MILITARY_STAR}
            data-gtm-exit
          >
            <MilitaryStar className="mr-2" />{' '}
            <section className="whitespace-nowrap text-sm text-[#1E1E1E] md:hidden xl:flex">
              {formatMessage({ id: 'militaryStar', defaultMessage: 'Military Star' })}
            </section>
          </a>
        </Link>

        {militaryStar && (
          <section className="absolute -right-10 top-full z-20 w-64 border border-gray-600 bg-white px-5">
            <section className="boxArrow absolute left-32 -top-4 h-0 w-0 border-x-[16px] border-b-[16px] border-x-transparent border-b-gray-600 bg-transparent outline-gray-600">
              <section className="triangle absolute left-[-16px] top-[1px] h-0 w-0 border-x-[16px] border-b-[16px] border-x-transparent border-b-white bg-transparent"></section>
            </section>
            <section className="py-3">
              {militaryStarData.map((data, id) => {
                const isAAFESOwned = isAAFESownedSite(data?.links);
                const isgtmAffesOwned = isAAFESOwned ? AAFES_OWNED_SITE : NON_AAFES_OWNED_SITE;
                return (
                  <section key={id} className="mb-1 py-1">
                    <Link href={data.links}>
                      <p
                        className="cursor-pointer text-blue-900 underline underline-offset-1 hover:no-underline hover:underline-offset-0"
                        data-gtm-exit
                        data-gtm-id={isgtmAffesOwned}
                      >
                        {data.header}
                      </p>
                    </Link>
                  </section>
                );
              })}

              <section>
                <Link href={militaryStarApply.links}>
                  <p
                    className="my-3 w-full   rounded-sm border border-blue-900 bg-white py-2 text-center text-blue-900 hover:bg-blue-900 hover:text-white"
                    data-gtm-cta
                    data-gtm-id={GTM_GENERIC}
                  >
                    {militaryStarApply.header}
                  </p>
                </Link>
              </section>
            </section>
          </section>
        )}
      </section>
    </>
  );
}

export default MilitryStar;
