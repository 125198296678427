import { useCallback } from 'react';
import { EXTPRICE_BRONZE, GA_BEGIN_CHECKOUT, GA_PURCHASE, GA_REVIEW_ORDER } from 'helpers/constants/aafes';
import { logEventToDataLayer } from 'helpers/utils/analytics';
import { calculateDiscount, transformItemData } from 'helpers/utils/commonGAUtils';

const useGATrackCheckoutAction = () => {
  const trackBeginCheckout = useCallback((trackBeginCheckoutObj: any) => {
    const { cartList, type } = trackBeginCheckoutObj;
    const { discount } = calculateDiscount(cartList);
    try {
      const items = transformItemData(cartList?.lineItems);
      const beginCheckout = {
        content_id: type,
        ecommerce: {
          currency: cartList?.subTotal?.currencyCode || EXTPRICE_BRONZE?.currencyCode,
          value: cartList?.subTotal?.centAmount / 10 ** cartList?.subTotal?.fractionDigits,
          coupon: cartList?.discountCodes?.[0]?.code || undefined,
          discount: discount,
          items: items,
        },
      };

      logEventToDataLayer(GA_BEGIN_CHECKOUT, beginCheckout);
    } catch (error) {
      console.error('ga4 begin checkout event fail', error);
    }
  }, []);

  const trackPurchase = useCallback((trackPurchaseObj: any) => {
    const { cartList, contentId, orderId } = trackPurchaseObj;
    const { discount } = calculateDiscount(cartList);
    try {
      const items = transformItemData(cartList?.lineItems);
      const purchaseItem = {
        content_id: contentId,
        ecommerce: {
          transaction_id: orderId,
          tax: cartList?.importTaxAndDuty?.centAmount || 0,
          shipping: cartList?.shippingCost?.centAmount / 10 ** cartList?.shippingCost?.fractionDigits,
          currency: cartList?.subTotal?.currencyCode || EXTPRICE_BRONZE?.currencyCode,
          value: cartList?.subTotal?.centAmount / 10 ** cartList?.subTotal?.fractionDigits,
          coupon: cartList?.discountCodes?.[0]?.code || undefined,
          discount: discount,
          items: items,
        },
      };

      logEventToDataLayer(GA_PURCHASE, purchaseItem);
    } catch (error) {
      console.error('ga4 purchase info event fail', error);
    }
  }, []);

  const trackOrderReview = useCallback((trackReviewOrderObj: any) => {
    const { contentId, cartList } = trackReviewOrderObj;
    const items = transformItemData(cartList?.lineItems);
    const { discount } = calculateDiscount(cartList);
    try {
      const reviewOrderObj = {
        content_id: contentId,
        ecommerce: {
          currency: cartList?.subTotal?.currencyCode || EXTPRICE_BRONZE?.currencyCode,
          value: cartList?.subTotal?.centAmount / 10 ** cartList?.subTotal?.fractionDigits,
          coupon: cartList?.discountCodes?.[0]?.code || undefined,
          discount: discount,
          items: items,
        },
      };
      logEventToDataLayer(GA_REVIEW_ORDER, reviewOrderObj);
    } catch (error) {
      console.error('ga4 review order event fail', error);
    }
  }, []);

  return { trackBeginCheckout, trackPurchase, trackOrderReview };
};

export default useGATrackCheckoutAction;
