import { useEffect, useRef } from 'react';
import { fetchApiHub } from 'frontastic';

export const getProductPricesWithFacilityPrice = async (records, storeId) => {
  const skuList = records
    .filter((product) => product?.useFacilityBasedPrice)
    .map((product) => product.repositoryId)
    .join(',');

  if (!skuList) {
    throw new Error('No valid SKUs with facility-based pricing found.');
  }

  try {
    const res = await fetchApiHub(`/action/externalPrice/getStorePrices?sku=${skuList}&storeId=${storeId}`);
    console.log(JSON.stringify(res) + 'fbp response');
    if (!Array.isArray(res)) {
      throw new Error('Expected response to be an array, but got something else');
    }
    const priceDetails = res.reduce((acc, item) => {
      const storePrices = item.storePrice?.[0]?.price ?? null;

      if (storePrices) {
        acc[item.sku] = storePrices;
      }
      return acc;
    }, {});

    return priceDetails;
  } catch (error) {
    console.error('Error in getProductPricesWithFacilityPrice:', error);
    throw error;
  }
};

export const useIntersectionObserver = (
  callback: (visibleValues: string[]) => void,
  targetAttribute: string,
  containerSelector: string,
  options: IntersectionObserverInit = { threshold: 0.1 },
) => {
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    const elements = document.querySelectorAll<HTMLElement>(`${containerSelector} [${targetAttribute}]`);
    if (elements.length === 0) return;
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    observerRef.current = new IntersectionObserver((entries) => {
      const visibleItems = entries
        .filter((entry) => entry.isIntersecting)
        .map((entry) => entry.target.getAttribute(targetAttribute) as string)
        .filter(Boolean);

      if (visibleItems.length > 0) {
        callback(visibleItems);
      }
    }, options);

    elements.forEach((el) => observerRef.current?.observe(el));

    return () => observerRef.current?.disconnect();
  }, [callback, targetAttribute, containerSelector, options]);

  return { observer: observerRef.current };
};
