import Header from 'components/commercetools-ui/aafes/header';
import { useCart } from 'frontastic/provider';

// Define constants for labels
const LABELS = {
  HEADER: 'label',
  POSITION: 'position',
  LOGIN_REQUIRED: 'loginrequired',
  URL: 'url',
  ICON: 'icon',
  RESPONSIVE_ICON: 'responsiveicon',
};

const CmsHeaderTastic = ({ data }) => {
  const { data: cartList } = useCart();
  const headerlinksData = data?.cmsData?.dataSource?.cmsContentsHyperlinkData?.headerlinks?.DropZones || [];

  const createSection = (headerlinksData, index) => {
    const children = headerlinksData[index]?.children;
    const channelDisplayState = headerlinksData[index]?.channelDisplayState;

    // Only process if channelDisplayState includes "webDisplay"
    if (channelDisplayState.split(', ').includes('webDisplay')) {
      return {
        header: children.find((item) => item.label === LABELS.HEADER)?.value,
        position: children.find((item) => item.label === LABELS.POSITION)?.value,
        loginRequired: headerlinksData[index]?.loginrequired,
        links: children.find((item) => item.label === LABELS.URL)?.value,
        icon: children.find((item) => item.label === LABELS.ICON)?.value,
        mIcon: children.find((item) => item.label === LABELS.RESPONSIVE_ICON)?.value,
        pagecomponentsPosistion: headerlinksData[index]?.fullname,
        channelDisplayState,
        componentType: headerlinksData[index]?.componenttype,
      };
    }
  };

  // Function to divide sections into left and right columns
  const divideSections = (headerlinksData) => {
    const sections = {
      leftColumn: [],
      rightColumn: [],
    };

    headerlinksData.forEach((_, index) => {
      const section = createSection(headerlinksData, index);

      if (!section) return;

      switch (section.position?.toLowerCase()) {
        case 'left':
          sections.leftColumn.push(section);
          break;
        case 'right':
          sections.rightColumn.push(section);
          break;
        default:
          break;
      }
    });
    return sections;
  };

  const { leftColumn, rightColumn } = divideSections(headerlinksData);

  const findStore = {
    header: data?.headerCol7,
    links: data?.linksCol7,
    icon: data?.iconCol7,
    mIcon: data?.iconColM7,
  };

  const exchangeLogo = {
    header: data?.headerCol8,
    links: data?.linksCol8,
    icon: data?.iconCol8,
    mIcon: data?.iconColM8,
  };

  const militaryStarParentURL = {
    header: data?.starHead1,
    links: data?.starLink1,
  };

  const militaryStar = [
    { header: data?.starHead1, links: data?.starLink1 },
    { header: data?.starHead2, links: data?.starLink2 },
    { header: data?.starHead3, links: data?.starLink3 },
  ];

  const militaryStarApply = {
    header: data?.starHead4,
    links: data?.starLink4,
  };
  return (
    <Header
      data={data}
      leftColumn={leftColumn}
      rightColumn={rightColumn}
      findStore={findStore}
      exchangeLogo={exchangeLogo}
      militaryStar={militaryStar}
      militaryStarApply={militaryStarApply}
      cartList={cartList}
      militaryStarParentURL={militaryStarParentURL}
    />
  );
};

export default CmsHeaderTastic;
