import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { RiArrowDownSLine, RiArrowRightSLine } from 'react-icons/ri';
import Loader from 'components/commercetools-ui/loader/Loader';
import { GA_EXCHANGE } from 'helpers/constants/aafes';
import useGATrackUserAction from 'helpers/hooks/gaEvents/useGATrackUserAction';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';

export default function SortBy({ setCurrentPage }) {
  const { studioConfig } = useCart();

  const SORT_BY = useMemo(() => {
    return studioConfig?.sortByOptions && JSON.parse(studioConfig?.sortByOptions);
  }, [studioConfig?.sortByOptions]);

  const { formatMessage } = useFormat({ name: 'common' });
  const [selectedSortOption, setSelectedSortOption] = useState(SORT_BY?.[0]);
  const [isShowSortByContent, setIsShowSortByContent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dropdownReference = useRef(null);
  const ButtonReference = useRef(null);
  const router = useRouter();
  const { trackSort } = useGATrackUserAction();

  const handleSetSortBy = (sortOption) => {
    if (sortOption?.value === selectedSortOption?.value) return setIsShowSortByContent(false);
    setSelectedSortOption(sortOption);
    setIsLoading(true);
    trackSort({
      contentId: GA_EXCHANGE,
      sortTerm: sortOption?.displayName,
    });
    const CURRENT_URL = window?.location?.href;
    const url = new URL(CURRENT_URL);
    const sortBy = sortOption?.value;
    if (sortBy !== '') url?.searchParams?.set('sortBy', sortBy);
    else url?.searchParams?.delete('sortBy');
    url?.searchParams?.delete('pageNo');
    setCurrentPage(1);
    router.push(url?.toString()).then(() => setIsLoading(false));
    setIsShowSortByContent(false);
  };

  useEffect(() => {
    const URL_DATA = router?.query?.sortBy;
    const decodedSortBy = Array.isArray(URL_DATA) ? URL_DATA[0] : URL_DATA;
    if (URL_DATA && SORT_BY) {
      try {
        setIsLoading(true);
        const matchingSortOption = SORT_BY?.find((sortOption) => sortOption?.value === decodedSortBy);
        if (matchingSortOption) setSelectedSortOption(matchingSortOption);
        setIsLoading(false);
      } catch (error) {
        console.error('Error decoding and parsing sortBy:', error);
      }
    } else {
      setSelectedSortOption(SORT_BY?.[0]);
    }
  }, [router?.asPath, SORT_BY]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (!dropdownReference?.current?.contains(event?.target) && !ButtonReference?.current?.contains(event?.target)) {
        setIsShowSortByContent(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="relative z-[3]">
        <button
          ref={ButtonReference}
          onClick={() => {
            setIsShowSortByContent((prev) => !prev);
          }}
          className="max-gap-x-6 flex w-full items-center justify-between rounded border border-[#00000033] p-2"
        >
          <p className="text-left text-sm font-bold leading-4 text-gray-900 md:min-w-[165px]">
            <span className="font-normal text-gray-800">
              {formatMessage({ id: 'sortBy', defaultMessage: 'Sort By:' })}{' '}
            </span>
            {selectedSortOption?.displayName}
          </p>
          {isShowSortByContent ? (
            <RiArrowDownSLine className="h-6 w-6 text-[#1E1E1E]" />
          ) : (
            <RiArrowRightSLine className="h-6 w-6 text-[#1E1E1E]" />
          )}
        </button>
        {isShowSortByContent && (
          <div ref={dropdownReference} className="absolute w-full rounded border border-[#00000033] bg-white">
            <div>
              {SORT_BY?.map((sortOption, index) => (
                <div key={index} className="py-1">
                  <button
                    className="w-full px-2.5 text-left text-gray-800 hover:bg-gray-200"
                    onClick={() => handleSetSortBy(sortOption)}
                  >
                    {sortOption?.displayName}
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {isLoading && <Loader />}
    </>
  );
}
